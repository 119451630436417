import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { AggregatedSignal }      from './aggregatedSignal';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class AggregatedSignalService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all aggregatedSignals
   */
  public getAggregatedSignals(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<AggregatedSignal[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<AggregatedSignal[]>(environment.api_url + '/aggregatedSignals', { params: queryParams });
  };
  /**
   * Retrieves aggregatedSignal details by a given id
   *
   * @param idAggregatedSignal
   */
  public getAggregatedSignal(idAggregatedSignal: string): Observable<AggregatedSignal> { 
    return this.http.get<AggregatedSignal>(environment.api_url + "/aggregatedSignal/" + idAggregatedSignal); 
  };

  /**
   * Save aggregatedSignal
   *
   * @param aggregatedSignal
   */
  public saveAggregatedSignal(aggregatedSignal: AggregatedSignal): Observable<any> {
    if (aggregatedSignal.idAggregatedSignal) {
      return this.updateAggregatedSignal(aggregatedSignal);
    } else {
      return this.newAggregatedSignal(aggregatedSignal);
    }
    //return null;
  };

  /**
   * Create new aggregatedSignal
   *
   * @param aggregatedSignal
   */
  public newAggregatedSignal(aggregatedSignal: AggregatedSignal): Observable<any> { 
    return this.http.post(environment.api_url + "/aggregatedSignal", aggregatedSignal);
  };

  /**
   * Update aggregatedSignal
   *
   * @param aggregatedSignal
   */
  public updateAggregatedSignal( aggregatedSignal: AggregatedSignal): Observable<any> { 
    return this.http.put(environment.api_url + "/aggregatedSignal", aggregatedSignal);
  
  };

  /**
   * Delete an aggregatedSignal by a given id
   *
   * @param idAggregatedSignal
   */
  public removeAggregatedSignal (idAggregatedSignal: number): Observable<any> { 
    return this.http.delete(environment.api_url + "/aggregatedSignal/" + idAggregatedSignal);
  }

  /**
   * Get entity aggregatedSignals by entity id
   *
   * @param idEntity
   */
  public getEntityAggregatedSignals(idEntity: string, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<AggregatedSignal[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<AggregatedSignal[]>(environment.api_url + '/aggregatedSignal/entity/' + idEntity, { params: queryParams });
  };
  /**
   * Validate a aggregatedSignal
   *
   * @param aggregatedSignal
   */
  public evaluateAggregatedSignal(aggregatedSignal: AggregatedSignal): Observable<any> { 
    return this.http.post(environment.api_url + "/aggregatedSignal/evaluate", aggregatedSignal);
  };


}
