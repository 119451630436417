import { Injectable }               from '@angular/core';
import { Router } from '@angular/router';

import { environment }  from './../environments/environment';
import { AlertService } from './alert.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map, share, catchError } from 'rxjs/operators';
import { Account } from './main/accounts/account';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { RefreshToken } from './interfaces/refreshToken.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLoggedIn: boolean = null;
  isImpersonating: boolean;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  options = { headers: this.headers };

  private loggedAccount: Account;
  private $loggedAccount: Observable<any> = this.http.get(`${environment.api_url}/auth/account`).pipe(
    tap((account)=>this.setAccount(account)),
    share()
  );
  private _isRefreshing = false;
  private supportedLanguages = ['en', 'es', 'zh', 'de', 'it', 'fr'];
  private apiDown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
    private http: HttpClient,
    public router: Router,
    private alertService: AlertService,
  ) {
    // maybe we should check if the token is valid
    this.isImpersonating = localStorage.getItem('token_impersonator') ? true : false;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  set isRefreshing(value: boolean) { 
    this._isRefreshing = value;
  }

  public setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  public static getToken(): string {
    return localStorage.getItem("token");
  }

  public getTokenAsync(): Observable<string> {
    const token = AuthService.getToken();
    return of(token);
  }
  
  addTokenHeader(req: HttpRequest<any>) {
    const token = AuthService.getToken() || ''
  
    return req.clone({headers: req.headers.set('x-access-token', token)});
  }

  public refreshToken() { 
    return this.http.get<RefreshToken>(`${environment.api_url}/refresh-token`, {withCredentials: true});
  }

  private setAccount(account: any): void {
    if (account && account.idAccount != null) {
      this.loggedAccount = account;
      this.isLoggedIn = true;
    }
  }

  public isLoggednIn(): Observable<boolean> {
    if (this.isLoggedIn !== null) {
      return of(this.isLoggedIn);
    } else {
      return this.$loggedAccount.pipe(
        catchError((error)=>{
          this.setApiDown(true);
          console.error(error);
          throw error;
        }),
        map((account)=> {
          return account && account.idAccount != null;
        }),
        tap((res): void => {
          this.setApiDown(false);
          this.isLoggedIn = res;
        }));
    }
  }

  private setApiDown(apiDown: boolean): void {
    this.apiDown.next(apiDown);
  }

  public isApiDown(): Observable<boolean> {
    return this.apiDown.asObservable();
  }

  public login(username: string, password: string): Observable<boolean> {
    const options = { headers: this.headers, withCredentials: true };
    return this.http.post(`${environment.api_url}/authenticate`, JSON.stringify({ username: username, password: password, from: 'web' }), options).pipe(
      tap({
        next: (r: any): void => {
          if (r.success) {
            this.setAccount(r.account);
            const token = r.token;
            this.setToken(token);
            // this.setTokenHeader(token);
          }
          else {
            this.alertService.emitErrorMessage({
              text: r.message + ' 1 Incorrect username or password',
              type: 'danger',
            });
            this.isLoggedIn = false
          }
        },
        error: (err): void => {
          this.alertService.emitErrorMessage({
            text: err.error.message,
            type: 'danger',
          });
          if(err.error.maintenance) {
            this.setApiDown(true);
          }
          this.isLoggedIn = false;
        },
      }),
    );
  }

  public logout(text: string = 'You have logged out'): void {
    this.isLoggedIn = false;
    localStorage.removeItem("token");
    // this.clearTokenHeader();
    window['_paq'].push(["deleteCookies"]);
    this.router.navigate(["login"]);
    this.alertService.emitErrorMessage({text, type: 'info'});

  }

  impersonate(idAccount: number): Observable<boolean> {
    const token = localStorage.getItem('token');
    localStorage.setItem('token_impersonator', token);
  
    const body = { idAccount: idAccount };
  
    return this.http.post(`${environment.api_url}/impersonate`, body, {
      headers: this.headers
    })
    .pipe(
      map((response: any) => {
        if (response.success) {
          const newToken = response.token;
          this.setToken(newToken);
          this.loadAccount().then(() => {
            location.reload();
          });
  
          return (this.isLoggedIn = true);
        } else {
          this.alertService.emitErrorMessage({
            text: 'Incorrect username or password',
            type: 'danger',
          });
          return (this.isLoggedIn = false);
        }
      }),
      catchError((err: any) => {
        this.alertService.emitErrorMessage({
          text: 'Incorrect username or password',
          type: 'danger',
        });
        this.isLoggedIn = false;
        return of(false);
      })
    );
  }

  endImpersonate(){
    localStorage.setItem('token',localStorage.getItem('token_impersonator'));
    localStorage.removeItem('token_impersonator');
    //this.loadAccount();
    window.location.replace('');
  }

  async getRole(){
    if (!this.loggedAccount){
      await this.loadAccount();
      return this.loggedAccount.idRole;
     } else {
       //console.log(this.loggedAccount.idRole);
       return this.loggedAccount.idRole;
     }
  }

  async isEndUser(){
    if (!this.loggedAccount){
      await this.loadAccount();
      return this.loggedAccount.idRole == 3;
     } else {
       //console.log(this.loggedAccount.idRole);
       return this.loggedAccount.idRole == 3;
     }
  }

  public getAccount(): Observable<any>{
    if (this.loggedAccount){
      return of(this.loggedAccount);
    } else {
      return this.$loggedAccount;
    }
  }

  async loadAccount() {
    try {
      const r: any = await this.http
        .get(`${environment.api_url}/auth/account`, {
          headers: this.headers
        })
        .toPromise();

      if (r.status == 200) {
        this.loggedAccount = r;
        this.isLoggedIn = true;
      }
    } catch (e) {
      debugger;
      console.log(e);
      this.logout();
    }
  }
 
  clearContentHeader(): void {
    this.options.headers.delete('Content-Type');
  }

}
