import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { WorkShift, EntityWorkShift } from './workShift.interface';
import { OrganizationWorkedShift } from "./organizationWorkedShift";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class CalendarService {

  public constructor(private http: HttpClient) { }

  /**
   * Get organization holidays
   *
   * @param idOrganization
   */
  public getOrgHolidays(idOrganization: number): Observable<any> { 
    return this.http.get(environment.api_url + '/orgHolidays/organization/' + idOrganization);
  };
 
  /**
   * Create new holiday
   * @param holiday 
   * @returns 
   */
  public addHoliday(holiday: { idOrganization: number, date: string }): Observable<any> { 
    return this.http.post(environment.api_url + '/orgHoliday', holiday);
  };
 
  /**
   * Delete holiday
   * @param idOrganization 
   * @param date 
   * @returns 
   */
  public removeHoliday (idOrganization: number, date: string): Observable<any> { 
    return this.http.delete(environment.api_url + '/orgHoliday/organization/' + idOrganization + '/date/' + date);
  }
 
  /**
   * get entity calendar
   * @param idEntity 
   * @returns 
   */
  public getEntityWorkedCalendarFromDate(idEntity: number, date: string): Observable<any> { 
    return this.http.get(environment.api_url + '/workedCalendar/entity/' + idEntity+ '/date/' + date);
  };
 
  /**
   * get entity calendar
   * @param idOrganization 
   * @returns 
   */
  public getOrganizationWorkedCalendarFromDate(idOrganization: number, date: string): Observable<any> { 
    return this.http.get(environment.api_url + '/workedCalendar/organization/' + idOrganization + '/date/' + date);
  };
 
  /**
   * update entity calendar
   * @param calendar 
   * @returns 
   */
  public updateWorkedCalendar(calendar: { workedCalendar: { idWorkedCalendar: number, date: string, worked: boolean }[] }): Observable<any> { 
    return this.http.put(environment.api_url + '/workedCalendar', calendar);
  };
 
  /**
   * create entity calendar
   * @param shifts 
   * @returns 
   */
  public newWorkedCalendar(shifts: any): Observable<any> { 
    return this.http.post(environment.api_url + '/workedCalendar', shifts);
  };
 
  /**
   * create special day shift worked
   * @param especialDayWorked 
   * @returns 
   */
  public updateSpecialDayWorked(especialDayWorked: { idWorkedCalendar: number, date: string, worked: boolean }[]): Observable<any> {
    return this.http.post(environment.api_url + '/specialDayWorked', especialDayWorked);
  };

}
