import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MachineAvailabilityOptionsEntity } from '../interfaces/machineAvailabilityOptionsEntity';

@Injectable({
  providedIn: 'root'
})
export class MachineAvailabilityService {

  public constructor(private http: HttpClient) { }

   /**
   * Retrieves all machineAvailabilitySettings
   */
   public getMachineAvailabilitySettings(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<any[]> {
    let queryParams = new HttpParams();
    if (limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if (offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if (sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if (direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if (filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<any[]>(environment.api_url + '/machineAvailabilitySettings', { params: queryParams });
  };


  /**
   * Retrieves Machine Availability data
   */
  public get(idEntity: string): Observable<MachineAvailabilityOptionsEntity> {
    return this.http.get<MachineAvailabilityOptionsEntity>(environment.api_url + '/machine-availability/entity/' + idEntity);
  };
  
  
  /**
  * Save machineAvailability
  *
  * @param machineAvailability
  */
  public newMachineAvailability(machineAvailability: MachineAvailabilityOptionsEntity): Observable<MachineAvailabilityOptionsEntity> { 
      return this.http.post<MachineAvailabilityOptionsEntity>(environment.api_url + '/machine-availability', machineAvailability);
  };
  
  /**
  * Update machineAvailability
  *
  * @param machineAvailability
  */
  public updateMachineAvailability(machineAvailability: MachineAvailabilityOptionsEntity): Observable<any> { 
      return this.http.put(environment.api_url + '/machine-availability', machineAvailability);
  };
}
