import { Component,
         OnInit,
         ElementRef,
         ViewChild,
         Inject,
         Output,
         Input}                                   from '@angular/core';
import { Router, ActivatedRoute, Params }           from '@angular/router';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Location }                                 from '@angular/common';

import { MasterSetting }        from '../masterSetting';
import { MasterSettingService } from '../masterSetting.service';
import { SettingService } from '../../settings/setting.service';

import { App }              from '../../apps/app';
import { AlertService } from '../../../alert.service';
import { Setting } from '../../settings/setting';
import { AuthService } from '../../../auth.service';

declare var _:any;

@Component({
  selector: 'masterSetting-entity',
  templateUrl: './masterSetting-entity.component.html',
  styleUrls: ['./masterSetting-entity.component.css'],
  providers: [ MasterSettingService ]

})
export class MasterSettingEntityComponent implements OnInit {

  errorMessage: string;
  confirmPassword: string;
  masterSetting: MasterSetting;
  apps: App[];
  masterSettingApps: App[];
  isAdmin: boolean = false;
  isPalletized: boolean = false;
  enableInvertedValueToogle: boolean = false;
  idEntity: number;
  @Output() editMode: boolean = false;
  @Input() entitySettings: Setting[];
  @Input() masterSettings: any[];
  @Input() entityDatasources: any[];
  module: string = 'Machine settings';
  cardHeaderState: string = 'Machine settings';
  settings: any [];
  canCreate: boolean = true;
  displayedColumns: string[] = ['Active', 'Description', 'Value'];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private masterSettingService: MasterSettingService,
    private settingService: SettingService,
    private _location: Location,
    private alertService: AlertService,
    private authService: AuthService
    // ,
    // public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.authService.getRole().then((role) => {
      if ( role == 1) { this.isAdmin = true;}
    if ( role == 3) { this.canCreate = false;}
    this.route.data
      .subscribe((data: { masterSetting: MasterSetting}) => {
        this.idEntity = data["entity"].idEntity;
        // VALIDATION TO CHECK IF IS PALLET
        this.entitySettings.map( entitySetting => {
          if (entitySetting["masterSetting"] && entitySetting["masterSetting"].name == 'palletCount' && entitySetting.value != '0' ) {
            this.isPalletized = true;
          }
        })
        let enableInvertedValue = _.find(this.entityDatasources, function(e) { return e.datasourceModel.sourceKey == 'DataSourceBasicConfig'; });
        if (enableInvertedValue) {
            this.enableInvertedValueToogle = true;
        }


        this.masterSettings.map( masterSetting => {
          // First hide the pallet settings
          // if (masterSetting.name == 'palletText' || masterSetting.name == 'palletCount') {
          //     masterSetting.visible = this.isPalletized;
          // }
          //
          // if (masterSetting.name == 'emergencyActiveValueInverted') {
          //     masterSetting.visible = this.enableInvertedValueToogle;
          // }

          if (this.entitySettings.length > 0) {
              for (let i = 0; i < this.entitySettings.length; i++) {
                if (this.entitySettings[i].idMasterSetting == masterSetting.idMasterSetting) {
                    if (masterSetting.type == 'boolean') {
                        if (this.entitySettings[i].value == '1') {
                            masterSetting["value"] = true;
                        }
                        else {
                            masterSetting["value"] = false;
                        }
                    }
                    else {
                      masterSetting["value"] = this.entitySettings[i].value;
                      // masterSetting["enabled"] = this.entitySettings[i].enabled;
                    }
                    break;
                }
                else  {
                    if (masterSetting["defaultValue"]) {
                      if (masterSetting.type == 'boolean') {
                        if (masterSetting["defaultValue"] == '1') {
                            masterSetting["value"] = true;
                        }
                        else {
                            masterSetting["value"] = false;
                        }
                      }
                      else {
                        masterSetting["value"] = masterSetting["defaultValue"];
                      }
                    }
                    else {
                      masterSetting["value"] = '';
                    }
                    // masterSetting["enabled"] = false;
                }
              }
          }
          else  {
              if (masterSetting["defaultValue"]) {
                if (masterSetting.type == 'boolean') {
                  if (masterSetting["defaultValue"] == '1') {
                      masterSetting["value"] = true;
                  }
                  else {
                      masterSetting["value"] = false;
                  }
                }
                else {
                  masterSetting["value"] = masterSetting["defaultValue"];
                }
              }
              else {
                masterSetting["value"] = '';
              }

              // masterSetting["enabled"] = false;
          }

          // _.find(users, function(o) { return o.age < 40; });

        })

        this.masterSettings.map( masterSetting => {
          let found = _.find(this.entitySettings, function(o) {
            o;
            return masterSetting.idMasterSetting == o.idMasterSetting ;
          });
          if (found) {
              masterSetting["enabled"] = found.enabled;
          }
          else {
            masterSetting["enabled"] = false;
          }
        })

        var removed = _.remove(this.masterSettings, function(m) {
          return m.visible == false;
        });
        // _.filter(this.masterSettings, 'visible');
        this.masterSettings = _.orderBy(this.masterSettings, ['type'],['asc']);

      });
    });
  }

  onheaderActionEmitter(message:any):void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        // this.onNew();
        break;
      case 'save':
        this.onSave();
        // this.onSelect(message.data);
        this.editMode = !this.editMode;
        break;
      case 'cancel':
        // this.onEdit(message.data);
        this.editMode = !this.editMode;
        // console.log(this.masterSetting);
        break;
      case 'remove':
        this.onDelete();
        // this.onRemove(message.data);
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  onGroupsChange (selected, idMasterSetting) {
    let index = _.findIndex(this.masterSettings, function(o) { return o.idMasterSetting == idMasterSetting; });
    this.masterSettings[index].enabled = selected.checked;
    selected;
  }

  isPallet(event: any): void {
         this.masterSettings.map( masterSetting => {
           if (masterSetting.name == 'palletText' || (masterSetting.name == 'palletCount')) {
              masterSetting.visible  = this.isPalletized;
               if (!this.isPalletized) {
                   // set default value for these settings
                   if (masterSetting.type == 'boolean') {
                     masterSetting["value"] = false;
                   }

                   if (masterSetting.type == 'integer') {
                     masterSetting["value"] = 0;
                   }

                   if (masterSetting.type == 'object') {
                     masterSetting["value"] = '';
                   }

                   masterSetting["forSave"] = true;
               }
           }
         })
  }

  onCancel(): void {
    this._location.back();
  }

  onSave(): void {
    // validateJSONObject
    let objectMasterSettings =_.filter(this.masterSettings, function(o) { return o.type == 'object'; });
    let jsonErrors = []
    objectMasterSettings.map( masterSetting => {
      try {
          JSON.parse(masterSetting.value);
      } catch (e) {
          jsonErrors.push(masterSetting.description);
      }
    });

    if (jsonErrors.length > 0) {
      let error = `There are invalid JSON values in the following settings: ${jsonErrors.join(', ')}`;
      this.alertService.emitErrorMessage({text: error, type: 'danger'});
    }
    else {
      let result =  [];
      this.masterSettings.map( masterSetting => {
        if (masterSetting.type == 'boolean') {
            if (masterSetting.value == '' || !masterSetting.hasOwnProperty('value')) {
                masterSetting.value = false;
            }
        }

        // if (masterSetting.type == 'integer') {
        //   if (masterSetting.value == '') {
        //       masterSetting.value = '0';
        //   }
        // }
       // let eachSetting = new Setting(0,this.idEntity,masterSetting.idMasterSetting,masterSetting.value,masterSetting.enabled);

       let eachSetting = {
         "idSetting": 0,
         "idEntity": this.idEntity,
         "idMasterSetting":masterSetting.idMasterSetting,
         "value": masterSetting.value,
         "enabled": masterSetting.enabled,
         "type": masterSetting.type,
         "defaultValue": masterSetting.defaultValue
       }
       result.push(eachSetting)
       // this.settings[0] = eachSetting;
      })

      this.settingService.newEntitySettings(result)
          .subscribe(
            response => {
              this.alertService.emitErrorMessage({text: response["_body"], type: 'success'});
              // this._location.back();
            },
            error =>  {

              this.alertService.emitErrorMessage({text: error, type: 'success'});
            });
    }
  }

  onDelete(): void {
    this.masterSettingService.removeMasterSetting(this.masterSetting.idMasterSetting)
        .subscribe(
          response => {
            this._location.back();
          },
          error =>  this.errorMessage = <any>error);
  }


}
