<div class="table-container mat-elevation-z8">

  <mat-card-header [ngClass]="{'white-label': !insideEntity}"
    [style.background-color]="insideEntity ? null : cardHeaderColor">

    <mat-card-title [hidden]="insideEntity">
      <button *ngIf="!hideBackButton" class="back_button" mat-icon-button (click)="onBack()">
        <mat-icon class="size-28" aria-label="Back">arrow_back</mat-icon>
      </button>
      {{ this.menuTitle }}
    </mat-card-title>
    <mat-card-actions>
      <!-- Action buttons (if any) -->
      <button *ngIf="canCreate && csvButton" class="onleft-margin" mat-icon-button (click)="onCsv()"
        matTooltip="CSV bulk import/export" matTooltipPosition="left">
        <mat-icon aria-label="Add">insert_drive_file</mat-icon>
      </button>
      <button *ngIf="canCreate && !insideLicense" class="onleft-margin" mat-icon-button (click)="onNew()">
        <mat-icon aria-label="Add">add</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card-header>

  <div class="loading-shade" *ngIf="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>

  <mat-table [dataSource]="dataSource" matSort matSortDisableClear>

    <ng-container *ngFor="let field of _fields; let i = index" [matColumnDef]="field.id">

      <mat-header-cell *matHeaderCellDef class="header-cell-stacked">
        <span *ngIf="!field.sortable">{{ field.header }}</span>
        <span *ngIf="field.sortable" mat-sort-header>{{ field.header }}</span>
        <input *ngIf="field.filterable && field.id !== 'entityInfo.connectivity'" matInput class='filterInput' [ngModel]="filter[field.id]"
          (keyup)="applyFilter(field.id, $event.target.value)"
          placeholder="Filter {{ lowerCaseFirstLetter(field.header) }}" />
          <ng-container *ngIf="field.id === 'entityInfo.connectivity'">
            <mat-select multiple placeholder="Filter by color" [ngModel]="filter['connectivity_color']" (selectionChange)="applyFilter(field.id, $event.value, 'connectivity_color')">
              <!-- <mat-option value="">All colors</mat-option> -->
              <mat-option value="green">Green</mat-option>
              <mat-option value="blue">Blue</mat-option>
              <mat-option value="yellow">Yellow</mat-option>
              <mat-option value="red">Red</mat-option>
            </mat-select>
          </ng-container>
      </mat-header-cell>

      <mat-cell *matCellDef="let element" [ngSwitch]="field.type" class="ellipsis" fxFlex="10px">
        <span *ngIf="getValue(element, field.display) != null">
          <!--TYPE DEFAULT-->
          <span *ngSwitchDefault title="{{getValue(element, field.display)}}">{{getValue(element,
            field.display)}}</span>
          <!--END TYPE DEFAULT-->

          <!--TYPE ENTITY_ARRAY-->
          <span *ngSwitchCase="'entityArray'">
            <span *ngIf="getValue(element, 'allEntities'); else entitiesElseBlock">
              All
            </span>
            <ng-template #entitiesElseBlock>
              <span *ngFor="let entity of getValue(element, field.display)">{{getValue(entity, 'name')}}, </span>
            </ng-template>
          </span>
          <!--END TYPE ARRAY-->

          <!--TYPE IMAGE-->
          <span *ngSwitchCase="'image'">
            <span *ngIf="field.display == 'image'; else imageElseBlock">
              <img alt="{{getValue(element, 'name')}}" title="{{getValue(element, 'name')}}"
                src="{{getValue(element, field.display)}}" class="table-img">
            </span>
            <ng-template #imageElseBlock>
              <img alt="{{getValue(element, field.display.concat('.name'))}}"
                title="{{getValue(element, field.display.concat('.name'))}}"
                src="{{getValue(element, field.display.concat('.image'))}}" class="table-img">
              <span *ngIf="field.show === false" style="display:none;">{{getValue(element, field.display.concat('.name'))}}</span>
              <span *ngIf="field.show === true" style="margin-left: 0.5rem;">{{getValue(element, field.display.concat('.name'))}}</span>
            </ng-template>
          </span>
          <!--END TYPE IMAGE-->

          <!--TYPE DATE-->
          <span *ngSwitchCase="'date'" title="{{getValue(element, field.display)}}">{{getValue(element, field.display) |
            date:'yyyy/MM/dd HH:mm:ss'}}
          </span>
          <!--END TYPE DATE-->

          <!--TYPE STATUS-->
          <span *ngSwitchCase="'status'">
            <span *ngIf="element.status == 0" title="Connected and receiving data">
              <mat-icon aria-label="Connected and receiving data"
                class="size-28 green-color">cloud_done_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">Connected and receiving data</span>
            </span>
            <span *ngIf="element.status == 1" title="Connected but not receiving data">
              <mat-icon aria-label="Connected but not receiving data" class="size-28 orange-color">cloud</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px; ">Connected but not receiving data</span>
            </span>
            <span *ngIf="element.status == 2" title="Not connected">
              <mat-icon aria-label="Not connected" class="size-28 red-color">cloud_off_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">Not connected</span>
            </span>
            <span *ngIf="element.status ==-1" title="Loading...">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </span>
          </span>
          <!--END TYPE STATUS-->

          <!--TYPE CONNECTIVITY STATUS-->
          <span *ngSwitchCase="'connectivityStatus'">
            <span *ngIf="element.connectivityStatus == 1" title="Connected">
              <mat-icon aria-label="Connected" class="size-28 green-color">cloud_done_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">Connected</span>
            </span>
            <span *ngIf="element.connectivityStatus == 2" title="Not connected">
              <mat-icon aria-label="Not connected" class="size-28 red-color">cloud_off_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">Not connected</span>
            </span>
            <span *ngIf="element.connectivityStatus ==-1" title="Loading...">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </span>
          </span>
          <!--END TYPE CONNECTIVITY STATUS-->

          <!--TYPE DATASOURCE STATUS-->
          <span *ngSwitchCase="'datasourceStatus'">
            <span *ngIf="getValue(element, field.display.concat('.status')) == 0"
              title="{{getValue(element, field.display.concat('.info'))}}&#013;Last connected on {{getValue(element, field.display.concat('.ts')) *1000 | date:'yyyy/MM/dd HH:mm:ss'}}">
              <mat-icon class="size-28">help_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">
                {{getValue(element, field.display.concat('.info'))}}
              </span>
            </span>
            <span *ngIf="getValue(element, field.display.concat('.status')) == 1"
              title="{{getValue(element, field.display.concat('.info'))}}&#013;Last connected on {{getValue(element, field.display.concat('.ts')) *1000 | date:'yyyy/MM/dd HH:mm:ss'}}">
              <mat-icon class="size-28 green-color">cloud_done_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px; ">
                {{getValue(element, field.display.concat('.info'))}}
              </span>
            </span>
            <span *ngIf="getValue(element, field.display.concat('.status')) == 2"
              title="{{getValue(element, field.display.concat('.info'))}}&#013;Last connected on {{getValue(element, field.display.concat('.ts')) *1000 | date:'yyyy/MM/dd HH:mm:ss'}}">
              <mat-icon class="size-28 red-color">cloud_off_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">
                {{getValue(element, field.display.concat('.info'))}}
              </span>
            </span>
            <span *ngIf="getValue(element, field.display.concat('.status')) == 3"
              title="{{getValue(element, field.display.concat('.info'))}}&#013;Last connected on {{getValue(element, field.display.concat('.ts')) *1000 | date:'yyyy/MM/dd HH:mm:ss'}}">
              <mat-icon class="size-28 orange-color">cloud</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">
                {{getValue(element, field.display.concat('.info'))}}
              </span>
            </span>
            <span *ngIf="getValue(element, field.display.concat('.status')) == 4"
              title="{{getValue(element, field.display.concat('.info'))}}&#013;Last connected on {{getValue(element, field.display.concat('.ts')) *1000 | date:'yyyy/MM/dd HH:mm:ss'}}">
              <mat-icon class="size-28" style="color:#e8df45;">cloud</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">
                {{getValue(element, field.display.concat('.info'))}}
              </span>
            </span>
            <span *ngIf="getValue(element, field.display.concat('.status')) ==-1" title="Loading...">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </span>
          </span>
          <!--END TYPE DATASOURCE STATUS-->

          <!--TYPE REALTIME-->
          <span *ngSwitchCase="'realTime'">
            <span *ngIf="element.loading" title="Loading...">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </span>

            <span *ngIf="!element.loading && element.realTime == 'null'" title="Not value" class="red-color">
              <strong>N/A</strong>
            </span>
            <!--
            <span *ngIf="element.realTime == '1'" title="Connected but not receiving data" >
              <mat-icon aria-label="Connected but not receiving data" class="size-28 orange-color">cloud</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px; ">Connected but no value</span>
            </span> -->

            <span *ngIf="element.realTimeStatus" title="  {{element.realTime}}">
              <i>{{element.realTime}}</i>
            </span>
          </span>
          <!--END TYPE REALTIME-->

          <!--TYPE ICON-->
          <span *ngSwitchCase="'icon'">
            <ng-container
              *ngIf="getValue(element, field.display); else elseTemplate">
              <img src="{{getValue(element, field.display)}}" alt="{{getValue(element, 'name')}}"
                title="{{getValue(element, 'name')}}"
                style="width: 45px; height: 45px; font-size: 45px;vertical-align: middle;">
            </ng-container>
            <ng-template #elseTemplate>
              <span class="{{getValue(element, field.display)}}" title="{{getValue(element, 'name')}}"
                style="font-size: 3em !important;"></span>
            </ng-template>
          </span>
          <!--END TYPE ICON-->

          <!--TYPE CHIP-->
          <span *ngSwitchCase="'chip'" class="bs-label"
            [ngStyle]="{'background-color':getValue(element, 'role.color')}">
            {{getValue(element, field.display)}}
          </span>
          <!--END TYPE CHIP-->

          <!--TYPE CHIP2 for Role-->
          <span *ngSwitchCase="'chip2'" class="bs-label" [ngStyle]="{'background-color':getValue(element, 'color')}">
            {{getValue(element, field.display)}}
          </span>
          <!--END TYPE CHIP2 for Role-->

          <!--TYPE COLOR-->
          <span *ngSwitchCase="'color'" class="circle-color"
            [style.background-color]="getValue(element, field.display)"></span>
          <!--END TYPE COLOR-->

          <!--TYPE COLOR AND ALT-->
          <span *ngSwitchCase="'color-title'">
            <ng-container *ngIf="getValue(element, field.display)?.color; else loadingTemplate">
              <span
                class="circle-color"
                [style.background-color]="getValue(element, field.display).color"
                title="{{ getValue(element, field.display).text }}"
              ></span>
            </ng-container>
            <ng-template #loadingTemplate>
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </ng-template>
          </span>
          <!-- <span *ngSwitchCase="'color-title'" class="circle-color" [style.background-color]="getValue(element, field.display).color" title="{{getValue(element, field.display).text}}"></span> -->
          <!--END TYPE COLOR-->

          <!--TYPE CHECK-->
          <span *ngSwitchCase="'check'">
            <mat-slide-toggle [checked]="getValue(element, field.display)" (click)="prevent($event)"></mat-slide-toggle>
          </span>

          <!--END TYPE CHECK-->

          <span *ngSwitchCase="'checkBox'">
            <span (click)="clickDelete($event, element)">
              <mat-checkbox> <br></mat-checkbox>
            </span>
          </span>

          <span *ngSwitchCase="'licenseButton'">
            <button title="Unassign machine from license" mat-icon-button (click)="openDialog($event, element)">
              <mat-icon aria-label="Delete">remove_circle</mat-icon>
            </button>
          </span>

          <span *ngSwitchCase="'license2full'">
            <span *ngIf="isEvaluationMode(element, field.display); else noneCell">
              <button title="Change machine to full license mode" mat-icon-button
                (click)="change2FullMode($event, element)">
                <mat-icon aria-label="change">publish</mat-icon>
              </button>
            </span>
            <ng-template #noneCell>
            </ng-template>
          </span>

          <span *ngSwitchCase="'licenseSeatEdit'">
            <span>
              <button title="Change machine to full license mode" mat-icon-button
                (click)="licenseSeatEdit($event, element)">
                <mat-icon aria-label="date_range">date_range</mat-icon>
              </button>
            </span>
            <!-- <ng-template #noneCell>
                  </ng-template> -->
          </span>

          <span *ngSwitchCase="'expirationDate'">
            <span *ngIf="isEvaluationMode(element, field.display); else expDate">
              Set-up
            </span>
            <ng-template #expDate>
              <span title="{{getValue(element, field.display).finish}}">{{getValue(element,
                field.display).finish}}</span>
            </ng-template>
          </span>

          <!--TYPE CHANNELS-->
          <span *ngSwitchCase="'channels'">
            <span *ngFor="let e of getValue(element, field.display)" class="{{getValue(e, 'icon')}}"
              style="font-size: 2em !important;">
            </span>
          </span>
          <!--END TYPE CHANNELS-->
          
          <!--START SEATINFO-->
          <span *ngSwitchCase="'seatInfo'">
            <span [style.color]="getValue(element, field.display).color">{{getValue(element, field.display).text}}</span>
          </span>
          <!--END SEATINFO-->

          <!--START LICENSEAPPS-->
          <span *ngSwitchCase="'licenseApps'" class="icons-apps">
            <span *ngFor="let e of getValue(element, field.display)">
              <ng-container *ngIf="getValue(e, 'icon'); else elseTemplate">
                <img matTooltip="{{getValue(e, 'name')}}" src="{{getValue(e, 'icon')}}" alt="{{getValue(e, 'name')}}"
                  style="width: 30px; height: 30px; font-size: 30px;vertical-align: middle; margin: 2px;">
              </ng-container>
              <ng-template #elseTemplate>
                <span class="{{getValue(e, 'icon')}}" title="{{getValue(e, 'name')}}"
                  style="font-size: 2em !important;"></span>
              </ng-template>
            </span>
          </span>
          <!--END LICENSEAPPS-->

          <!--TYPE CATEGORIZATION STATUS-->
          <span *ngSwitchCase="'categorizationStatus'" title="{{getValue(element, field.display)}}">
            <span *ngIf="getValue(element, field.display) == true; else:pending" style="color:green"
              title="Categorized">Categorized</span>
            <ng-template #pending>
              <span style="color:red" title="Pending categorization">Pending</span>
            </ng-template>
          </span>
          <!--TYPE CATEGORIZED STATUS-->

          <!--TYPE BOOLEAN CHECK ICON O ERROR ICON-->
          <span *ngSwitchCase="'dinamicMatIcon'" title="{{getValue(element, field.display).title}}">
            <mat-icon class="size-42 mat-icon material-icons {{getValue(element, field.display).color}}">{{getValue(element, field.display).icon}}</mat-icon>
          </span>
          <!--TYPE CATEGORIZED STATUS-->
          
          <!--TYPE MORE INFO ICON-->
          <span *ngSwitchCase="'moreInfo'" title="{{getValue(element, field.display)}}">
            <mat-icon class="size-42 green-color">add_circle</mat-icon>
          </span>
          <!--TYPE CATEGORIZED STATUS-->

          <!--TYPE SELECT NOT SELECTABLE-->
          <span *ngSwitchCase="'select-option-not-selectable'" title="{{getValue(element, field.display)}}">
            <mat-form-field appearance="outline">
              <mat-select name="Parent-organization" [(value)]="getValue(element, field.display)[0]">
                <mat-option *ngFor="let option of getValue(element, field.display)" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <!--TYPE SELECT NOT SELECTABLE-->

        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="idFields"></mat-header-row>
    <span *ngIf="!insideLicense; else noSelect">
      <mat-row *matRowDef="let row; columns: idFields;" (click)="onSelect($event, row)"
        class="selectable-row"></mat-row>
    </span>
    <ng-template #noSelect>
      <mat-row *matRowDef="let row; columns: idFields;"></mat-row>
    </ng-template>

  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>

</div>
