import { Injectable }             from '@angular/core';
import { Router, Resolve,
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs';

import { App }          from './../app';
import { AppService }   from './../app.service';


@Injectable()
export class MasterVariableAppListResolver implements Resolve<App[]> {
  constructor(private appService: AppService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<App[]> {
    //return this.appService.getProviderApps(route.params['idOrganization'])
    return this.appService.getMasterVariableApps(route.params['id'])
  }
}
