<mat-card-header>
  <mat-card-actions *ngIf="this.entity">
    <span [ngSwitch]="cardHeaderState">

      <span *ngSwitchCase="'new'">
        <button class="onleft-margin"  mat-icon-button (click)="onCancel()" title="Cancel">
          <mat-icon aria-label="Cancel">clear</mat-icon>
        </button>
        <button class="onleft-margin"  mat-icon-button (click)="onSave(account)" title="Accept">
          <mat-icon aria-label="Accept">done</mat-icon>
        </button>
      </span>

      <span *ngSwitchCase="'edit'">
        <button class="onleft-margin" mat-icon-button *ngIf="canEdit" (click)="onEdit()" title="Edit">
        <mat-icon aria-label="Edit">mode_edit</mat-icon>
      </button>
    </span>

    </span>
  </mat-card-actions>
</mat-card-header>

<div [ngSwitch]="cardHeaderState">
  <div  *ngSwitchCase="'new'">
    <calendar-generator 
      #calendarGenerator 
      [entity]="entity"
      (reload)='reload()'>
    </calendar-generator>
  </div>
  <div *ngSwitchCase="'edit'">
    <div class="row text-center">
      <div class="calendar-header">
        <button
          mat-stroked-button
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="onDateChange($event)">
          Today
        </button>
        <button
          mat-icon-button
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="onDateChange($event)">
          <mat-icon aria-label="Previous">keyboard_arrow_left</mat-icon>
        </button>
        <button
          mat-icon-button
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="onDateChange($event)">
          <mat-icon aria-label="Next">keyboard_arrow_right</mat-icon>
        </button>
        <div class="calendar-title">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</div>
      </div>
    </div>
    <br />
    
    <mwl-calendar-month-view
      weekStartsOn="1"
      [cellTemplate]="customCellTemplate"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (beforeViewRender)="beforeMonthViewRender($event)"
      (dayClicked)="dayClicked($event.day)">
    </mwl-calendar-month-view>
    
    
    <ng-template #customCellTemplate let-day="day" let-locale="locale">
      <div class="cal-cell-top">
        <span class="cal-day-number">
          {{ day.date | calendarDate:'monthViewDayNumber':locale }}
        </span>
      </div>
      <div class="events">
        <div *ngFor="let event of day.events" [ngClass]="event.worked ? 'event' : 'event no-worked'" [style.background-color]="event.color.primary">
        <!-- <div *ngFor="let event of day.events" [ngClass]="event.worked ? 'event' : 'event no-worked'" [style.background-color]="event.color.primary"> -->
          {{event.title}}
        </div>
      </div>
    </ng-template>
  </div>
</div>