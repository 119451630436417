import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs';

import { Role }          from './../role';
import { RoleService }   from './../role.service';


@Injectable()
export class RoleEditResolver implements Resolve<Role> {
  constructor(private roleService: RoleService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Role> {
    return this.roleService.getRole(route.params['id'])
  }
}
