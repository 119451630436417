import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrganizationListComponent }   from './organization-list/organization-list.component';
import { OrganizationEditComponent }   from './organization-edit/organization-edit.component';


const organizationsRoutes: Routes = [
  { path: '',
    children: [
      {path: 'new', component: OrganizationEditComponent,
      },
      {path: 'edit/:id', component: OrganizationEditComponent,
      },
      {path: '', component: OrganizationListComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(organizationsRoutes)],
  exports: [RouterModule],
  providers: []
})
export class OrganizationsRoutingModule { }
