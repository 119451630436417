import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { EntityReport } from './entityReport';
import { Report } from './report';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class ReportService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves current entity reports
   *
   * @param idEntity
   */
  public getReports(idEntity: string): Observable<Report[]> { 
    return this.http.get<Report[]>(environment.api_url + '/reports/entity/' + idEntity);
  };

    /**
   * Retrieves current entity reports
   *
   * @param idEntity
   */
    public getSubReports(idEntity: string, parentReport: string): Observable<Report[]> { 
    return this.http.get<Report[]>(environment.api_url + '/reports/entity/' + idEntity + '/parentReport/' + parentReport);
    };

  /**
   * Retrieves missing reports
   *
   * @param idEntity
   */
  public getMissingReports(idEntity: string): Observable<Report[]> { 
    return this.http.get<Report[]>(environment.api_url + '/missingReports/entity/' + idEntity);
  };
    
  /**
   * Retrieves entity's report
   *
   * @param idEntity
   */
  public getEntityReport(idEntity: string, idReport: number): Observable<EntityReport> { 
    return this.http.get<EntityReport>(environment.api_url + '/entityReport/entity/' + idEntity + '/report/' + idReport);
  };

  /**
   * Update EntityReport
   *
   * @param EntityReport
   */
  public updateReport(entityReport: EntityReport): Observable<any> { 
    return this.http.put(environment.api_url + '/entityReport', entityReport);
  };

  /**
   * Create new EntityReport
   *
   * @param EntityReport
   */
  public newEntityReport(entityReport: EntityReport): Observable<any> { 
    return this.http.post(environment.api_url + '/entityReport', entityReport);
  };


  public canCreateReport(idEntity: number): Observable<any> { 
    return this.http.get(environment.api_url + '/canCreateReport/entity/' + idEntity);
  };

  public removeReport(idEntity: number, idReport: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/entityReport/entity/' + idEntity + '/report/' + idReport);
  };
    
}
