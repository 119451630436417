import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Metric } from '../metric';
import { MetricsService } from '../metrics.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { MetricsListDetailsComponent } from '../metrics-list-details/metrics-list-details.component';
import { QueryStore } from '../../shared/store/query.store';
import { DateFormatPipe } from '../../../pipes/date-format/date-format.pipe';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-metrics-list',
  templateUrl: './metrics-list.component.html',
  styleUrls: ['./metrics-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MetricsListComponent implements OnInit {
  public lastMetricsData: Metric[];
  @Input() set lastMetrics(metrics: Metric[]){
      this.lastMetricsData = metrics;
      this.onGetData();
  };

  metrics: Metric[];
  module: string = 'Metrics';
  menuTitle: string = 'Metrics';
  loading: boolean = true;
  messageWarning: string = '';

  query: any = {pageSize: 10, pageIndex: 0, sort: null, direction: null, filter: null};
  fields: Array<any>= [
    { id: 'metricName', display: 'metricName', header: 'Metric', sortable: false, filterable: false },
    { id: 'checkTimestamp', display: 'checkTimestampFormat', header: 'Date', sortable: false, filterable: false },
    { id: 'status', display: 'icon', header: 'Status', type: 'dinamicMatIcon', sortable: false, filterable: false },
  ];

  errorMessage: string;
  canCreate: boolean = false;

  mainDatasourceInDatasources:boolean = false;

  constructor(
    public metricsService: MetricsService,
    public dialog: MatDialog,
    private queryStore: QueryStore,
    private dateFormatPipe: DateFormatPipe
  ) { }

  ngOnInit() {
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
      case 'select':
        this.openDialog(message.data);
        break;
    }
  }
      
  async onGetData(){
    this.loading = false;
    this.lastMetricsData['rows'] = await this.dataFormatMetrics(this.lastMetricsData['rows']);
  }
  
  private dataFormatMetrics(metricsData): Metric[] {
    if (metricsData && metricsData.length) {
      for (let i = 0; i < metricsData.length; i++) {
        const metric = metricsData[i];
        let mainName = '';
        if (!_.isEmpty(metric.datasource) && metric.datasource.main) {
          this.mainDatasourceInDatasources = true;
          mainName = '* ';
        }
        metric.metricName = !_.isEmpty(metric.datasource) ? mainName + metric.name + ' ' + metric.datasource.name +  ' (' + metric.datasource.address + ')' : metric.name;
        metric.checkTimestampFormat = this.dateFormatPipe.transform(metric.checkTimestamp, 'DD/MM/YYYY HH:mm:ss', true);
        if (metric.metricDown) {
          this.messageWarning = 'Metrics are out of date, machine is probably offline, otherwise contact Vixion.';
        }
      }
    }
    return metricsData;
  }

  public openDialog(metric: Metric) {
    const dialogRef = this.dialog.open(MetricsListDetailsComponent, {data: metric});

    dialogRef.afterClosed().subscribe(result => {
      this.queryStore.deleteAllQueries();
    });
  }

}
