import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Report } from './../report';
import { ReportService } from './../report.service';
import { AuthService } from '../../../auth.service';
import { Entity } from '../../entities/entity';
import { Subscription } from 'rxjs';

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css'],
  providers: [ ReportService ]
})

export class ReportListComponent implements OnInit {

  reports: Report[];
  module: string = 'Reports manager';
  // table_headers: Array<string>= ['Name', 'Units'];
  fields: Array<any>= [
    { id: 'entity.dataloggerSoftware.dataloggerHardware.organization.name', display: 'entity.dataloggerSoftware.dataloggerHardware.organization', header: 'Machine owner', type: 'image', sortable: true, filterable: true },
    { id: 'entity.name', display: 'entity.name', header: 'Machine name', sortable: true, filterable: true },
    { id: 'name', display: 'name', header: 'Report name', sortable: true, filterable: true },
    { id: 'description', display: 'description', header: 'Description', sortable: true, filterable: true }
  ];
  menuTitle: string = 'Reports';
  insideEntity: boolean = false;
  loading: boolean = true;

  query: any = {pageSize: 10, pageIndex: 0, sort: 'name', direction: 'asc', filter: null};

  entity: Entity;

  isAdmin: boolean = false;
  canCreate: boolean = false;
  errorMessage: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.authService.getRole().then((role) => {
      // vixioners have access to create and Vixioner, Provider, End User to list and edit
      if (role == 1) {
        this.isAdmin = true;
        this.canCreate = true;
      } else if (role == 2) {
        this.reportService.canCreateReport(this.entity.idEntity).subscribe((res)=>{
          if (res.canCreateReport) {
            this.canCreate = true;
          }
        });
      }
    });
    this.route.data
      .subscribe((data: { entity: Entity }) => {
        if(data.entity) {
          this.entity = data.entity;
          this.insideEntity = true;
          this.fields = [
            { id: 'name', display: 'name', header: 'Report name', sortable: true, filterable: true },
            { id: 'description', display: 'description', header: 'Description', sortable: true, filterable: true }
          ];
        }
      });
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    if (this.entity) {
      this.router.navigate(['/reports/new'], { queryParams: { entity: this.entity.idEntity } });
    } else {
      this.router.navigate(['/reports/new']);
    }
  }

  onSelect(report: Report){
    if(report.name.toLocaleLowerCase().includes('production') ) {
      this.router.navigate(['/reports/edit', this.entity.idEntity, report.idReport ]);
    } else if(report.name.toLocaleLowerCase().includes('geometrics')) {
      this.router.navigate([`/reports/geometrics`, this.entity.idEntity ]);
    } else if(report.name.toLocaleLowerCase().includes('cycle')) {
      this.router.navigate([`/reports/cycle`, this.entity.idEntity ]);
    } else {
      this.router.navigate([`/reports/${report.name.toLocaleLowerCase()}`, this.entity.idEntity, report.idReport ]);

    }
  }

  onEdit(report: Report){
    if (report['reportType'] && report['reportType'] === 8) {
      this.router.navigate(['/reports/cycle-report-generator-edit/' + report.idReport]);
    } else if(report.name.toLocaleLowerCase().includes('production') ) {
      this.router.navigate(['/reports/edit', this.entity.idEntity, report.idReport ]);
    } else if(report.name.toLocaleLowerCase().includes('geometrics')) {
      this.router.navigate([`/reports/geometrics`, this.entity.idEntity ]);
    } else {
      this.router.navigate([`/reports/${report.name.toLocaleLowerCase()}`, this.entity.idEntity, report.idReport ]);
    }
  }

  onRemove(report: Report){
    
  }

  onGetData(){
    this.loading = true;
    if (this.entity) {
      this.loadEntityReport();
    }
  }
  
  loadEntityReport(){
    this.loading = true;
    this.unsubscribeData();
    this.subscription = this.reportService.getReports(this.entity.idEntity.toString())
      .subscribe( data => {
        this.reports = data;
        this.loading = false;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
        this.subscription.unsubscribe();
    }
  }

}
