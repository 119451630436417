<div class="col-sm-12">
  <div class="col-sm-12 wrimagecard wrimagecard-topimage">
    <a>
      <div class="col-sm-4 wrimagecard-topimage_header yellow">
        <p class='text-center'><i class="icon fa fa-organization yellow-icon"></i></p>
      </div>
      <div class="col-sm-8 wrimagecard-topimage_title">
        <h4>ID: {{organization.idOrganization}}</h4>
        <h4>Name: {{organization.name}}</h4>
        <h4>Description: {{organization.description}}</h4>
      </div>
    </a>
  </div>
</div>
