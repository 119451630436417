import { Component,
         OnInit }                                   from '@angular/core';
import { Router, ActivatedRoute }           from '@angular/router';
import { Location }                                 from '@angular/common';
import { MatDialog} from '@angular/material/dialog';

import { Language }          from './../language';
import { AuthService } from '../../../auth.service';


@Component({
  selector: 'language-edit',
  templateUrl: './language-edit.component.html',
  styleUrls: ['./language-edit.component.css']
})
export class LanguageEditComponent implements OnInit {

  errorMessage: string;
  language: Language;
  languages:Array<Object> = [
    {value: 'admin', name: 'Admin'},
    {value: 'test', name: 'Test'}
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.getRole().then((role) => {
      if ( role != 1) {
        this.router.navigate(['/']);
      }
    });
    this.route.data
      .subscribe((data: { language: Language }) => {
        if(data.language) {
          this.language = data.language;
        } else {
          this.language = new Language(null, '');
        }
      });
  }

  onCancel(): void {
    this._location.back();
  }

  onSave(language: Language): void {
    // this.languageService.saveRole(role)
    //                     .subscribe(
    //                       response => {
    //                         this._location.back();
    //                       },
    //                       error =>  this.errorMessage = <any>error);
  }

  onDelete(): void {
    // this.languageService.removeRole(this.role.idRole)
    //                     .subscribe(
    //                       response => {
    //                         this._location.back();
    //                       },
    //                       error =>  this.errorMessage = <any>error);
  }



}
