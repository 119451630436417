import { Component,
         OnInit,
         Output }                                   from '@angular/core';
import { Router, ActivatedRoute }           from '@angular/router';
import { Location }                                 from '@angular/common';

import { AlertService } from './../../../alert.service';
import { App }          from './../app';
import { AppService }   from './../app.service';

import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-edit',
  templateUrl: './app-edit.component.html',
  styleUrls: ['./app-edit.component.css']
})
export class AppEditComponent implements OnInit {

  errorMessage: string;
  app: App;
  isAdmin: boolean = false;
  @Output() editMode: boolean = false;
  module: string = 'App';
  cardHeaderState: string = 'edit';
  alias: string;
  url: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private _location: Location,
    private alertService: AlertService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    let account = this.authService.getAccount().subscribe((account)=>{
      if (account.idRole == 1){
        this.isAdmin = true
      }
      else {
        this.router.navigate(['/']);
      }
      this.route.data
        .subscribe((data: { app: App}) => {
          if(data.app) {
            this.app = data.app[0];
            this.url = data.app[0]['defaultUrl'];
          } else {
            this.app = new App(null, null, null, null, null, null, null);
            this.editMode = true;
            this.cardHeaderState = 'new';
          }
        });
    });
  }

  onheaderActionEmitter(message:any):void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        // this.onNew();
        break;
      case 'save':
        this.app["alias"]= this.alias;
        this.app["url"]= this.url;
        this.app["id"]= this.url.includes('custom') ? this.url.substring('custom/'.length) : this.url;
        this.onSave(this.app);
        // this.onSelect(message.data);
        this.editMode = !this.editMode;
        break;
      case 'cancel':
        // this.onEdit(message.data);
        this.editMode = !this.editMode;
        break;
      case 'remove':
        this.onDelete();
        // this.onRemove(message.data);
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  onCancel() {
    this._location.back();
  }

  onFileChanged(event){
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      if(file.size < 50000) {
      let fr = new FileReader();
        fr.onload = (event:any)=>{
          let base64 = event.target.result
          this.app.image = base64;
        }
        fr.readAsDataURL(file)
      } else {
        this.alertService.emitErrorMessage({text: 'File too big', type: 'error'});
      }
    }
  }

  onSave(app: App) {
    if (app.name) {
      this.appService.saveApp(app)
                          .subscribe(
                            response => {
                              this._location.back();
                            },
                            error =>  {
                              this.editMode = !this.editMode;
                              this.errorMessage = <any>error
                            });
    }
    else
    {
        this.alertService.emitErrorMessage({text: 'Name is required. Please fill out the field and resubmit the form', type: 'danger'});
        this.editMode = !this.editMode;
    }

  }

  onDelete(): void {
    this.appService.removeApp(this.app.idApp)
                   .subscribe(
                     response => {
                       this._location.back();
                     },
                     error =>  this.errorMessage = <any>error);
  }

}
