<div class="mdl-layout__container">
  <div class="mdl-layout mdl-js-layout">
    <h2>Welcome back to Vixion</h2>
    <mat-card>
      <mat-card-content>
        <mat-form-field>
          <input matInput id="inputEmail" [(ngModel)]="inputEmail" placeholder="Email" (keyup.enter)=login()>
        </mat-form-field>

        <mat-form-field>
          <input matInput id="inputPassword" [(ngModel)]="inputPassword" placeholder="Enter your password" type="password" (keyup.enter)=login()>
        </mat-form-field>

        <a class='pink-text' routerLink="/" routerLinkActive="active"><b>Forgot Password?</b></a>

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="login()">Login</button>
      </mat-card-actions>
    </mat-card>
    <!-- <button mat-button>Create Account</button> -->
  </div>
</div>
