import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Account } from './../account';
import { AccountService } from './../account.service';
import { App } from '../../apps/app';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css'],
  providers: [ AccountService ]
})

export class AccountListComponent implements OnInit {

  accounts: Account[];
  module: string = 'Account';
  fields: Array<any>= [
    { id: 'organization.name', display: 'organization', header: 'Organization', type: 'image', sortable: true, filterable: true },
    { id: 'username', display: 'username', header: 'Email', sortable: true, filterable: true },
    { id: 'fullname', display: 'fullname', header: 'Full name', sortable: true, filterable: true },
    { id: 'role.name', display: 'role.name', header: 'Role', type: 'chip', sortable: true, filterable: true },
  ];
  menuTitle: string = 'Accounts';
  insideEntity: boolean = false;
  canCreate: boolean = true;
  query: any = {pageSize: 10, pageIndex: 0, sort: 'organization.name', direction: 'asc', filter: null};

  errorMessage: string;
  app: App;

  private subscription: Subscription = new Subscription();

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.isEndUser().then((result) => {
      if ( result ) { this.canCreate = false }
    })
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    if(this.route.params['_value'].id) {
      this.router.navigate([`/apps/account/new/${this.route.params['_value'].id}`]);
    } else {
      this.router.navigate(['/accounts/new']);
    }
  }

  onSelect(account: Account){
    if(this.route.params['_value'].id) {
      this.router.navigate([`/apps/account/${account.idAccount}/edit/${this.route.params['_value'].id}` ]
      );
    } else {
      this.router.navigate(['/accounts/edit',account.idAccount ]);
    }
  }

  onEdit(account: Account){
    this.router.navigate(['/accounts/edit',account.idAccount ]);
  }

  onRemove(account: Account){
    this.accountService.removeAccount(account.idAccount)
        .subscribe(
          error => this.errorMessage = <any>error);
  }

  onGetData(){
    this.unsubscribeData();
    this.subscription = this.accountService.getAccounts(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        this.accounts = data;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

}
