import { Shift } from '../../shifts/shift';
import { ShiftService } from '../../shifts/shift.service';
import { map } from 'rxjs/operators';
import {
    Component,
    OnInit,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { CalendarService } from '../calendar.service';
import { AlertService } from '../../../alert.service';
import { EventEmitter } from '@angular/core';
import { Entity } from '../../entities/entity';
import * as _ from 'lodash';
import { ShiftsUtils } from '../../../utils/shifts';
import { Organization } from '../../organizations/organization';

@Component({
    selector: 'calendar-generator',
    templateUrl: './calendar-generator.component.html',
    styleUrls: ['./calendar-generator.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CalendarGeneratorComponent implements OnInit {

    @Input() entity: Entity;
    @Input() organization: Organization;
    @Input() shifts: any[];
    @Output() reload: EventEmitter<any> = new EventEmitter<any>();

    public submitting = false;
    public errorMessage: string;
    // public shifts: any[] = [];
    public holidays: any[] = [];
    public showHolidays: boolean;
    public days = [
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' },
        { label: 'Sunday', value: 'Sunday' },
    ];

    constructor(
        private shiftsService: ShiftService,
        private calendarService: CalendarService,
        private alertService: AlertService,
        private shiftsUtils: ShiftsUtils
    ) { }

    async ngOnInit() {
        this.initShifts();
    }

    private initShifts() {
        for (let i = 0; i < this.shifts.length; i++) {
            const shift = this.shifts[i];
            shift['weekDay'] = shift['weekDay'] !== undefined ? shift['weekDay'] : [false, false, false, false, false, false, false];
            if (shift['historicalChanges'] && shift['historicalChanges'] && shift['historicalChanges'].length > 0) {
                const changes = shift['historicalChanges'].map(change => change.dataValues);
                const changesOrder = _.orderBy(changes, ['date'], ['desc'])
                shift.startDayBefore = changesOrder[0].startDayBefore;
            }
            if (shift['startDayBeforeEntity'] === null || shift['startDayBeforeEntity'] === undefined) {
                shift['startDayBeforeEntity'] = shift.startDayBefore;
            }
        }
    }

    public getShiftTitle(shift: Shift): string {
        const start = shift.start.split(':').slice(0, -1).join(':'); // remove seconds
        const end = shift.end.split(':').slice(0, -1).join(':'); // remove seconds
        return `${shift.name}: ${start}-${end}`;
    }

    public async selectRow(shift) {
        const result = shift.weekDay.includes(false);
        if (result) {
            const index = _.findIndex(this.shifts, ['idShift', shift.idShift]);
            const clonesShifts = _.cloneDeep(this.shifts);
            clonesShifts[index].weekDay = clonesShifts[index].weekDay.map(() => result);
            for (let j = 0; j < clonesShifts.length; j++) {
                const cloneShift = clonesShifts[j];
                cloneShift.worked = cloneShift.weekDay.includes(true);
            }
            const resultOverlap = await this.shiftsUtils.checkOverlapShifts(clonesShifts);
            if (resultOverlap) {
                this.alertService.emitErrorMessage({ text: 'One or more of the shifts are overlapping and will not be activated.', type: 'danger' });
            } else {
                this.shifts[index].weekDay = this.shifts[index].weekDay.map(() => result);
            }
        } else {
            shift.weekDay = shift.weekDay.map(() => result);
        }
    }

    public async selectCol(x) {
        let result: boolean = false;
        for (let i = 0; i < this.shifts.length; i++) {
            const shift = this.shifts[i];
            if (shift.weekDay[x] === false) {
                result = true;
                break;
            };
        }
        if (result) {
            let clonesShifts = _.cloneDeep(this.shifts);
            clonesShifts.map((shift) => shift.weekDay[x]);
            clonesShifts.forEach(shift => {
                shift.weekDay[x] = result;
                shift.worked = result;
            });
            const resultOverlap = await this.shiftsUtils.checkOverlapShifts(clonesShifts);
            if (resultOverlap) {
                this.alertService.emitErrorMessage({ text: 'One or more of the shifts are overlapping and will not be activated.', type: 'danger' });
            } else {
                this.shifts.forEach(shift => shift.weekDay[x] = result);
            }
        } else {
            this.shifts.forEach(shift => shift.weekDay[x] = result);
        }
    }

    public async selectDayWorkShift(event, s, d) {
        event.source.checked = event.checked;
        this.shifts[s].weekDay[d] = !this.shifts[s].weekDay[d];
        const clonesShifts = _.cloneDeep(this.shifts);
        for (let i = 0; i < clonesShifts.length; i++) {
            const shift = clonesShifts[i];
            shift.worked = shift.weekDay[d];
        }
        const resultOverlap = await this.shiftsUtils.checkOverlapShifts(clonesShifts);
        if (resultOverlap) {
            event.source.checked = !event.checked;
            this.shifts[s].weekDay[d] = !this.shifts[s].weekDay[d];
            this.alertService.emitErrorMessage({ text: 'This shift overlaps with other turns and cannot be activated.', type: 'danger' });
        } else {
            this.shifts = _.cloneDeep(clonesShifts);
        }
    }

    public submitCalendar(): void {
        this.submitting = true;
        const entityCalendar = [];
        let allDisabled = true;
        this.shifts.forEach(elem => {
            entityCalendar.push({idShift: elem.idShift, week: elem.weekDay, startDayBefore: elem.startDayBeforeEntity});
            const result = elem.weekDay.includes(true);
            if (result) allDisabled = false;
        });
        if (!allDisabled) {
            const workedCalendarData = { shifts: entityCalendar }
            if (this.entity) workedCalendarData['idEntity'] = this.entity.idEntity
            if (this.organization) workedCalendarData['idOrganization'] = this.organization.idOrganization
            this.calendarService.newWorkedCalendar(workedCalendarData).subscribe(
                response => { 
                    this.submitting = false;
                    this.alertService.emitErrorMessage({ text: 'Saved!', type: 'info' });
                    this.reload.emit();
                },
                error => {
                    this.submitting = false;
                    this.alertService.emitErrorMessage({ text: error.error, type: 'danger' });
                    this.errorMessage = <any>error;
            });
        } else {
            this.alertService.emitErrorMessage({ text: 'You haven´t selected any calendar shift ', type: 'danger' });
            this.submitting = false;
        }
    }

    public checkShiftCanBeSetStartDayBefore(shift) {
        return this.shiftsUtils.checkShiftCanBeSetStartDayBefore(shift);
    }

}
