import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs';

import { Organization }          from './../organization';
import { OrganizationService }   from './../organization.service';


@Injectable()
export class OrganizationListResolver implements Resolve<Organization[]> {
  constructor(private organizationService: OrganizationService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organization[]> {
    return this.organizationService.getOrganizations(null, 0, 'name', 'asc', '')
  }
}
