<mat-card>
  <!-- <mat-card-header>
    <button mat-icon-button (click)="onDelete(account)" [disabled]="isDisabled">
      <mat-icon aria-label="Delete">delete</mat-icon>
    </button>
  </mat-card-header> -->
  <cardHeader-edit (headerActionEmitter)='onheaderActionEmitter($event)' [accountAppNew]='accountAppNew' [module]='module' [editMode]='editMode' [cardHeaderState]='cardHeaderState'>
  </cardHeader-edit>
  <mat-card-content>
    <mat-form-field *ngIf="isDisabled">
      <input type="text" placeholder="Pick one" aria-label="Number" required matInput [formControl]="myControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let account of filteredAccounts | async" [value]="account.username">
          {{ account.username }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field *ngIf="!isDisabled">
      <input type="text" matInput [value]="account.username" disabled>
    </mat-form-field>
  </mat-card-content>

  <!-- <mat-card-actions>
    <button mat-icon-button (click)="onCancel()">
      <mat-icon aria-label="Cancel">clear</mat-icon>
    </button>
    <button mat-icon-button (click)="onSave()" [disabled]="!isDisabled">
      <mat-icon aria-label="Accept">done</mat-icon>
    </button>
  </mat-card-actions> -->
</mat-card>
