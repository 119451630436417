import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../auth.service';
import { AppService } from '../apps/app.service';

import { QueryStore } from '../shared/store/query.store';
import { TabStore } from '../shared/store/tab.store';

import { environment } from '../../../environments/environment';

import * as _ from 'lodash';
import { OrganizationService } from '../organizations/organization.service';
import { Account } from '../accounts/account';
import { ConfigTypesService } from '../../services/configTypes.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  account : any;
  url: string = "";
  leftColor = environment.header_color;

  navList: any[] = [
    {
      heading: 'Roles',
      link: '/roles',
      icon: 'pe pe-7s-id pe-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
    },
    {
      heading: 'Organizations',
      link: '/organizations',
      icon: 'vi vi-factory vi-3x',
      minimumRoleWeight: 4,
      exclusiveUse: false,
    },
    {
      heading: 'Accounts',
      link: '/accounts',
      icon: 'vi vi-users vi-3x',
      minimumRoleWeight: 4,
      exclusiveUse: false,
    },
    {
      heading: 'Machines',
      link: '/entities',
      icon: 'vi vi-machine vi-3x',
      minimumRoleWeight: 4,
      exclusiveUse: false,
    },
    {
      heading: 'Fleet',
      link: '/fleet',
      icon: 'vi vi-fleet vi-3x',
      minimumRoleWeight: 4,
      exclusiveUse: true,
    },
    {
      heading: 'Machine Availability',
      link: '/machine-availability',
      icon: 'vi vi-timeline vi-3x',
      minimumRoleWeight: 2,
      exclusiveUse: true,
    },
    {
      heading: 'Machine models',
      link: '/entityModels',
      icon: 'vi vi-machine-catalog vi-3x',
      minimumRoleWeight: 2,
      exclusiveUse: false,
    },
    {
      heading: 'Vixion Edge',
      link: '/dataloggerHardwares',
      icon: 'vi vi-vixion-edge vi-3x',
      minimumRoleWeight: 4,
      exclusiveUse: false,
    }
  ]
  
  extraNavList = [
    {
      heading: 'Apps',
      icon: 'vi vi-vixion vi-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: [
        {
          title: 'Apps',
          link: '/apps',
          icon: 'vi vi-vixion vi-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Views',
          link: '/views',
          icon: 'pe pe-7s-photo-gallery pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Widgets',
          link: '/widgets',
          icon: 'pe pe-7s-graph pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'WidgetGroups',
          link: '/widgetGroups',
          icon: 'pe pe-7s-graph pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
      ]
    },
    {
      heading: 'Addons',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: [
        {
          title: 'Master Addons',
          link: '/masterAddons',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Addon models',
          link: '/addonModels',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Addons',
          link: '/addons',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        }
      ]
    },
    {
      heading: 'Datalogger',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: [
        {
          title: 'Datalogger models',
          link: '/dataloggerHardwareModels',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Datalogger softwares',
          link: '/dataloggerSoftwares',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        }
      ]
    },
    {
      heading: 'Datasources',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: [
        {
          title: 'Datasources',
          link: '/datasources',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Datasource models',
          link: '/datasourceModels',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        }
      ]
    },
    {
      heading: 'Manage EULAs',
      link: '/eulas',
      matIcon: 'gavel',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: []
    },
    {
      heading: 'Licenses',
      link: '/licenses',
      icon: 'pe pe-7s-note pe-3x',
      minimumRoleWeight: 2,
      exclusiveUse: false,
      pages: []
    },
    {
      heading: 'Notifications',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: [
        {
          title: 'Notification rules',
          link: '/notificationRules',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
      ]
    },
    {
      heading: 'Settings',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: [
        {
          title: 'Master settings',
          link: '/masterSettings',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Settings',
          link: '/settings',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        }
      ]
    },
    {
      heading: 'Signal types',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: [
        {
          title: 'Management',
          link: '/masterVariables',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
      ]
    },
    {
      heading: 'Variables / Signals',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      exclusiveUse: false,
      pages: [
        {
          title: 'Aggregated signals',
          link: '/aggregatedSignals',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 2,
          exclusiveUse: false,
        },
        {
          title: 'Signal types',
          link: '/masterVariables',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 2,
          exclusiveUse: false,
        },
        {
          title: 'Management',
          link: '/masterVariables/management',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 2,
          exclusiveUse: false,
        },
        {
          title: 'Map variables',
          link: '/mapVariables',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'PMCSelectors',
          link: '/PMCSelectors',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Variable types',
          link: '/variableTypes',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1,
          exclusiveUse: false,
        },
        {
          title: 'Signals',
          link: '/bbjVariables',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 2,
          exclusiveUse: false,
        },
      ]
    },
    {
      heading: 'API Documentation',
      link: '/swagger',
      icon: 'pe pe-7s-notebook pe-3x',
      minimumRoleWeight: 10,
      exclusiveUse: false,
      pages: []
    },
  ];

  private organizationExlusiveAccessConfigs: any;
  private idOrganizationExlusiveAccess: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private queryStore: QueryStore,
    private tabStore: TabStore,
    private configTypeService: ConfigTypesService
  ) {}

  ngOnInit() {
    this.authService.getAccount().subscribe((account: Account): void => {
      this.account = account;
      this.getConfigTypesAccessExclusiveUse();
    });
  }
  
  ngAfterContentChecked(){
    this.checkSelected();
  }
  
  private async getConfigTypesAccessExclusiveUse() {
    this.configTypeService.getConfigTypes(this.account.idOrganization, 'accessExlusiveConfigsManager').subscribe(res => {
      this.idOrganizationExlusiveAccess = res.idOrganization;
      this.organizationExlusiveAccessConfigs = res.value;
      this.checkExclusiveConfigs();
    })
  }

  checkExclusiveConfigs() {
    for (let i = 0; i < this.navList.length; i++) {
      const navItem = this.navList[i];
      if(this.account && this.account.role.weight === 1) {
        navItem.exclusiveUse = false;
      } else if (this.organizationExlusiveAccessConfigs && !_.isEmpty(this.organizationExlusiveAccessConfigs)) {
        const itemConfigAccess = _.find(this.organizationExlusiveAccessConfigs, config => {
          return navItem.link === `/${config.link}`
        })
        if (!itemConfigAccess) continue;
        if (itemConfigAccess.hierarchy) {
          navItem.exclusiveUse = false;
        } else {
          navItem.exclusiveUse = this.idOrganizationExlusiveAccess === this.account.idOrganization ? false : true;
        }
      }
    }
  }

  checkSelected(): void {
    if (this.router.url != this.url) {
      this.url = this.router.url;
    }
  }

  onSidebarClick() {
    this.queryStore.deleteAllQueries();
    this.tabStore.deleteAllTabs();
  }

  hasChildActive(navItem) {
    let active = false;
    active = navItem.pages.some((page)=>{
      return (this.router.url.startsWith(page.link));
    });
    return active;
  }

  isActive(navItem) {
    let isMyProfile = this.router.url.startsWith("/accounts/profile/");
    return this.router.url.startsWith(navItem.link) && !isMyProfile;
  }

}
