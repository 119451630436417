import { Component, OnInit }        from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

import { AuthService } from './../auth.service';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.css']
})

export class LoginUserComponent implements OnInit{
  message: string;
  name: string;
  inputEmail: string;
  inputPassword: string;

  constructor(
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute) {
    this.setMessage();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['id'] != null && params['id'] != '') {
        this.inputEmail = params['id'];
      } else {
        this.router.navigate(['/login']);
      }
    })
  }

  setMessage() {
    this.message = 'Logged ' + (this.authService.isLoggedIn ? 'in' : 'out');
  }

  async login() {
    this.message = 'Trying to log in ...';


    await this.authService.login(this.inputEmail, this.inputPassword)
        .subscribe(() => {
          this.setMessage();
          if (this.authService.isLoggedIn) {
            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
            
            // Set our navigation extras object
            // that passes on our global query params and fragment
            let navigationExtras: NavigationExtras = {
              queryParamsHandling: 'preserve',
              preserveFragment: true
            };
            
            // Redirect the account
            this.router.navigate([redirect], navigationExtras);
          }
        });
  }
  
  logout() {
    this.authService.logout();
    this.setMessage();
  }
}
