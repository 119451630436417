import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';

import { Datasource } from './../datasource';
import { Entity } from './../../entities/entity';
import { DatasourceService } from './../datasource.service';
import { MainService} from './../../main.service';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'datasource-list',
  templateUrl: './datasource-list.component.html',
  styleUrls: ['./datasource-list.component.css'],
  providers: [ DatasourceService ]
})

export class DatasourceListComponent implements OnInit {

  datasources: Datasource[];
  module: string = 'Datasource';
  table_headers: Array<string>= ['Model', 'Address', 'Alarm'];
  fields: Array<any>= [
    { id: 'datasourceModel.name', display: 'datasourceModel.name', header: 'Model', sortable: true, filterable: true },
    { id: 'address', display: 'address', header: 'Address', sortable: true, filterable: true },
    { id: 'datasourceStatus', display: 'datasourceStatus', header: 'Status', type: 'datasourceStatus', sortable: false, filterable: false },
    // { id: 'dataloggerSoftware.dataloggerHardware.serialNumber', display: 'dataloggerSoftware.dataloggerHardware.serialNumber', header: 'Serial Number', sortable: true, filterable: true }
  ]
  menuTitle: string = 'Datasources';
  insideEntity: boolean = false;
  loading: boolean = true;

  query: any = {pageSize: 10, pageIndex: 0, sort: 'datasourceModel.name', direction: 'asc', filter: null};

  entity: Entity;
  state:any;
  errorMessage: string;
  subscriptions: Array<any> = [];
  canCreate: boolean = true;
  @Output() datasourceStatusEmitter: EventEmitter<any> = new EventEmitter<any>();
  datasourceStatus: Array<any> = [];

  mainDatasourceInDatasources:boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private datasourceService: DatasourceService,
    private route: ActivatedRoute,
    private mainService: MainService,
    private router: Router,
    private authService: AuthService
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        first()
      )
      .subscribe((event) => {
        this.destroySubscriptions();
      });
  }

  ngOnInit() {
    this.route.data
    //.subscribe((data: { datasources: Datasource[], entity: Entity }) => {
    .subscribe((data: { entity: Entity }) => {
      ////if(data.datasources) {
      //this.datasources = data.datasources;
      ////if the component is inside entityEdit component
      if(data.entity) {
        this.entity = data.entity;
        this.state = {idDataloggerSoftware: data.entity.idDataloggerSoftware};
        this.insideEntity = true;
      }
    });

    this.authService.isEndUser().then((result) => {
      if ( result ) { this.canCreate = false; }
    })
  }

  destroySubscriptions() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
      case 'refresh':
        this.setInitialStatus();
        break;
    }
  }

  onNew(){
    this.mainService.setState(this.state);
      this.router.navigate(['/datasources/new']);
  }

  onSelect(datasource: Datasource){
    this.router.navigate(['/datasources/edit',datasource.idDatasource ]);
  }

  onEdit(datasource: Datasource){
    this.router.navigate(['/datasources/edit',datasource.idDatasource ]);
  }

  onRemove(datasource: Datasource){
    this.datasourceService.removeDatasource(datasource.idDatasource)
        .subscribe(
          error => this.errorMessage = <any>error);
  }

  onGetData(){
    this.loading = true;
    this.unsubscribeData();
    if(this.entity) {
      this.subscription = this.datasourceService.getEntityDatasources(this.route.snapshot.params.id)
      .subscribe(async data => {
        this.loading = false;
        this.datasources = await this.changeNameDatasourceMain(data);
        this.setInitialStatus();
      })
    } else {
      this.subscription = this.datasourceService.getDatasources(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
        .subscribe(async data => {
          this.loading = false;
          this.datasources = await this.changeNameDatasourceMain(data);
          this.setInitialStatus();
        })
    }
  }

  private changeNameDatasourceMain(data) {
    for (let i = 0; i < data['rows'].length; i++) {
      const datasource = data['rows'][i];
      if (datasource.bbjVariables && datasource.bbjVariables.length > 0) {
        this.mainDatasourceInDatasources = true;
      }
      datasource.datasourceModel.name = datasource.bbjVariables && datasource.bbjVariables.length > 0 ? '* ' + datasource.datasourceModel.name : datasource.datasourceModel.name;
    }
    return data;
  }

  setInitialStatus() {
    this.datasources['rows'].map( (dataloggerHardware) => {
      dataloggerHardware["datasourceStatus"] = {
        "status": -1,
        "description": "",
        "info": "Loading..."
      };
    })
    //this.dataloggerHardwares = dataloggerHardwares;
    this.datasourceStatus = [];
    this.getStatus();
  }

  getStatus (){
    Promise.all(this.datasources['rows'].map((datasource : Datasource) => {
        return new Promise ((resolve, reject) =>{
        this.subscriptions.push(this.datasourceService.getDatasourceStatus(datasource.idDatasource).subscribe(
          res => {
            datasource["datasourceStatus"] = res;
            this.datasourceStatus.push({datasource: datasource, status: res});
            resolve();
          }
        ));
      })
    })).then(()=>{
      this.datasourceStatusEmitter.emit(this.datasourceStatus);
    })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

}
