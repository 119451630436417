import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-help-calendar',
  templateUrl: './help-calendar.component.html',
  styleUrls: ['./help-calendar.component.css']
})
export class HelpCalendarComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<HelpCalendarComponent>,
     @Inject(MAT_DIALOG_DATA) 
      public data: any
  ) { }

  ngOnInit() {
  }

}
