<button mat-icon-button [matMenuTriggerFor]="vixion_apps">
  <img src="/./assets/images/logo_plain.svg" class="size-28">
</button>
<button mat-icon-button [matMenuTriggerFor]="notifications">
   <mat-icon class="size-28">notifications</mat-icon>
</button>
<button mat-icon-button [matMenuTriggerFor]="menu">
  <span class="vi vi-user-filled vi-2x"></span>
</button>
<mat-menu #vixion_apps="matMenu" [overlapTrigger]="false" class="apps-tab">
  <!-- hello -->
  <mat-grid-list [cols]="cols" rowHeight="100px" style="width:420px; text-align:center">
    <ng-container *ngFor="let app of apps">
      <mat-grid-tile colspan=1 rowspan=1 *ngIf="app.displayOnMenu">
        <div>
          <button class="app-btn" (click)="onAppClick(app)">
            <img style="text-align:center" src="{{app.icon}}" alt="{{app.name}}" class="size-50">
            <br><br>
            <span style="text-align:center">{{app.name}}</span>
          </button>
          <!-- <p >{{app.title}}</p> -->
        </div>
        <!-- <a
           routerLink="#"  class="apps-tile-link">
          <span class="{{app.icon}}" style="font-size: 3em !important;"></span><span> <h6> {{app.name}}</h6></span>
        </a> -->
      </mat-grid-tile>
      <!-- <mat-grid-tile [colspan]="cols" rowspan=1 style="background-color:linghgrey;">
        <button mat-raised-button routerLink="/apps">Apps section</button>
      </mat-grid-tile> -->
    </ng-container>
  </mat-grid-list>
</mat-menu>
<mat-menu #notifications="matMenu" [overlapTrigger]="false">
</mat-menu>
<mat-menu #menu="matMenu" [overlapTrigger]="false" class="account-tab">
  <mat-grid-list cols="4" rowHeight="45px" style="width:320px;">
    <mat-grid-tile colspan=1 rowspan=2>
      <img  *ngIf="account.organization.image" [src]=account.organization.image class="img-responsive"style="height:70px;width:70px;border-radius: 50%;border: 1px solid lightgrey; margin-left: 5px
">
    </mat-grid-tile>
    <mat-grid-tile colspan=3 rowspan=1 class="in-block">
      <div>
        <span class="tittle-size">
          <strong>{{account.fullname}}</strong>
        </span>

        <br>
        <span class="email-size">
             {{account.username}}
        </span>

      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan=3 rowspan=1 class="in-block">
      <span class="bs-label">{{account.role.name}}</span>
    </mat-grid-tile>

    <mat-grid-tile colspan=2 rowspan=1 style="background-color:linghgrey;">
      <button mat-raised-button routerLink="/accounts/profile/{{account.idAccount}}" routerLinkActive="active"
      (click)="resetStateStore($event)">My account</button>
    </mat-grid-tile>
    <mat-grid-tile colspan=2 rowspan=1 style="background-color:linghgrey;">
      <button mat-raised-button (click)="resetStateStore($event);logOut()">Sign out</button>
    </mat-grid-tile>
  </mat-grid-list>
</mat-menu>
