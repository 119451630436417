import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigTypesService {

  public constructor(private http: HttpClient) { }
   
   /**
    * Retrieves configTypes
   *
   * @param idOrganization
   * @param idConfigType
   */
  public getConfigTypes(idOrganization: number, idConfigType: string): Observable<any> { 
    return this.http.get<any>(environment.api_url + '/config/organization/' + idOrganization + '/type/' + idConfigType);
  };

}
