import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class CalendarUtils {
    // create events using workShifts weekDay and holidays
    public async generateEvents(viewDate, workShiftsGroup: any[], idOrganization, idEntity) {
      let events = [];
      let editEvent = false;
      const monthDays = this.getDaysInMonth(viewDate.getMonth()+1, viewDate.getFullYear());
      monthDays.forEach((day) => {
        const dateFreqFormat = moment(day).format('YYYY-MM-DD');
        
        let workedCalendarsActive = [];
        for (let i = 0; i < workShiftsGroup.length; i++) {
          const workShift = workShiftsGroup[i];
          for (let j = 0; j < workShift.rows.length; j++) {
              const workedCalendars = workShift.rows[j];
              let calendarActive = false;
              workedCalendars.historicalChanges.sort((a,b) => moment(a.date, 'YYYY-MM-DD').valueOf() - moment( b.date, 'YYYY-MM-DD').valueOf());
              for (let k = 0; k < workedCalendars.historicalChanges.length; k++) {
                  const historical = workedCalendars.historicalChanges[k];
                  const isSameOrBefore = moment(historical.date, 'YYYY-MM-DD').isSameOrBefore(dateFreqFormat);
                  if (isSameOrBefore) {
                    calendarActive = true;
                    workedCalendars.weekDay = historical.weekDay;
                    workedCalendars.startDayBefore = historical.startDayBefore;
                  }
              }
  
              if (calendarActive) workedCalendarsActive.push(workedCalendars);
  
          }
  
          if (workedCalendarsActive.length === 0 && workShift.rows[0].historicalChanges.length === 0) {
              workedCalendarsActive = workedCalendarsActive.concat(workShift.rows);
          }
  
          if (workedCalendarsActive.length > 0) {
              break;
          }
        }

        for (let i = 0; i < workedCalendarsActive.length; i++) {
          const workShiftActive = workedCalendarsActive[i];
          const date = dateFreqFormat;
          const shift = workShiftActive.shift;

          const dayMoment = moment(date, 'YYYY-MM-DD');
          const dateStart = moment(date + ' ' + shift.start, 'YYYY-MM-DD HH:mm:ss');
          let start = new Date(dateStart.toDate());
          
          const dateEnd = moment(date + ' ' + shift.end, 'YYYY-MM-DD HH:mm:ss');
          let end = new Date(dateEnd.toDate());

          const weekDay = dayMoment.day() === 0 ? 6 : dayMoment.day() -1;
          
          const weekDayFormat = (workShiftActive.weekDay).split("").map(d => d === "1" ? true : false);
          const isWorkedDay = weekDayFormat ? weekDayFormat[weekDay] : true;
          if (!editEvent) {
            if (idOrganization) {
              if (workShiftActive) editEvent = workShiftActive.idOrganization === idOrganization;
              if (workShiftActive.specialDay && workShiftActive.specialDay.length > 0) editEvent = workShiftActive.specialDay.idOrganization === idOrganization;
            } else {
              if (workShiftActive) editEvent = workShiftActive.idEntity === idEntity;
              if (workShiftActive.specialDay && workShiftActive.specialDay.length > 0) editEvent = workShiftActive.specialDay.idEntity === idEntity;
            }
          }

          if (isWorkedDay) {
            let worked: boolean = false;
            if (workShiftActive) {
              if (workShiftActive.workedCalendarFreeShift) {
                const index = _.findIndex(workShiftActive.workedCalendarFreeShift, ['date', date]);
                worked = index > -1 ? false : true
              }           
            }
            events.push({
              start,
              end,
              title: shift.name,
              color: {
                primary: shift.color,
                secondary: shift.color,
              },
              worked: worked,
              date: moment(date, 'YYYYMMDD').format('YYYY-MM-DD'),
              idShift: shift.idShift,
              editEvent: editEvent,
              meta: workShiftActive,
              espacialDay: false
            });
          } else {
            if (workShiftActive.specialDay) {
              const index = _.findIndex(workShiftActive.specialDay, ['date', date]);
              if (index > -1) {
                events.push({
                  start,
                  end,
                  title: shift.name,
                  color: {
                    primary: shift.color,
                    secondary: shift.color,
                  },
                  worked: true,
                  date: moment(date, 'YYYYMMDD').format('YYYY-MM-DD'),
                  idShift: shift.idShift,
                  editEvent: editEvent,
                  meta: workShiftActive,
                  espacialDay: true
                });

              }
            }
          }
        };
      })
      events.sort((a, b) => a.meta.timestampStart - b.meta.timestampStart);
      return events;
    }

    // get array of dates of the selected month-year
    private getDaysInMonth (month, year): Date[] {
      return (new Array(31)).fill('').map((v,i)=>new Date(year,month-1,i+1)).filter(v=>v.getMonth()===month-1)
    }
}