import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Organization }      from './organization';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class OrganizationService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all organizations
   */
  public getOrganizations(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Organization[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Organization[]>(environment.api_url + '/organizations', { params: queryParams });
  };
  /**
   * Retrieves organization details by a given id
   *
   * @param idOrganization
   */
  public getOrganization(idOrganization: string): Observable<Organization> { 
    return this.http.get<Organization>(environment.api_url + '/organization/' + idOrganization);
  };

  /**

   * Retrieves entity details by a given a master variable
   *
   * @param id
   */
  public getMasterSetttingOrganizations (id: string): Observable<Organization[]> { 
    return this.http.get<Organization[]>(environment.api_url + '/organizations/masterSetting/' + id);
  };

  /**
   * Retrieves organization config by a given id
   *
   * @param idOrganization
   */
  public getOrganizationConfig(idOrganization: string): Observable<Organization> { 
    return this.http.get<Organization>(environment.api_url + '/organization/config/' + idOrganization);
  };


  /**
   * Save organization
   *
   * @param organization
   */
  public saveOrganization(organization: Organization): Observable<any> {
    if (organization.idOrganization) {
      return this.updateOrganization(organization);
    } else {
      return this.newOrganization(organization);
    }
    //return null;
  };

  /**
   * Create new organization
   *
   * @param organization
   */
  public newOrganization(organization: Organization): Observable<any> { 
    return this.http.post(environment.api_url + '/organization', organization);
  };

  /**
   * Update organization
   *
   * @param organization
   */
  public updateOrganization(organization: Organization): Observable<any> { 
    return this.http.put(environment.api_url + '/organization', organization);
  };

  /**
   * Delete an organization by a given id
   *
   * @param idOrganization
   */
  public removeOrganization (idOrganization: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/organization/' + idOrganization);
  }

  /**
   * Retrieves organizations without endusers role
   */
  public getOrganizationsWithoutEndUsers(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Organization[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Organization[]>(environment.api_url + '/organizationsWithoutEndUsers', { params: queryParams });
  };

}
