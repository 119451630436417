<div class="table-container mat-elevation-z8">

    <mat-card-header [ngClass]="{'white-label': !insideEntity}">
        <mat-card-title [hidden]="insideEntity">
            {{this.menuTitle}}
        </mat-card-title>
        <mat-card-actions>
            <button *ngIf="canCreate && csvButton" class="onleft-margin" mat-icon-button (click)="onCsv()" matTooltip="CSV bulk import/export" matTooltipPosition="left">
            <mat-icon aria-label="Add">insert_drive_file</mat-icon>
        </button>
            <button *ngIf="canCreate && this.module == 'Signal'" title="Refresh real time" class="onleft-margin" mat-icon-button (click)="onRefresh($event)">
              <mat-icon aria-label="Refresh real time">replay</mat-icon>
        </button>
            <button *ngIf="canCreate && this.module == 'Signal'" title="Delete selected signals" class="onleft-margin" mat-icon-button (click)="onBulkDelete($event, this.bbjVariables4DeleteObject)">
              <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
            <button *ngIf="canCreate" class="onleft-margin" mat-icon-button (click)="onNew()">
            <mat-icon aria-label="Add">add</mat-icon>
        </button>
        </mat-card-actions>
    </mat-card-header>

    <div class="loading-shade" *ngIf="loading">
        <mat-spinner *ngIf="loading"></mat-spinner>
    </div>

    <mat-table [dataSource]="data.rows" matSort matSortDisableClear multiTemplateDataRows>

        <ng-container *ngFor="let field of fields; let i = index" matColumnDef={{field.id}}>

            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="!field.sortable">{{field.header}}</span>
                <span *ngIf="field.sortable" mat-sort-header (click)="onSort()">{{field.header}}</span>
                <input *ngIf="field.filterable" matInput class='filterInput' (keyup)="applyFilter(field.id, $event.target.value)" placeholder="Filter {{lowerCaseFirstLetter(field.header)}}" />

            </mat-header-cell>

            <mat-cell *matCellDef="let element" [ngSwitch]="field.type" class="ellipsis" fxFlex="10px">
                <span *ngIf="getValue(element, field.display) != null">
          <!--TYPE DEFAULT-->
          <span *ngSwitchDefault title="{{getValue(element, field.display)}}">{{getValue(element, field.display)}}</span>
                <!--END TYPE DEFAULT-->

                <!--TYPE ENTITY_ARRAY-->
                <span *ngSwitchCase="'entityArray'">
            <span *ngIf="getValue(element, 'allEntities'); else entitiesElseBlock">
              All
            </span>
                <ng-template #entitiesElseBlock>
                    <span *ngFor="let entity of getValue(element, field.display)">{{getValue(entity, 'name')}}, </span>
                </ng-template>
                </span>
                <!--END TYPE ARRAY-->

                <!--TYPE IMAGE-->
                <span *ngSwitchCase="'image'">
            <span *ngIf="field.display == 'image'; else imageElseBlock">
              <img alt="{{getValue(element, 'name')}}" title="{{getValue(element, 'name')}}" src="{{getValue(element, field.display)}}" class="table-img">
            </span>
                <ng-template #imageElseBlock>
                    <img alt="{{getValue(element, field.display.concat('.name'))}}" title="{{getValue(element, field.display.concat('.name'))}}" src="{{getValue(element, field.display.concat('.image'))}}" class="table-img">
                    <span style="display:none;">{{getValue(element, field.display.concat('.name'))}}</span>
                </ng-template>
                </span>
                <!--END TYPE IMAGE-->

                <!--TYPE STATUS-->
                <span *ngSwitchCase="'status'">
            <span *ngIf="element.status == 0" title="Connected and receiving data">
              <mat-icon aria-label="Connected and receiving data" class="size-28 green-color">cloud_done_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">Connected and receiving data</span>
                </span>
                <span *ngIf="element.status == 1" title="Connected but not receiving data">
              <mat-icon aria-label="Connected but not receiving data" class="size-28 orange-color">cloud</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px; ">Connected but not receiving data</span>
                </span>
                <span *ngIf="element.status == 2" title="Not connected">
              <mat-icon aria-label="Not connected" class="size-28 red-color">cloud_off_outline</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px;">Not connected</span>
                </span>
                <span *ngIf="element.status ==-1" title="Loading...">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </span>
                </span>
                <!--END TYPE STATUS-->

                <!--TYPE REALTIME-->
                <span *ngSwitchCase="'realTime'">
            <span *ngIf="element.realTime == '-1'" title="Loading...">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </span>

                <span *ngIf="element.realTime == '2' || element.realTime == '1'" title="Not value" class="red-color">
            <strong>N/A</strong>
            </span>
                <!--
            <span *ngIf="element.realTime == '1'" title="Connected but not receiving data" >
              <mat-icon aria-label="Connected but not receiving data" class="size-28 orange-color">cloud</mat-icon>
              <span style="vertical-align: middle;  margin-left: 10px; ">Connected but no value</span>
            </span> -->

                <span *ngIf="element.realTimeStatus" title="  {{getValueRealTime(element)}}">
            <i>{{getValueRealTime(element)}}</i>
            </span>
                </span>
                <!--END TYPE REALTIME-->

                <!--TYPE ICON-->
                <span *ngSwitchCase="'icon'" class="{{getValue(element, field.display)}}" style="font-size: 3em !important;"></span>
                <!--END TYPE ICON-->

                <!--TYPE CHIP-->
                <span *ngSwitchCase="'chip'" class="bs-label" [ngStyle]="{'background-color':getValue(element, 'role.color')}">
            {{getValue(element, field.display)}}
          </span>
                <!--END TYPE CHIP-->

                <!--TYPE CHIP2 for Role-->
                <span *ngSwitchCase="'chip2'" class="bs-label" [ngStyle]="{'background-color':getValue(element, 'color')}">
            {{getValue(element, field.display)}}
          </span>
                <!--END TYPE CHIP2 for Role-->

                <!--TYPE COLOR-->
                <span *ngSwitchCase="'color'" class="circle-color" [style.background-color]="getValue(element, field.display)"></span>
                <!--END TYPE COLOR-->

                <!--TYPE CHECK-->
                <span *ngSwitchCase="'check'">
            <mat-slide-toggle [checked]="getValue(element, field.display)" (click)="prevent($event)"></mat-slide-toggle>
          </span>

                <!--END TYPE CHECK-->

                <span *ngSwitchCase="'checkBox'">
            <span (click)="clickDelete($event, element)">
                <mat-checkbox> <br></mat-checkbox>
            </span>
                </span>

                <!--TYPE CHANNELS-->
                <span *ngSwitchCase="'channels'">
            <span *ngFor="let e of getValue(element, field.display)" class="{{getValue(e, 'icon')}}" style="font-size: 2em !important;">
            </span>
                </span>
                <!--END TYPE CHANNELS-->

                </span>
            </mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let element" [attr.colspan]="idFields.length">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <!-- <div class="example-element-diagram"> -->
                    <!-- <button  class="onleft-margin assign-margin"  mat-raised-button color="warn" (click)="assignEntityToLicense()">
              <mat-icon aria-label="add_to_photos">add_to_photos</mat-icon>
              Assign new machine to license
          </button> -->
                    <hr>
                    <mat-chip-list *ngFor="let entity of element.entities" class="chip-list-padding">
                        <mat-chip *ngIf="entity.entity_license.active == true">{{entity.name}}</mat-chip>
                    </mat-chip-list>

                    <!-- <ul *ngFor="let entity of element.entities">
          <li>{{entity.name}}</li>
          <li>fwef</li>
          <li>fwef</li>
          <li>fwef</li>
        </ul> -->
                    <!-- <div class="example-element-position"> {{element.position}} </div>
            <div class="example-element-symbol"> {{element.symbol}} </div>
            <div class="example-element-name"> {{element.name}} </div>
            <div class="example-element-weight"> {{element.weight}} </div> -->
                    <!-- </div> -->
                    <!-- <div class="example-element-description">
            {{element.description}}
            <span class="example-element-description-attribution"> -- Wikipedia </span>
          </div> -->
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="idFields"></mat-header-row>
        <mat-row *matRowDef="let element; columns: idFields;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
        </mat-row>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </mat-table>

    <mat-paginator #paginator [length]="data.count"></mat-paginator>

</div>