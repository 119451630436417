import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountListComponent }   from './account-list/account-list.component';
import { AccountEditComponent }   from './account-edit/account-edit.component';

const accountsRoutes: Routes = [
  { path: '',
    children: [
      {path: 'new', component: AccountEditComponent,
      },
      {path: 'edit/:id', component: AccountEditComponent,
      },
      {path: 'profile/:id', component: AccountEditComponent,
      },
      {path: '', component: AccountListComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(accountsRoutes)],
  exports: [RouterModule],
  providers: []
})
export class AccountsRoutingModule { }
