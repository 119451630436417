import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Addon } from './../addon';
import { Entity } from '../../entities/entity';

import { AddonService } from '../addon.service';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'addon-list',
  templateUrl: './addon-list.component.html',
  styleUrls: ['./addon-list.component.css'],
  providers: [ AddonService ]
})

export class AddonListComponent implements OnInit {

  addons: Addon[];
  module: string = 'Addon';
  table_headers: Array<string>= ['Model', 'Address', 'Alarm'];
  fields: Array<any>= [
    { id: 'entity.name', display: 'entity.name', header: 'Machine', sortable: true, filterable: true },    
    { id: 'addonModel.masterAddon.type', display: 'addonModel.masterAddon.type', header: 'Type', sortable: true, filterable: true },
    { id: 'addonModel.modelName', display: 'addonModel.modelName', header: 'Model name', sortable: true, filterable: true },
    { id: 'addonModel.serialNumber', display: 'addonModel.serialNumber', header: 'Serial number', sortable: true, filterable: true },
    { id: 'addonModel.description', display: 'addonModel.description', header: 'Model Description', sortable: true, filterable: true },
    { id: 'description', display: 'description', header: 'Addon Description', sortable: true, filterable: true },
  ];
  menuTitle: string = 'Addons';
  insideEntity: boolean = false;
  loading: boolean = true;

  query: any = {pageSize: 10, pageIndex: 0, sort: null, direction: null, filter: null};
  componentName = this.module;
  
  entity: Entity;

  isAdmin: boolean = false;
  canCreate: boolean = false;
  errorMessage: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private addonService: AddonService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private _location: Location,
  ) { }

  ngOnInit() {    
    this.authService.getRole().then((role) => {
      if (role == 1) {
        this.isAdmin = true;
        this.canCreate = true;
      } else {
        this._location.back();
      }
    });
    this.route.data
      .subscribe((data: { entity: Entity }) => {
        if(data.entity) {
          this.entity = data.entity;
          this.insideEntity = true;
          this.canCreate = true;
          this.fields = [   
            { id: 'addonModel.masterAddon.type', display: 'addonModel.masterAddon.type', header: 'Type', sortable: true, filterable: true },
            { id: 'addonModel.modelName', display: 'addonModel.modelName', header: 'Model name', sortable: true, filterable: true },
            { id: 'addonModel.serialNumber', display: 'addonModel.serialNumber', header: 'Serial number', sortable: true, filterable: true },
            { id: 'addonModel.description', display: 'addonModel.description', header: 'Model Description', sortable: true, filterable: true },
            { id: 'description', display: 'description', header: 'Addon Description', sortable: true, filterable: true },
          ];
        }
      });
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    if (this.entity) {
      this.router.navigate(['/addons/new'], { queryParams: { entity: this.entity.idEntity } });
    } else {
      this.router.navigate(['/addons/new']);
    }
  }

  onSelect(addon: Addon){
    if (this.entity) {
      this.router.navigate(['/addons/edit',addon.idAddon ], { queryParams: { entity: this.entity.idEntity } });
    } else {
      this.router.navigate(['/addons/edit',addon.idAddon ]);
    }
  }

  onEdit(addon: Addon){
    if (this.entity) {
      this.router.navigate(['/addons/edit',addon.idAddon ], { queryParams: { entity: this.entity.idEntity } });
    } else {
      this.router.navigate(['/addons/edit',addon.idAddon ]);
    }
  }

  onRemove(addon: Addon){
    this.addonService.removeAddon(addon.idAddon)
        .subscribe(
          error => this.errorMessage = <any>error);
  }

  onGetData(){
    this.loading = true;
    this.unsubscribeData();    
    if (this.entity) {
      this.loadEntityAddons();
    } else {
      this.loadAllAddons();
    }
  }

  loadEntityAddons() {
    this.subscription = this.addonService.getEntityAddons(this.route.snapshot.params.id)
      .subscribe( data => {
        this.loading = false;
        this.addons = data;
      })
  }

  loadAllAddons() {
    this.subscription = this.addonService.getAddons(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        this.loading = false;
        this.addons = data;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

}
