<mat-card>
  <mat-card-header>
    <mat-card-title>New Machine</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-horizontal-stepper linear>
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step [stepControl]="zeroFormGroup" [completed]="freeVixionEdge && vixionEdgeNext">
          <ng-template matStepLabel>Select your Vixion Edge</ng-template>
          <mat-form-field id="spVixionEdgeWidth">
            <input type="text"
            required
                   placeholder="Vixion Edge"
                   aria-label="Vixion Edge"
                   matInput [formControl]="myControl_all"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let dataloggerHardware of filteredAllDataloggerHardwares | async" [value]=dataloggerHardware.serialNumber (onSelectionChange)="onChangeVixionEdge()">
                {{ dataloggerHardware.serialNumber }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="freeVixionEdge; else matError" align="start"><strong>Vixion Edge: serial number</strong> </mat-hint>
            <ng-template #matError>
              <mat-error >
                <strong>There is not available Vixion Edge. Contact Vixion team.</strong>
              </mat-error>
            </ng-template>
          </mat-form-field>
          <br>
          <br>
          <div class="stepperButton">
            <button mat-button matStepperNext *ngIf="freeVixionEdge && vixionEdgeNext; else disabledButton">NEXT</button>
            <ng-template #disabledButton>
              <button mat-button matStepperNext disabled>NEXT</button>
            </ng-template>
          </div>
      </mat-step>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Describe machine</ng-template>

          <mat-form-field>
            <input matInput
                   required
                   placeholder="Name"
                   formControlName="name">
          </mat-form-field>

          <mat-form-field>
            <input matInput
                   required
                   placeholder="Serial Number" formControlName="serialNumber">
          </mat-form-field>

          <mat-form-field>
            <input matInput
                   type="number"
                   placeholder="Manufacture Year"
                   formControlName="manufactureYear">
          </mat-form-field>

          <mat-form-field>
            <input type="text"
                   required
                   placeholder="Model"
                   aria-label="Entity Model"
                   matInput [formControl]="myControl3"
                   [matAutocomplete]="auto3"
                   >
            <mat-autocomplete #auto3="matAutocomplete" >
              <mat-option *ngFor="let entityModel of filteredEntityModels | async" [value]=entityModel.name>
                {{ composeEntityModel(entityModel) }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint align="start"><strong>Machine model type</strong> </mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="isAdmin">
            <input matInput
                   required
                   placeholder="MongoDB"
                   formControlName="mongoDB">
          </mat-form-field>
          <mat-form-field *ngIf="isAdmin">
            <input matInput
                   required
                   placeholder="dataPath"
                   formControlName="dataPath">
          </mat-form-field>
          <mat-form-field>
            <input matInput required id="hourlyPrice" type="number" name="hourlyPrice" placeholder="Hourly price €" formControlName="hourlyPrice">
          </mat-form-field>
          <mat-form-field>
            <input matInput required id="oeeObjective" type="number" name="oeeObjective" placeholder="OEE objective %" min="0" max="100" formControlName="oeeObjective">
          </mat-form-field>
          <mat-form-field>
            <input matInput id="warrantyEnd" [matDatepicker]="picker" placeholder="Warranty end date" (click)="picker.open()" formControlName="warrantyEnd">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

          <div class="location">
            <button mat-raised-button type="button" color="primary" class="location-button" (click)="onLocationClick()">
              <mat-icon aria-label="add_to_photos">location_searching</mat-icon>
              MAP LOCATION
            </button>
            <label *ngIf="locationString">{{locationString}}</label>
            <label *ngIf="!locationString">There is no location selected</label>
          </div>

          <!-- <mat-form-field>
            <input matInput [matDatepicker]="picker"  placeholder="License start date"  formControlName="licenseStart" (click)="picker.open()" [max]="today">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field> -->
          <div class="stepperButton">
            <button mat-button matStepperPrevious>BACK</button>
            <button mat-button matStepperNext>NEXT</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Add main datasource</ng-template>

          <mat-form-field id="spDatasourceWidth">
            <input type="text"
                   required
                   placeholder="Device or protocol"
                   aria-label="Vixion Edge"
                   matInput [formControl]="myControl2"
                   [matAutocomplete]="auto2"
                   >
            <mat-autocomplete #auto2="matAutocomplete" >
              <mat-option *ngFor="let datasourceModel of filteredDatasourceModels | async" [value]=datasourceModel.name
              (onSelectionChange)="onChange($event, datasourceModel)">
                {{ datasourceModel.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint align="start"><strong>Machine control</strong> </mat-hint>
          </mat-form-field>

           <mat-form-field>
            <input matInput
                   required
                   placeholder="Datasource address"
                   formControlName="address">
            <mat-hint align="start"><strong>IP address in local network</strong> </mat-hint>
          </mat-form-field>
          </form>
          <br><br>
          <form [formGroup]="thirdFormGroup">
          <ng-container *ngFor="let field of fields" >
          <ng-container [ngSwitch]="field.key">
          <mat-form-field *ngSwitchCase="'user'">
              <input matInput
                     placeholder='Username'
                     [value]="field.value"
                     formControlName={{field.key}}>
              <mat-hint align="start"><strong>Username for the datasource</strong> </mat-hint>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'password'">
            <input matInput
                   placeholder='Password'
                   type="password"
                   [value]="field.value"
                   formControlName={{field.key}}>
              <mat-hint align="start"><strong>Password for the datasource</strong> </mat-hint>
          </mat-form-field>
          <mat-form-field *ngSwitchDefault>
            <input matInput
                   placeholder="{{field.key}}"
                   [value]="field.value"
                   formControlName={{field.key}}>
          </mat-form-field>

          </ng-container>
        </ng-container>
        </form>

          <div class="stepperButton">
            <button mat-button matStepperPrevious>BACK</button>
            <button mat-button matStepperNext>NEXT</button>
          </div>

      </mat-step>

      <mat-step [stepControl]="thirdFormGroup" *ngIf="showMapVariables">
        <form [formGroup]="fourthFormGroup">
          <ng-template matStepLabel>Default variables</ng-template>
          <mat-card class="mapvariables-card">
            <p><mat-icon aria-label="info">info</mat-icon>
            Vixion needs the following signals in order to be fully configured. Vixion gets these signals from the PLC of the machine so please, enter PLC memory address where Vixion can find them. You don't have to configure them right now, but pay attention to them before you apply the configuration of your machine.</p>
          </mat-card>
          <br><br>
          <ng-container *ngFor="let field of mapVariableFields">
          <mat-form-field class="spMapVariables">
            <input matInput
                   placeholder="{{field.label}}"
                   [value]="field.value"
                   formControlName={{field.key}}>
          </mat-form-field>
          <br>
          </ng-container>
        </form>
          <!-- <br><br> -->
          <!-- <form [formGroup]="thirdFormGroup">
          <ng-container *ngFor="let field of fields" >
          <ng-container [ngSwitch]="field.key">
          <mat-form-field *ngSwitchCase="'user'">
              <input matInput
                     placeholder='Username'
                     [value]="field.value"
                     formControlName={{field.key}}>
              <mat-hint align="start"><strong>Username for the datasource</strong> </mat-hint>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="'password'">
            <input matInput
                   placeholder='Password'
                   type="password"
                   [value]="field.value"
                   formControlName={{field.key}}>
              <mat-hint align="start"><strong>Password for the datasource</strong> </mat-hint>
          </mat-form-field>
          <mat-form-field *ngSwitchDefault>
            <input matInput
                   placeholder="{{field.key}}"
                   [value]="field.value"
                   formControlName={{field.key}}>
          </mat-form-field>

          </ng-container>
        </ng-container>
        </form> -->

        <div class="stepperButton">
          <button mat-button matStepperPrevious>BACK</button>
          <button mat-button matStepperNext>NEXT</button>
        </div>

      </mat-step>

      <!-- <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">
          <ng-template matStepLabel>Add datasource values</ng-template>

          <mat-form-field *ngFor="let field of fields" >
            <input matInput
                   placeholder={{field.key}}
                   [value]="field.value"
                   formControlName={{field.key}}>
          </mat-form-field>

          <div class="stepperButton">
            <button mat-button matStepperPrevious>BACK</button>
            <button mat-button matStepperNext>NEXT</button>
          </div>
        </form>
      </mat-step> -->

      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p>Almost done. Press FINISH to save the new machine.</p>
        <p>Then, please continue configuring signals, settings and more from your new machine.</p>
        <div *ngIf="saving">
          <mat-spinner *ngIf="saving"></mat-spinner>
        </div>
        <div class="stepperButton">
          <button mat-button matStepperPrevious [disabled]="saving">BACK</button>
          <button mat-button matStepperNext (click)="onFinish()" [disabled]="saving">FINISH</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>
