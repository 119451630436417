import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

import { MasterSetting }      from './masterSetting';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class MasterSettingService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all masterSettings
   */
  public getMasterSettings(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<MasterSetting[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<MasterSetting[]>(environment.api_url + '/masterSettings', { params: queryParams });
  };
  /**
   * Retrieves masterSetting details by a given id
   *
   * @param idMasterSetting
   */
  public getMasterSetting(idMasterSetting: string): Observable<MasterSetting> { 
    return this.http.get<MasterSetting>(environment.api_url + '/masterSetting/' + idMasterSetting);
  };

  /**
   * Save masterSetting
   *
   * @param masterSetting
   */
  public saveMasterSetting(masterSetting: MasterSetting): Observable<any> {
    if (masterSetting.idMasterSetting) {
      return this.updateMasterSetting(masterSetting);
    } else {
      return this.newMasterSetting(masterSetting);
    }
    //return null;
  };

  /**
   * Create new masterSetting
   *
   * @param masterSetting
   */
  public newMasterSetting(masterSetting: MasterSetting): Observable<any> { 
    return this.http.post(environment.api_url + '/masterSetting', masterSetting);
  };

  /**
   * Update masterSetting
   *
   * @param masterSetting
   */
  public updateMasterSetting(masterSetting: MasterSetting): Observable<any> { 
    return this.http.put(environment.api_url + '/masterSetting', masterSetting);
  };

  /**
   * Delete an masterSetting by a given id
   *
   * @param idMasterSetting
   */
  public removeMasterSetting (idMasterSetting: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/masterSetting/' + idMasterSetting);
  }


}
