import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-remove-bbjvariables',
  templateUrl: './message-remove-bbjvariables.component.html',
  styleUrls: ['./message-remove-bbjvariables.component.css']
})
export class MessageRemoveBBJVariablesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MessageRemoveBBJVariablesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  public remove(): void {
    this.dialogRef.close(true);
  }

  public close(): void {
    this.dialogRef.close(false);
  }

}
