<mat-card-header [style.background-color]="cardHeaderColor">
    <div *ngIf="isBeta" class="ribbon"><span>Beta</span></div>
    <button *ngIf="!hideBackButton" class="start-action" mat-icon-button (click)="onBack()">
        <mat-icon aria-label="Back">arrow_back</mat-icon>
    </button>

    <mat-card-title>
      {{this.module}}
    </mat-card-title>
    <mat-card-actions>
      <div class="message" *ngFor="let message of messages" title="{{message.title}}">
        <p *ngIf="message.type === 'text'" [ngStyle]="message.style">{{message.text}}</p>
        <mat-icon *ngIf="message.type === 'icon'" class="mat-icon material-icons {{message.class}}" [ngStyle]="message.style">{{message.text}}</mat-icon>
      </div>
    <span [ngSwitch]="cardHeaderState">
      <span *ngSwitchCase="'duplicate'">
        <button class="onleft-margin" mat-icon-button (click)="onGenerateBBJ()" title="Apply configuration">
          <mat-icon aria-label="Apply">cloud_done</mat-icon>
        </button>
        <button class="onleft-margin" mat-icon-button (click)="onDuplicate()" title="Duplicate">
          <span class="vi vi-machine-duplicate-filled vi-2x"></span>
        </button>
        <button class="onleft-margin"  mat-icon-button (click)="onEdit()" title="Edit">
            <mat-icon aria-label="Edit">mode_edit</mat-icon>
        </button>
        <button class="onleft-margin" mat-icon-button (click)="openDialog()" title="Delete">
            <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </span>

      <span *ngSwitchCase="'impersonate'">
        <button class="onleft-margin" mat-icon-button (click)="onImpersonate()" title="Impersonate">
            <mat-icon aria-label="Impersonate">supervisor_account</mat-icon>
        </button>
        <button class="onleft-margin"  mat-icon-button (click)="onEdit()" title="Edit">
            <mat-icon aria-label="Edit">mode_edit</mat-icon>
        </button>
        <button class="onleft-margin" mat-icon-button (click)="openDialog()" title="Delete">
            <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </span>

      <span *ngSwitchCase="'new'">
        <button class="onleft-margin"  mat-icon-button (click)="onCancel()" title="Cancel">
          <mat-icon aria-label="Cancel">clear</mat-icon>
        </button>
        <button class="onleft-margin"  mat-icon-button (click)="onSave(account)" title="Accept">
          <mat-icon aria-label="Accept">done</mat-icon>
        </button>
      </span>

      <span *ngSwitchCase="'delete'">
        <button class="onleft-margin" mat-icon-button (click)="openDialog()" title="Delete">
            <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </span>
      <span *ngSwitchCase="'edit'">
          <button class="onleft-margin"  mat-icon-button (click)="onEdit()" title="Edit">
          <mat-icon aria-label="Edit">mode_edit</mat-icon>
        </button>
        <button class="onleft-margin" mat-icon-button (click)="openDialog()" title="Delete">
            <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </span>
      <span *ngSwitchCase="'onlyEdit'">
          <button class="onleft-margin"  mat-icon-button (click)="onEdit()" title="Edit">
          <mat-icon aria-label="Edit">mode_edit</mat-icon>
        </button>
      </span>
      <span *ngSwitchCase="'disabled'">
          <!-- <button class="onleft-margin"  mat-icon-button (click)="onEdit()">
          <mat-icon aria-label="Edit">mode_edit</mat-icon>
        </button> -->
      </span>
    </span>
    </mat-card-actions>

</mat-card-header>
