<mat-card>
  <cardHeader-edit (headerActionEmitter)='onheaderActionEmitter($event)' [module]='module' [editMode]='editMode' [initialCardHeaderState]='initialCardHeaderState' [cardHeaderState]='cardHeaderState'>
  </cardHeader-edit>
  <mat-card-content>
    <mat-form-field>
      <input matInput
             id="name" 
             required
             name="name" 
             [disabled]="!editMode"
             placeholder="Name"
             [(ngModel)]="role.name" 
             #name="ngModel">       
    </mat-form-field>
    <mat-form-field>
      <input matInput
             id="description"
             name="description" 
             [disabled]="!editMode"
             placeholder="Description"
             [(ngModel)]="role.description" 
             #description="ngModel">
    </mat-form-field>
    <mat-form-field>
      <input matInput
             type="number"
             id="weight"
             name="weight" 
             [disabled]="!editMode"
             placeholder="Weight"
             [(ngModel)]="role.weight" 
             #weight="ngModel">
    </mat-form-field>
    <mat-form-field>
      <input matInput
             type="color"
             id="color"
             name="color" 
             [disabled]="!editMode"
             placeholder="Color"
             [(ngModel)]="role.color" 
             #color="ngModel">
    </mat-form-field>
  </mat-card-content>
</mat-card>
