<tableListHttp
  [module]='module'
  [fields]='fields'
  [data]='roles'
  [canCreate]=true
  
  [menuTitle]='menuTitle'
  [query]='query'
  (tableListEmitter)='onTableListEmitter($event)'>
</tableListHttp>
