<div class="button-row"
  (mouseover)="icon = ''"
  (mouseout)="icon = 'add'">
  <!-- <button disabled mat-mini-fab tooltip="Add notification"
          color="primary"
          routerLink="/notifications/edit" routerLinkActive="active">
    <span class="pe pe-7s-bell pe-2x" aria-label="Notifications"></span>
  </button>
  <button disabled mat-mini-fab tooltip="Add executor"
          color="primary"
          routerLink="/executorInstances/edit" routerLinkActive="active">
          <span class="pe pe-7s-play pe-2x" aria-label="Executor Instances"></span>
  </button> -->
  <!-- <button mat-mini-fab tooltip="Add measurement point"
          color="primary"
          routerLink="/accounts/edit" routerLinkActive="active">
    <mat-icon aria-label="Wifi">wifi</mat-icon>
  </button> -->
  <button mat-mini-fab tooltip="Add account"
          color="primary"
          routerLink="/accounts/new" routerLinkActive="active">
    <!--<span class="pe pe-7s-users pe-2x" aria-label="Accounts"></span>-->
    <span class="vi vi-user-add-filled vi-2x" aria-label="Accounts"></span>
  </button>
  <button mat-mini-fab tooltip="Add machine"
          color="primary"
          routerLink="/entities/new" routerLinkActive="active">
    <!--<mat-icon aria-label="Add to queue">add_to_queue</mat-icon>-->
          <!--<span class="vi vi-machine vi-2x" aria-label="Machine"></span>-->
          <span class="vi vi-machine-add-filled vi-2x" aria-label="Machine"></span>
  </button>
  <button mat-fab tooltip="Duplicate machine"
          (click)="onTap($event)">
    <mat-icon aria-label="Add" [ngClass]="{'vi vi-machine-duplicate-filled vi-2x': icon != 'add' }">{{icon}}</mat-icon>
  </button>
</div>
