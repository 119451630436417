<tableListHttp
  [module]='module'
  [fields]='fields'
  [data]='notificationRules'
  [canCreate]='canCreate'
  [loading]='loading'
  [menuTitle]='menuTitle'
  [query]='query'
  (tableListEmitter)='onTableListEmitter($event)'
  [insideEntity]='insideEntity'>
</tableListHttp>