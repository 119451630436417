import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Metric }      from './metric';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class MetricsService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves last metrics
   */
  public getLastMetricsEntity(idEntity: string): Observable<Metric[]> {  
    return this.http.get<Metric[]>(environment.api_url + '/edge-metrics-last/entity/' + idEntity);
  };

  /**
   * Retrieves all type metrics
   */
  public getMetricsEntityAndTypeMetric(idEntity: number, idType: number, metricSubId: string, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Metric[]> { 
    let queryParams = new HttpParams();
    if(metricSubId !== null) queryParams = queryParams.set('metricSubId', metricSubId.toString());
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Metric[]>(environment.api_url + '/edge-metrics/entity/' + idEntity + '/type/' + idType, { params: queryParams });
  };
}


