import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AggregatedSignal } from './../aggregatedSignal';
import { Entity } from '../../entities/entity';

import { AggregatedSignalService } from '../aggregatedSignal.service';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'aggregatedSignal-list',
  templateUrl: './aggregatedSignal-list.component.html',
  styleUrls: ['./aggregatedSignal-list.component.css'],
  providers: [ AggregatedSignalService ]
})

export class AggregatedSignalListComponent implements OnInit {

  aggregatedSignals: AggregatedSignal[];
  module: string = 'AggregatedSignal';
  // table_headers: Array<string>= ['Machine','Name', 'Expression count', 'Operation', 'Returns'];
  fields: Array<any>= [
    { id: 'entity.name', display: 'entity.name', header: 'Machine', sortable: true, filterable: true },
    { id: 'name', display: 'name', header: 'Aggregated signal name', sortable: true, filterable: true }
  ];
  menuTitle: string = 'Aggregated signals';
  insideEntity: boolean = false;
  loading: boolean = false;

  query: any = {pageSize: 10, pageIndex: 0, sort: 'name', direction: 'asc', filter: null};
  
  entity: Entity;

  isAdmin: boolean = false;
  canCreate: boolean = false;
  errorMessage: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private aggregatedSignalService: AggregatedSignalService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private _location: Location,
  ) { }

  ngOnInit() {    
    this.authService.getRole().then((role) => {
      if (role < 3) {
        this.isAdmin = true;
        this.canCreate = true;
      }
    });
    this.route.data
      .subscribe((data: { entity: Entity }) => {
        if(data.entity) {
          this.entity = data.entity;
          this.insideEntity = true;
          this.canCreate = true;
          this.fields = [
            { id: 'name', display: 'name', header: 'Name', sortable: true, filterable: true },
            // { id: 'aggregatedSignalModel.expressions.length', display: 'aggregatedSignalModel.expressions.length', header: 'Expression count', sortable: true, filterable: true },
            // { id: 'aggregatedSignalModel.returns.type', display: 'aggregatedSignalModel.returns.type', header: 'Returns', sortable: true, filterable: true }
          ];
        }
      });
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    if (this.entity) {
      this.router.navigate(['/aggregatedSignals/new'], { queryParams: { entity: this.entity.idEntity } });
    } else {
      this.router.navigate(['/aggregatedSignals/new']);
    }
  }

  onSelect(aggregatedSignal: AggregatedSignal){
    if (this.entity) {
      this.router.navigate(['/aggregatedSignals/edit',aggregatedSignal.idAggregatedSignal ], { queryParams: { entity: this.entity.idEntity } });
    } else {
      this.router.navigate(['/aggregatedSignals/edit',aggregatedSignal.idAggregatedSignal ]);
    }
  }

  onEdit(aggregatedSignal: AggregatedSignal){
    if (this.entity) {
      this.router.navigate(['/aggregatedSignals/edit',aggregatedSignal.idAggregatedSignal ], { queryParams: { entity: this.entity.idEntity } });
    } else {
      this.router.navigate(['/aggregatedSignals/edit',aggregatedSignal.idAggregatedSignal ]);
    }
  }

  onRemove(aggregatedSignal: AggregatedSignal){
    this.aggregatedSignalService.removeAggregatedSignal(aggregatedSignal.idAggregatedSignal)
        .subscribe(
          error => this.errorMessage = <any>error);
  }

  onGetData(){
    this.loading = true;
    this.unsubscribeData();   
    if (this.entity) {
      this.loadEntityAggregatedSignals();
    } else {
      this.loadAllAggregatedSignals();
    }
  }

  loadEntityAggregatedSignals() {
    this.subscription = this.aggregatedSignalService.getEntityAggregatedSignals(this.route.snapshot.params.id, this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        this.loading = false;
        this.aggregatedSignals = data;
      })
  }

  loadAllAggregatedSignals() {
    this.subscription = this.aggregatedSignalService.getAggregatedSignals(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        this.loading = false;
        this.aggregatedSignals = data;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

}
