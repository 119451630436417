export class Account {
    constructor (
        public idAccount: number,
        public idRole: number,
        public idOrganization: number,
        public username: string,
        public fullname: string,
        public password: string,
        public idLanguage: string,
        public operator: string,
        public defaultDisplayDataCalendar: boolean) { }
}
