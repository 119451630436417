<div class="not-metrics" *ngIf="!lastMetricsData">
    <h5>Metrics not available</h5>
    <h6>For more information contact Vixion</h6>
</div>

<div class="info-table" *ngIf="lastMetricsData">
  <div class="info">
    <p>Last metrics check: {{lastMetricsData['rows'][0].lastCheck | dateFormat: 'DD/MM/YYYY HH:mm:ss' : true}} <span class="message" *ngIf="messageWarning !== ''">{{messageWarning}}</span></p>
    <p>Vixion Edge IP: {{lastMetricsData['rows'][0].vpnIp}}</p>
  </div>
  
  <tableListHttp
    [module]='module'
    [fields]='fields'
    [data]='lastMetricsData'
    [canCreate]='canCreate'
    [loading]='loading'
    [menuTitle]='menuTitle'
    [query]='query'
    [insideEntity]='true'
    (tableListEmitter)='onTableListEmitter($event)'>
  </tableListHttp>
  <div class="explain" *ngIf="mainDatasourceInDatasources">
    <p>* Main datasource</p>
  </div>
</div>

