<mat-card>
  <cardHeader-edit (headerActionEmitter)='onheaderActionEmitter($event)' [module]='module' [editMode]='editMode' [cardHeaderState]='cardHeaderState' [initialCardHeaderState]='initialCardHeaderState'>
  </cardHeader-edit>
  <mat-card-content>
    <span [ngStyle]="{'display':'inline-block', 'text-align': 'center', 'width': '49%'}">
      <img [alt]="organization.name" [title]="organization.name" [src]="organization.image" class="logo-img">
    </span>

    <mat-form-field>
      <input matInput
             id="name"
             required
             [disabled]="!editMode"
             name="name"
             placeholder="Full name"
             [(ngModel)]="organization.name"
             #name="ngModel">
    </mat-form-field>
    <mat-form-field>
      <input matInput
             id="description"
             [disabled]="!editMode"
             name="description"
             placeholder="Brand or short name"
             [(ngModel)]="organization.description"
             #description="ngModel">
    </mat-form-field>
    <mat-form-field *ngIf="isAdmin">
      <input matInput
             id="image"
             [disabled]="!editMode"
             name="image"
             placeholder="Logo path"
             [(ngModel)]="organization.image"
             #description="ngModel">
    </mat-form-field>
    <mat-form-field *ngIf="!parent">
      <mat-select name="Parent-organization"
                  required
                  placeholder="Parent organization"
                  [disabled]="!editMode"
                  [(ngModel)]="organization.hierarchy">
        <mat-option *ngFor="let org of organizations"
                    [value]="org.hierarchy"
                    (onSelectionChange)="onChangeOrg($event, org)">
                    {{ org.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select name="role"
                  placeholder="Role"
                  required
                  [disabled]="!editMode"
                  [(ngModel)]="organization.idRole">
        <mat-option *ngFor="let role of roles"
                    [value]="role.idRole">
                    {{ role.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field *ngIf="isAdmin">
      <mat-select placeholder="Views"
                  multiple
                  name="Views"
                  [disabled]="!editMode"
                  [formControl]="viewControl"
                  [compareWith]="compareViews"
                  (selectionChange)="viewSelectionChange($event)"
                  required>
        <div *ngFor="let app of apps">
          <mat-checkbox style="padding-bottom:20px" (change)="appSelectionChange($event, app)" [(ngModel)]="app.selected"> {{app.name}} </mat-checkbox>
          <mat-option *ngFor="let view of app['views']" [value]="view">
            {{view.name}}
          </mat-option>
        </div>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field *ngIf="isAdmin">
      <input matInput
             id="nameSpace"
             [disabled]="!editMode"
             name="nameSpace"
             placeholder="Name space in the server"
             [(ngModel)]="organization.nameSpace"
             #nameSpace="ngModel">
    </mat-form-field>

    <mat-tab-group *ngIf="showCalendar" [selectedIndex]="0" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="Calendar">
        <calendar [organization]="organization"></calendar>
      </mat-tab>
      <mat-tab label="Shifts" *ngIf="showCalendar">
          <shift-list [organization]="organization"></shift-list>
      </mat-tab>
    </mat-tab-group>

  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-icon-button (click)="onCancel()">
      <mat-icon aria-label="Cancel">clear</mat-icon>
    </button>
    <button mat-icon-button (click)="onSave(organization)">
      <mat-icon aria-label="Accept">done</mat-icon>
    </button>
  </mat-card-actions> -->
</mat-card>
