<mat-card>
    <cardHeader-edit (headerActionEmitter)='onheaderActionEmitter($event)' [module]='module' [entities]='entities'
        [editMode]='editMode' [cardHeaderState]='cardHeaderState' [initialCardHeaderState]='initialCardHeaderState' [messages]="showMessageHeader">
    </cardHeader-edit>
    <mat-card-content>
        <span *ngIf="entity['license'];else no_license">
            <span [ngSwitch]="entity['license'].status">
                <span *ngSwitchCase="0">
                    <mat-card class="license-card"> <strong>This machine is not assigned to a license </strong>. Vixion
                        App requires a valid license for its operation
                        <br>
                        <button mat-raised-button color="primary" class="machine-entity-button-margin"
                            (click)="assignEntityToLicense()">
                            <mat-icon aria-label="add_to_photos">add_to_photos</mat-icon>
                            Assign machine to a license
                        </button>
                    </mat-card>
                    <hr>
                </span>
                <span *ngSwitchCase="2">
                    <mat-card class="license-card-trial"> <strong>This machine is assigned to a license in Set-up mode
                        </strong>. Vixion App requires a valid license for its operation. When you have set up the
                        machine, change it to a definitive seat. Otherwise Vixion app will work with limited features.
                        <br>
                        <button mat-raised-button color="primary" class="machine-entity-button-margin"
                            (click)="changeToFullLicense()">
                            <mat-icon aria-label="add_to_photos">add_to_photos</mat-icon>
                            Change license to full active mode
                        </button>
                    </mat-card>
                    <hr>
                </span>
                <span *ngSwitchCase="3">
                    <mat-card class="license-card-expired"> <strong>This machine license seat has been expired</strong>.
                        Vixion App requires a valid license for its operation. To continue using Vixion services, you
                        have to re assign the machine to another valid license
                        <br>
                        <button mat-raised-button color="primary" class="machine-entity-button-margin"
                            (click)="assignEntityToLicense()">
                            <mat-icon aria-label="add_to_photos">add_to_photos</mat-icon>
                            Assign machine to a license
                        </button>
                    </mat-card>
                    <hr>
                </span>
                <span *ngSwitchDefault>
                </span>
            </span>
        </span>
        <ng-template #no_license>
            <span>
                <mat-card class="license-card"> <strong>This machine is not assigned to a license </strong>. Vixion App
                    requires a valid license for its operation
                    <br>
                    <button mat-raised-button color="primary" class="machine-entity-button-margin"
                        (click)="assignEntityToLicense()">
                        <mat-icon aria-label="add_to_photos">add_to_photos</mat-icon>
                        Assign machine to a license
                    </button>
                </mat-card>
                <hr>
            </span>
        </ng-template>
       
        <!-- <mat-divider></mat-divider> -->
        <mat-form-field>
            <input matInput id="owner" required [disabled]="true" name="owner" placeholder="Owner"
                [(ngModel)]="dataloggerHardware['organization'].name">
        </mat-form-field>
        <mat-form-field>
            <input matInput id="name" required [disabled]="!editMode" name="name" placeholder="Name"
                [(ngModel)]="entity.name" #name="ngModel">
        </mat-form-field>
        <mat-form-field>
            <mat-select name="entityModel" required [disabled]="!editMode || !canCreate" [disabled]=new
                placeholder="Model" [(ngModel)]="entity.idEntityModel">
                <mat-option *ngFor="let entityModel of entityModels" [value]="entityModel.idEntityModel">
                    {{ composeEntityModel(entityModel) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input matInput id="serialNumber" [disabled]="!editMode || !canCreate" name="serialNumber"
                placeholder="Serial number" [(ngModel)]="entity.serialNumber" #serialNumber="ngModel">
        </mat-form-field>
        <mat-form-field>
            <input 
                id="tags" 
                matInput 
                [disabled]="!editMode || !canCreate" 
                name="tags" 
                placeholder="Tags, separated by commas. Useful for aggregate similar machines" 
                [(ngModel)]="entity.tags" 
                #tags="ngModel"
            >
            <mat-hint class="mat-error" *ngIf="entity.tags != null && entity.tags != '' && !validateTags()">Tags should be comma-separated and can only include alphanumeric characters, _ and/or -</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input matInput type="number" id="manufactureYear" [disabled]="!editMode || !canCreate"
                name="manufactureYear" placeholder="Manufacturing year" [(ngModel)]="entity.manufactureYear"
                #manufactureYear="ngModel">
        </mat-form-field>
        <mat-form-field (click)="goToVixionEdge(dataloggerHardware)" class="pointer">
            <input matInput id="Vixion_Edge" [disabled]="true" name="Vixion Edge" placeholder="Vixion Edge"
                [(ngModel)]="dataloggerHardware.serialNumber">
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin">
            <input matInput id="mongoDB" [disabled]="!editMode || !canCreate" name="mongoDB"
                placeholder="mongoDB instance" [(ngModel)]="entity.mongoDB" #mongoDB="ngModel">
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin">
            <input matInput id="dataPath" [disabled]="!editMode || !canCreate" name="dataPath"
                placeholder="Folder path of data files in the server" [(ngModel)]="entity.dataPath" #dataPath="ngModel">
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin">
            <input matInput id="nameSpace" [disabled]="!editMode || !canCreate" name="nameSpace"
                placeholder="Name space in the server" [(ngModel)]="entity.nameSpace" #nameSpace="ngModel">
        </mat-form-field>
        <mat-form-field>
            <input matInput id="hourlyPrice" [disabled]="!editMode || !canEditEntityData" name="hourlyPrice"
                placeholder="Hourly price €" type="number" [(ngModel)]="entity.entityInfo.hourlyPrice.hourlyPrice"
                #hourlyPrice="ngModel">
        </mat-form-field>
        <mat-form-field>
            <input matInput id="oeeObjective" [disabled]="!editMode || !canEditEntityData" name="oeeObjective"
                placeholder="OEE objective %" type="number" min="0" max="100"
                [(ngModel)]="entity.entityInfo.oeeObjective.oeeObjective" #oeeObjective="ngModel">
        </mat-form-field>
        <mat-form-field>
            <input matInput id="warrantyEnd" [disabled]="!editMode || !canEditEntityData" [matDatepicker]="picker" placeholder="Warranty end date" (click)="picker.open()" [(ngModel)]="entity.warrantyEnd">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="hasActiveLicense && activeLicense" class="pointer" (click)="goToLicense(activeLicense.idLicense)">
            <input matInput id="licenseStart" disabled name="licenseStart"
                placeholder="License start date for this machine" [(ngModel)]="formatedStart">
        </mat-form-field>
        <mat-form-field *ngIf="(isAdmin || isProvider) && viewsEnabled">
            <mat-select placeholder="Views" multiple name="Views" [disabled]="!editMode" [formControl]="viewControl"
                required>
                <div *ngFor="let view of apps_views" class="option {{view.elem}}">
                    <mat-option [value]="view" (onSelectionChange)="viewSelectionChange($event, view)">
                        {{view.description}} </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
        <div class="location">
            <button mat-raised-button [disabled]="!editMode || !canCreate" color="primary" class="location-button"
                (click)="onLocationClick()">
                <mat-icon aria-label="add_to_photos">location_searching</mat-icon>
                MAP LOCATION
            </button>
            <label *ngIf="locationString">{{locationString}}</label>
            <label *ngIf="!locationString">There is no location selected</label>
        </div>
        <!-- <mat-form-field>
      <input matInput [matDatepicker]="picker" [(ngModel)]="entity.licenseStart" placeholder="License start date" (click)="picker.open()" [disabled]="!editMode || !canCreate" [max]="today">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->

        <!-- <div *ngIf="dataloggerHardware['status'] == -1" class="lds-ellipsis" title="Loading..."> <div></div><div></div><div></div><div></div></div>
    <div *ngIf="dataloggerHardware['status'] == 0" class="middle inline-block margin25">
      <mat-icon aria-label="Connected and receiving data" class="size-28 green-color">cloud_done_outline</mat-icon>
      <span style="vertical-align: middle;"> Connected and receiving data</span>
    </div>
    <div *ngIf="dataloggerHardware['status'] == 1" class="middle inline-block margin25">
      <mat-icon aria-label="Connected but not receiving data" class="size-28 orange-color">cloud</mat-icon>
      <span style="vertical-align: middle;"> Connected but not receiving data</span>
    </div>
    <div *ngIf="dataloggerHardware['status'] == 2" class="middle inline-block margin25">
      <mat-icon aria-label="Not connected" class="size-28 red-color">cloud_off_outline</mat-icon>
      <span style="vertical-align: middle;"> Not connected</span>
    </div> -->

        <span *ngIf="loadingDatasourcesStatus" title="Loading...">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </span>
        <div *ngIf="allDatasourcesWorking === true" class="middle inline-block margin25" [(matTooltip)]="tooltipText"
            matTooltipClass="test">
            <mat-icon aria-label="All datasources working" class="size-28 green-color">cloud_done_outline</mat-icon>
            <span style="vertical-align: middle;"> All datasources working</span>
        </div>
        <div *ngIf="allDatasourcesFailing === true" class="middle inline-block margin25" [(matTooltip)]="tooltipText"
            matTooltipClass="test">
            <mat-icon aria-label="All datasources failing" class="size-28 red-color">cloud_off_outline</mat-icon>
            <span style="vertical-align: middle;"> All datasources failing</span>
        </div>
        <div *ngIf="workingDatasourceCount > 0 && failingDatasourceCount > 0 && allDatasourcesFailing === false && allDatasourcesWorking === false"
            class="middle inline-block margin25" [(matTooltip)]="tooltipText" matTooltipClass="test">
            <mat-icon aria-label="Some datasources failing" class="size-28 orange-color">cloud</mat-icon>
            <span style="vertical-align: middle;"> Some datasources failing</span>
        </div>

        <div style="display: flex; align-items: center; gap: 20px" *ngIf="(idOrganization === 3) || (idOrganization === 1)">
            <button mat-raised-button (click)="f_input.click()" [disabled]="uploadingZip">
                <mat-icon>cloud_upload</mat-icon>
                Upload sqlite zip
            </button>
            <div *ngIf="!uploadingZip" style="display: flex; align-items: center; gap: 10px;">
                Last file uploaded: {{fileUploaded}}
            </div>
            <div *ngIf="uploadingZip" style="display: flex; align-items: center; gap: 10px;">
                Uploading zip...
                <mat-spinner [diameter]="'20'"></mat-spinner>
            </div>
        </div>

        <input
            type="file"
            [accept]="'.zip'"
            hidden
            #f_input
            (change)="handleFileInputChange(f_input.files)"
        />

        <div class="mat-tab-group-entity-edit">
            <mat-tab-group [selectedIndex]="currentTabIndex" (selectedTabChange)="onTabClick($event)">
                <mat-tab label="Datasources">
                    <datasource-list (datasourceStatusEmitter)="onDatasourceStatusEmitter($event)"></datasource-list>
                </mat-tab>
                <mat-tab label="Signals">
                    <bbjVariable-list *ngIf="variablesLoad"></bbjVariable-list>
                </mat-tab>
                <mat-tab label="Settings">
                    <masterSetting-entity [entitySettings]='entitySettings' [entityDatasources]='datasources'
                        [masterSettings]='masterSettings'></masterSetting-entity>
                </mat-tab>
                <mat-tab label="Notifications">
                    <!-- <entityNotification-edit [entity]='entity'></entityNotification-edit> -->
                    <notificationRule-list></notificationRule-list>
                </mat-tab>
                <mat-tab label="Addons" *ngIf="isAdmin">
                    <addon-list></addon-list>
                </mat-tab>
                <mat-tab label="Aggregated signals" *ngIf="isAdmin">
                    <aggregatedSignal-list></aggregatedSignal-list>
                </mat-tab>
                <mat-tab label="Calendar" *ngIf="showCalendar">
                    <calendar [entity]="entity" [editCalendarCheckMachineAvailability]="editCalendarCheckMachineAvailability"></calendar>
                </mat-tab>
                <mat-tab label="Reports" *ngIf="canAccessReports">
                    <report-list></report-list>
                </mat-tab>
                <mat-tab label="Metrics">
                    <app-metrics-list [lastMetrics]="metrics"></app-metrics-list>
                </mat-tab>
                <mat-tab label="Availability" *ngIf="showAvailability">
                    <app-machine-availability-form></app-machine-availability-form>
                </mat-tab>
            </mat-tab-group>
        </div>

    </mat-card-content>
</mat-card>
