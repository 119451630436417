<mat-card>
  <mat-card-header>
    <mat-card-actions>
      <span [ngSwitch]="cardHeaderState">
  
        <span *ngSwitchCase="'new'">
          <button class="onleft-margin"  mat-icon-button (click)="onCancel()" title="Cancel">
            <mat-icon aria-label="Cancel">clear</mat-icon>
          </button>
          <button class="onleft-margin"  mat-icon-button (click)="onSave(account)" title="Accept">
            <mat-icon aria-label="Accept">done</mat-icon>
          </button>
        </span>
        
        <span *ngSwitchCase="'edit'">
          <button class="onleft-margin" mat-icon-button (click)="onEdit()" title="Edit">
          <mat-icon aria-label="Edit">mode_edit</mat-icon>
        </button>
      </span>
  
      </span>
    </mat-card-actions>
  </mat-card-header>

  <mat-card-content>
    
    <div class="info">
      <p>Configuration values for availability calculations</p>
    </div>
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="machineAvailability.minAvailability" [disabled]="!editMode" placeholder="Minimum availability" max="100">
      <mat-hint align="end">Minimum percentage that the machine must be available</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="machineAvailability.daysToStart" [disabled]="!editMode" placeholder="Days to start">
      <mat-hint align="end">Time in days to indicate the start of availability calculations</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="machineAvailability.limitAvailabilityTime" [disabled]="!editMode" placeholder="Notification limit" max="100">
      <mat-hint align="end">Minimum percentage to warn of the proximity of machine availability</mat-hint>
    </mat-form-field>
    
  </mat-card-content>

</mat-card>
