import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { App }          from './../app';
import { AppService }   from './../app.service';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.css'],
  providers: [ AppService ]
})
export class AppListComponent implements OnInit {

  apps: App[];
  module: string = 'Account-app';
  fields: Array<any>= [
    { id: 'icon', display: 'icon', header: 'Icon', type: 'icon', sortable: true, filterable: false },
    { id: 'name', display: 'name', header: 'Name', sortable: true, filterable: true },
    { id: 'description', display: 'description', header: 'Description', sortable: true, filterable: true },
    { id: 'index', display: 'index', header: 'Index', sortable: true, filterable: true }
  ];
  canCreate: boolean = false;
  menuTitle: string = 'Apps';

  query: any = {pageSize: 10, pageIndex: 0, sort: null, direction: null, filter: null};

  errorMessage: string;
  private subscription: Subscription = new Subscription();

  constructor (
    private appService: AppService,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authService.getRole().then((role)=>{
      if (role == 1) {
        this.canCreate = true;
      }
      else {
        this.router.navigate(['/']);
      }
    });
  }

  //getApps() {
    //this.appService.getApps()
                      //.subscribe(
                        //apps => this.apps = apps,
                        //error => this.errorMessage = <any>error
                      //);
  //}

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    this.router.navigate(['/apps/new']);
  }

  onSelect(app: App){
    //this.router.navigate(['/entities', app.idApp ]);
    this.router.navigate(['/apps/edit', app.idApp ]);
  }

  onEdit(app: App){
    this.router.navigate(['/apps/edit', app.idApp ]);
  }

  onRemove(app: App){
    this.appService.removeApp(app.idApp)
        .subscribe(
          error => this.errorMessage = <any>error);
  }

  onGetData(){
    this.unsubscribeData();
    this.subscription = this.appService.getApps(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        this.apps = data;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

}
