<mat-card>
    <cardHeader-edit (headerActionEmitter)='onheaderActionEmitter($event)' [module]='module' [editMode]='editMode' [cardHeaderState]='cardHeaderState' [initialCardHeaderState]='initialCardHeaderState'>
    </cardHeader-edit>
    <mat-card-content>
        <mat-form-field>
            <input matInput id="username" required type="email" email [disabled]="!editMode || !showEmail" name="username" placeholder="Email" [(ngModel)]="account.username" #username="ngModel">
        </mat-form-field>

        <mat-form-field>
            <input matInput id="fullname" name="fullname" [disabled]="!editMode" placeholder="Full name" [(ngModel)]="account.fullname" #accountsFullName="ngModel">
        </mat-form-field>

        <mat-form-field>
            <mat-select name="organization" required placeholder="Organization" [disabled]="!editMode || isEndUser" [(ngModel)]="account.idOrganization">
                <mat-option *ngFor="let organization of organizations" [value]="organization.idOrganization" (onSelectionChange)="onChangeOrg($event, organization)">
                    {{ organization.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="isAdmin || isProvider">
            <mat-select name="role" required placeholder="Role" [disabled]="!editMode" [(ngModel)]="account.idRole" >
                <mat-option *ngFor="let role of roles" [value]="role.idRole" (onSelectionChange)="onChangeRole($event, role)">
                    {{ role.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="(isAdmin || isProvider) && operatorAccount">
            <input matInput id="operator" name="operator" [disabled]="!editMode" placeholder="Operator" [(ngModel)]="account.operator" #operator="ngModel">
        </mat-form-field>
      
        <mat-form-field>
            <input matInput id="password" type="password" required [disabled]="!editMode" name="password" placeholder="Password" [(ngModel)]="account.password" #password="ngModel">
        </mat-form-field>

        <mat-form-field>
            <input matInput id="confirmPassword" type="password" required [disabled]="!editMode" name="confirmPassword" placeholder="Confirm Password" [(ngModel)]="confirmPassword" #confirmPassword1="ngModel">
        </mat-form-field>

        <mat-form-field>
            <mat-select name="language" placeholder="Language" [disabled]="!editMode" [(ngModel)]="account.idLanguage">
                <mat-option *ngFor="let language of languages" [value]="language.idLanguage">
                    {{ language.language }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="container-default-display-toggle">
            <mat-slide-toggle class="default-display-toggle" [(ngModel)]="account.defaultDisplayDataCalendar" [disabled]="!editMode">
                <ng-container *ngIf="account.defaultDisplayDataCalendar; else elseTemplate">
                    <span [ngClass]="(editMode) ? 'enable' : 'disabled'">Calendar visualization</span>
                </ng-container>
                <ng-template #elseTemplate>
                    <span [ngClass]="(editMode) ? 'enable' : 'disabled'">Natural visualization</span>
                </ng-template>
            </mat-slide-toggle>
            <mat-icon class="size-28 pointer" [ngClass]="(editMode) ? 'enable' : 'disabled'" (click)="openInfo()">help_outline</mat-icon>
        </div>

    </mat-card-content>

</mat-card>