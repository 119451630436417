<h1 mat-dialog-title>{{metric.metricName}}</h1>
<div mat-dialog-content class="metrics-list-detail">

  <h6>Filter date</h6>
  <div class="date-select">
    <div class="date-start">
        <mat-form-field>
          <input matInput readonly [matDatepicker]="pickerStart" [(ngModel)]="dateStart" (dateChange)="changeDate()" placeholder="Date init" (click)="pickerStart.open()">
          <mat-icon matDatepickerToggleIcon class="pointer" title="Clear" (click)="clearDate('dateStart')">clear</mat-icon>
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="date-end">
        <mat-form-field>
          <input matInput readonly [matDatepicker]="pickerEnd" [(ngModel)]="dateEnd" (dateChange)="changeDate()" placeholder="Date end" (click)="pickerEnd.open()">
          <mat-icon matDatepickerToggleIcon class="pointer" title="Clear" (click)="clearDate('dateEnd')">clear</mat-icon>
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
          <mat-hint *ngIf="!dateCorrect">The end date must be after the start date</mat-hint>
        </mat-form-field>
      </div>
    </div>

  <tableListHttp
  *ngIf="queryDetails"
    [module]='module'
    [fields]='fields'
    [data]='metrics'
    [canCreate]='canCreate'
    [loading]='loading'
    [menuTitle]='menuTitle'
    [query]='queryDetails'
    [insideEntity]='true'
    (tableListEmitter)='onTableListDetailsEmitter($event)'>
  </tableListHttp>
</div>
