import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CalendarService } from '../calendar.service';
import { Subject } from 'rxjs';
import { isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { filter, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDayEditComponent } from '../calendar-day-edit/calendar-day-edit.component';
import { Organization } from '../../organizations/organization';
import { AuthService } from '../../../auth.service';
import { AlertService } from '../../../alert.service';
import { CalendarGeneratorComponent } from '../calendar-generator/calendar-generator.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ShiftService } from '../../shifts/shift.service';
import { Account } from '../../accounts/account';
import { CalendarUtils } from '../../../utils/calendar';
import { HelpCalendarComponent } from '../help-calendar/help-calendar.component';
import { Entity } from '../../entities/entity';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  @Input() organization: Organization;
  @Input() entity: Entity;
  private _editCalendarCheckMachineAvailability: boolean = true;
  @Input() set editCalendarCheckMachineAvailability(value: boolean) {
    this._editCalendarCheckMachineAvailability = value;
    if (this.account) this.checkCanEdit();
  }

  @ViewChild(CalendarGeneratorComponent) calendarGenerator;

  public view: CalendarView = CalendarView.Month;
  public viewDate: Date = new Date();
  public refresh: Subject<any> = new Subject();
  public events: CalendarEvent[];
  public holidays: any[] = [];
  public shifts: any[] = [];
  public account: Account;

  public cardHeaderState: string = "edit";

  public canEdit: boolean = false;

  public showCalendarOrganization: boolean = false;
  
  public date: string = '';
  public firstDayToEdit: string = '';

  constructor(
    private shiftsService: ShiftService,
    private calendarService: CalendarService,
    private alertService: AlertService,
    private authService: AuthService,
    private dialog: MatDialog,
    private calendarUtils: CalendarUtils
  ) { }

  ngOnInit() {
    this.authService.getAccount().subscribe((account) => {
      this.account = account;
      this.checkCanEdit();
    });
    const date = new Date();
    this.onDateChange(date);
  }

  public checkCanEdit() {
    if (
      ((this.entity && this.entity["dataloggerSoftware"].dataloggerHardware.organization.hierarchy.includes('/-'+this.account.idOrganization+'-/')) ||
      (this.organization && this.organization.idOrganization === this.account.idOrganization || this.organization.hierarchy.includes('/-'+this.account.idOrganization+'-/'))) && 
      (this._editCalendarCheckMachineAvailability)
    ) {
      this.canEdit = true;
    }
  }

  private getWorkShifts() {
    this.showCalendarOrganization = false;
    if (this.organization) {
      this.getOrganizationCalendar(this.organization.idOrganization);
    } else if (this.entity) {
      this.getEntityCalendar();
    }
  }

  // get holidays and set color to red
  private async getOrgHolidays() {
    this.holidays = [];
    const idOrganizationsAll = this.events.map(event => event.meta.shift.idOrganization);
    const idOrganizations = _.uniq(idOrganizationsAll);
    for (let i = 0; i < idOrganizations.length; i++) {
      const idOrganization = idOrganizations[i];
      this.getHolidays(idOrganization);
    }
  }

  // get holidays and set color to red
  private getHolidays(idOrganization: number) {
    this.calendarService.getOrgHolidays(
      idOrganization
    ).pipe(filter(ws => !!ws)).subscribe((res: any)=>{
      const holidayOrganization = {
        dates: res.map((h) => h.date),
        idOrganization: idOrganization
      }
      this.holidays.push(holidayOrganization);
    });
  }

  // get calendar and add events 
  private getEntityCalendar() {
    this.calendarService.getEntityWorkedCalendarFromDate(
      this.entity.idEntity,
      this.date
    ).pipe(filter(ws => !!ws)).subscribe(async(res: any)=>{
      this.events = await this.calendarUtils.generateEvents(this.viewDate, res, null, this.entity.idEntity);
      this.setFirstDayToEdit();
      this.getShiftsEntity();
      this.getOrgHolidays();
    });
  }
  
  // get calendar and add events 
  private getOrganizationCalendar(idOrganization) {
    this.calendarService.getOrganizationWorkedCalendarFromDate(
      idOrganization,
      this.date
    ).pipe(filter(ws => !!ws)).subscribe(async(res: any)=>{
      this.events = await this.calendarUtils.generateEvents(this.viewDate, res, idOrganization, null);
      this.setFirstDayToEdit();
      this.getShiftsOrganization(idOrganization);
      this.getOrgHolidays();
    });
  }

  private setFirstDayToEdit() {
    let firstDayToEdit = this.events.find((event) => event['editEvent'] === true);
    let firstDayToEditIndex = this.events.findIndex((event) => event['editEvent'] === true);
    if (firstDayToEditIndex === 0) {
      this.firstDayToEdit = this.date;
    } else if (firstDayToEditIndex === -1) {
      this.firstDayToEdit = moment(this.date, 'YYYYMMDD').add(1, 'month').format('YYYYMMDD');
      this.showCalendarOrganization = true;
    } else {
      this.firstDayToEdit = moment(firstDayToEdit['date'], 'YYYY-MM-DD').format('YYYYMMDD');
      this.showCalendarOrganization = true;
    }
  }

  private getShiftsEntity() {
    this.shiftsService.getEntityShifts(
      this.entity["dataloggerSoftware"].dataloggerHardware.idOrganization,
      this.entity.idEntity
    ).pipe(
      map(res => res.rows)
    ).subscribe((shifts) => {
      this.shifts = shifts;
    })
  }
  
  private getShiftsOrganization(idOrganization) {
    this.shiftsService.getOrganizationShifts(idOrganization, null, null, null, null, null)
    .subscribe( data => {
      for (let i = 0; i < data.rows.length; i++) {
        const row = data.rows[i];
        row['valueStartDayBefore'] = row.startDayBefore ? 'Yes' : 'No';
      }
      this.shifts = data.rows;
    })
  }

  public checkHoliday(day): boolean {
    if (day.events && day.events.length > 0) {
      const idOrganizationEvent = day.events[0].meta.shift.idOrganization;
      const holidayOrganizationEvent = _.find(this.holidays, ['idOrganization', idOrganizationEvent]);
      const dayString = moment(day.date).format('YYYY-MM-DD');
      if (holidayOrganizationEvent.dates.includes(dayString)) {
        return true;
      }
    }
  }

  public checkCanEditDay(day): boolean {
    const selectedDate = moment(day.date).format('YYYYMMDD');
    const editDay = selectedDate >= this.firstDayToEdit;
    return editDay;
  }

  async dayClicked(day) {
   // if user has edit permission (admin and end user)
   if (this.organization) {
    if (this.checkCanEditDay(day)) {
      if (this.canEdit && day.inMonth) {
        const dateString = moment(day.date).format('YYYY-MM-DD');
        const holidayDay = await this.checkHoliday(day);
        if (!holidayDay) {
          this.calendarService.addHoliday({idOrganization: this.organization.idOrganization, date: dateString}).subscribe(
            response => { 
              this.alertService.emitErrorMessage({ text: 'Saved holiday', type: 'info' });
            },
            error => { 
              this.alertService.emitErrorMessage({ text: 'Error trying to save holiday', type: 'info' });
            },
            () => {
                this.reload();
            }
          );
        } else {
          this.calendarService.removeHoliday(
            this.organization.idOrganization,
            dateString
          ).subscribe(
            response => { 
              this.alertService.emitErrorMessage({ text: 'Holiday removed', type: 'info' });
            },
            error => {
              this.alertService.emitErrorMessage({ text: 'Error trying to remove holiday', type: 'info' });
            },
            () => {
              this.reload();
            }
          );
        }
      }
    } else {
      this.alertService.emitErrorMessage({ text: 'The organization calendar is displayed and cannot be edited for a machine. To modify it, access the calendar section in the organization.', type: 'info' });
    }
    }
  }

  handleEvent(day): void {
    if (this.checkCanEditDay(day)) {
      if (isSameMonth(day.date, this.viewDate)) {
        if (this.canEdit) {
          if (this.shifts.length > 0) {
            this.viewDate = day.date;
            const dialogRef = this.dialog.open(CalendarDayEditComponent, {
              width: '350px',
              data: {
                date: day.date,
                organization: (this.organization) ? this.organization.name : undefined,
                workShifts: day.events.map((e) => {
                  return {
                    name: e.meta.shift.name,
                    start: moment(e.start).format('HH:mm:ss'),
                    end: moment(e.end).format('HH:mm:ss'),
                    worked: e.worked,
                    idShift: e.meta.idShift,
                    idEntity: this.entity ? this.entity.idEntity : undefined,
                    idOrganization: this.organization ? this.organization.idOrganization: undefined,
                    idWorkedCalendar: e.meta && e.meta.idWorkedCalendar && !e.espacialDay ? e.meta.idWorkedCalendar : null,
                    date: moment(day.date).format('YYYYMMDD')
                  };
                }),
                shifts: this.shifts.map((shift) => {
                  return {
                    name: shift.name,
                    start: shift.start,
                    end: shift.end,
                    idShift: shift.idShift,
                    idEntity: this.entity ? this.entity.idEntity : undefined,
                    idOrganization: this.organization ? this.organization.idOrganization: undefined,
                    worked: false,
                    date: moment(day.date).format('YYYYMMDD')
                  };
                })
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                if (this.canEdit) {
                  this.onDateChange(this.viewDate);
                } else {
                  this.alertService.emitErrorMessage({ text: 'You can only view the calendar.', type: 'info' });
                }
              }
            });
          } else {
            this.alertService.emitErrorMessage({ text: 'You need to configure shifts from the organization section to configure them in the machine calendar.', type: 'info' });
          }
        } else {
          this.alertService.emitErrorMessage({ text: 'You can only view the calendar.', type: 'info' });
        }
      }
    } else {
      this.alertService.emitErrorMessage({ text: 'The organization calendar is displayed and cannot be edited for a machine. To modify it, access the calendar section in the organization.', type: 'info' });
    }
  }

  onEdit() {
    if (this.shifts.length > 0) {
      this.cardHeaderState = 'new';
    } else {
      this.alertService.emitErrorMessage({ text: 'You need to configure shifts from the organization section to configure them in the machine calendar.', type: 'info' });
    }
  }

  onHelp() {
    const dialogRef = this.dialog.open(HelpCalendarComponent,
      {
      data: {organization: this.organization ? true : false}
      });
  }

  onCancel() {
    this.cardHeaderState = 'edit';
    this.getWorkShifts();
    this.refresh.next();
  }

  // call save function. calendar generator will call reload once finished
  onSave() {
    this.calendarGenerator.submitCalendar();
  }

  // calendar generator will emmit reload once the calendar is saved
  reload() {
    this.cardHeaderState = 'edit';
    this.getWorkShifts();
    this.refresh.next();
  }

  // month/data changed, load new data
  public onDateChange(date) {
    this.date = moment(date).startOf('month').format('YYYYMMDD');
    this.getWorkShifts();
  }

}
