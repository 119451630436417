import { EventEmitter, Injectable } from '@angular/core';
import { environment }  from './../environments/environment';

// import * as Raven from 'raven-js';

@Injectable()
export class AlertService {

  errorEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  emitErrorMessage(message: any) {
    this.errorEmitter.emit(message);

    //Send error to Sentry
    // if (environment.production && message.type == 'danger') { Raven.captureException(message) }

  }

  getErrorEmitter() {
    return this.errorEmitter;
  }
}
