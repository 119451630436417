import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { LoginUserComponent } from './login-user/login-user.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/user', component: LoginUserComponent },
  //{ path: '', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: []
})

export class AppRoutingModule { }
