<div class="mdl-layout__container">
  <div class="mdl-layout mdl-js-layout">
    <!-- <div class="title">
      <h2>Welcome</h2>
      <h3>{{inputEmail}}</h3>
    </div> -->
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">Welcome</mat-card-title>
        <mat-card-subtitle>{{inputEmail}}</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <mat-form-field>
          <input matInput id="inputPassword" [(ngModel)]="inputPassword" placeholder="Enter your password" type="password" (keyup.enter)=login()>
        </mat-form-field>

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="login()">Login</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
