<!--<div class="mdl-layout mdl-js-layout mdl-layout--fixed-drawer-->
            <!--mdl-layout--fixed-header">-->
  <!--<header class="mdl-layout__header" app-header></header>-->
  <!--<div class="mdl-layout__drawer" app-sidebar></div>-->
  <!--<main class="mdl-layout__content">-->
    <!--<div class="mdl-grid page-content">-->
      <!--<router-outlet></router-outlet>-->
      <!--<fab></fab>-->
    <!--</div>-->
  <!--</main>-->
<!--</div>-->


<!--ANGULAR MATERIAL2 ALTERNATIVE-->
<!--<mat-toolbar color="primary">-->
  <!--<div app-header><span>Custom Toolbar</span></div>-->
<!--</mat-toolbar>-->
<!--<mat-sidenav-container class="docs-component-viewer-sidenav-container mat-drawer-container mat-sidenav-container mat-drawer-transition">-->

  <!--<mat-sidenav #sidenav>aaaaa</mat-sidenav>-->

  <!--<mat-sidenav-content>-->
    <!--<button type="button" mat-button (click)="sidenav.open()">-->
      <!--Open sidenav-->
    <!--</button>-->
    <!--<router-outlet></router-outlet>-->
  <!--</mat-sidenav-content>-->

<!--</mat-sidenav-container>-->


<div class="container" [class.is-mobile]="mobileQuery.matches">

  <mat-toolbar class="toolbar">
    <mat-toolbar-row *ngIf="impersonating" class="red-toolbar-row">
        <app-impersonate></app-impersonate>
    </mat-toolbar-row>
    <mat-toolbar-row class="toolbar-row" [style.background-color]="headerColor">
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <h1 class="app-name">Vixion Manager</h1>
      <span *ngIf="showEnvironment">&nbsp; - {{host}}</span>
      <span class="spacer"></span>
      <app-header></app-header>
    </mat-toolbar-row>  
  </mat-toolbar>


  <mat-sidenav-container class="sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? (impersonating ? 82 : 56) : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" 
                (openedChange)="onSidenavChange($event)"
                [opened]="isSidenavOpened"
                 [fixedInViewport]="mobileQuery.matches" [fixedTopGap]="impersonating ? 82 : 56">
      <mat-nav-list>
        <app-sidebar></app-sidebar>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <router-outlet></router-outlet>
      <!-- <fab></fab> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
