<mat-card>
  <cardHeader-edit #header (headerActionEmitter)='onheaderActionEmitter($event)' [module]='module' [editMode]='editMode'
    [initialCardHeaderState]='initialCardHeaderState' [cardHeaderState]='cardHeaderState'>
  </cardHeader-edit>
  <mat-card-content>
    <mat-form-field>
      <input matInput id="name" required name="name" [disabled]="!editMode" placeholder="Name" [(ngModel)]="shift.name"
        #name="ngModel">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="time" [disabled]="!editMode || cardHeaderState !== 'new'" placeholder="Start" name="start" [(ngModel)]="shift.start" (ngModelChange)="onChangeTime()">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="time" [disabled]="!editMode || cardHeaderState !== 'new'" placeholder="End" name="end" [(ngModel)]="shift.end" (ngModelChange)="onChangeTime()">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="color" id="color" name="color" [disabled]="!editMode" placeholder="Color"
      [(ngModel)]="shift.color" #color="ngModel">
    </mat-form-field>
    <mat-checkbox [disabled]="!editMode || !canBeSetStartDayBefore" name="startDayBefore" [(ngModel)]="shift.startDayBefore">Shift starts the previous day.</mat-checkbox>
    <p class="explain" [ngClass]="{'disabled-text': !editMode || !canBeSetStartDayBefore}">Example: The night shift starts the previous day at 10:00 PM and ends at 6:00 AM.</p>
  </mat-card-content>
</mat-card>