import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MachineAvailabilityOptionsEntity } from '../interfaces/machineAvailabilityOptionsEntity';
import { AlertService } from '../../../alert.service';
import { MachineAvailabilityService } from '../services/machine-availability.service';

@Component({
  selector: 'app-machine-availability-form',
  templateUrl: './machine-availability-form.component.html',
  styleUrls: ['./machine-availability-form.component.css']
})
export class MachineAvailabilityFormComponent implements OnInit {
  public cardHeaderState: string = "edit";
  public minimumAvailability: number;
  public editMode: boolean = false;
  public machineAvailability: MachineAvailabilityOptionsEntity;

  constructor(
    private route: ActivatedRoute,
    private machineAvailabilityService: MachineAvailabilityService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    const idEntity = this.route.params['_value'].id;
    this.machineAvailabilityService.get(idEntity).subscribe(
      res => {
        this.machineAvailability = res;
      }, error => {
        if (error.status === 404) this.machineAvailability = new MachineAvailabilityOptionsEntity(null, null, null, idEntity, null);
        if (error.status === 402) this.alertService.emitErrorMessage({text: error.message, type: 'danger'});
      }
    )
  }

  onEdit() {
    this.cardHeaderState = 'new';
    this.editMode = true;
  }
  
  onCancel() {
    this.cardHeaderState = 'edit';
    this.editMode = false;
  }
  
  onSave() {
    if (this.machineAvailability.minAvailability === null) {
      this.alertService.emitErrorMessage({text: 'The Minimum availability field is empty', type: 'danger'});
    } else if (this.machineAvailability.daysToStart === null) {
      this.alertService.emitErrorMessage({text: 'Days to start is empty', type: 'danger'});
    } else if (this.machineAvailability.limitAvailabilityTime === null ) {
      this.alertService.emitErrorMessage({text: 'Notification limit', type: 'danger'});
    } else if (this.machineAvailability.minAvailability > 100) {
      this.alertService.emitErrorMessage({text: 'The Minimum Availability field must be less than 100', type: 'danger'});
    } else if (this.machineAvailability.limitAvailabilityTime > 100) {
      this.alertService.emitErrorMessage({text: 'Notification limit must be less than 100', type: 'danger'});
    } else {
      this.cardHeaderState = 'edit';
      this.editMode = false;
      if (this.machineAvailability.idMachineAvailabilityOptionsEntity === null) {
        this.machineAvailabilityService.newMachineAvailability(this.machineAvailability).subscribe(
          res => {
            this.machineAvailability = res;
            this.alertService.emitErrorMessage({text: 'Saved!', type: 'info'});
          }, error => {
            if (error.status === 402) {
              this.alertService.emitErrorMessage({text: error.message, type: 'danger'});
            }else {
              this.alertService.emitErrorMessage({text: 'Not saved!', type: 'danger'});
            }
          }
        )
      } else {
        this.machineAvailabilityService.updateMachineAvailability(this.machineAvailability).subscribe(
          res => {
            this.alertService.emitErrorMessage({text: 'Saved!', type: 'info'});
          }, error => {
            if (error.status === 402) {
              this.alertService.emitErrorMessage({text: error.message, type: 'danger'});
            }else {
              this.alertService.emitErrorMessage({text: 'Not saved!', type: 'danger'});
            }
          }
        )
      }
    }
  }
}
