<h1 mat-dialog-title style="margin-bottom: 1rem; font-size: 2rem; font-weight: bold;">The following signals are configured in the reports</h1>
<mat-dialog-content>
  <ng-container>
    <div class="report-data" *ngFor="let report of data">
      <h4>Report: {{report.nameReport}}</h4>
      <p><strong>Signals</strong></p>
      <ul>
        <li *ngFor="let variable of report.variables">{{variable.prettyName}}</li>
      </ul> 
    </div>
    <div class="buttons">
        <button mat-raised-button color="primary" class="next" (click)="remove()">Continue</button>
        <button mat-raised-button color="secondary" class="cancel" (click)="close()">Cancel</button>
    </div>
  </ng-container>
</mat-dialog-content>