<mat-card>
  <!-- <cardHeader-edit (headerActionEmitter)='onheaderActionEmitter($event)' [module]='module' [editMode]='editMode' [cardHeaderState]='cardHeaderState'>
  </cardHeader-edit> -->
  <mat-card-content>
    <table style="width:100%; border-spacing:0 15px">
      <tr>
       <th *ngFor="let col of displayedColumns" >
        {{col}}
      </th>
      </tr>
      <tr *ngFor="let masterSetting of masterSettings" >
        <td style="text-align: center;">
        <mat-checkbox color="primary" [disabled]="!canCreate" [(ngModel)]="masterSetting.enabled" (change)="onGroupsChange($event, masterSetting.idMasterSetting)"></mat-checkbox>
        </td>
        <td [ngClass]="(masterSetting.enabled) ? '' : 'disabled'" >
          {{masterSetting.description}}
        </td>
        <td style="text-align: center;">
          <span *ngIf="masterSetting.enabled" style="width:100%; !important">
            <span *ngIf="masterSetting.type == 'boolean'">
              <mat-slide-toggle
                [disabled]="!canCreate"
                [(ngModel)]="masterSetting.value">
              </mat-slide-toggle>
            </span>
            <span *ngIf="masterSetting.type == 'object'">
              <mat-form-field style="width:100%; !important">
                <textarea matInput
                        [disabled]="!canCreate"
                        matTextareaAutosize
                        matAutosizeMaxRows="6"
                       [(ngModel)]="masterSetting.value">
                       </textarea>
              </mat-form-field>
            </span>
            <span *ngIf="masterSetting.type == 'integer'">
              <mat-form-field style="width:100%; !important">
                <input matInput
                       [disabled]="!canCreate"
                       type="number"
                       [(ngModel)]="masterSetting.value">
              </mat-form-field>
            </span>
          </span>
        </td>
      </tr>
    </table>

    <hr>

    <!-- <div *ngFor="let masterSetting of masterSettings" style="padding: 10px">
      <span >
        <mat-checkbox labelPosition="before" [(ngModel)]="masterSetting.enabled" (change)="onGroupsChange($event, masterSetting.idMasterSetting)">Enabled</mat-checkbox>
      </span>

      <span style="padding: 10px">
          {{masterSetting.description}}
      </span>

      <span *ngIf="masterSetting.enabled" style="padding: 10px">
        <span *ngIf="masterSetting.type == 'boolean'">
          <mat-slide-toggle
            [(ngModel)]="masterSetting.value">
            <i>Value</i>
          </mat-slide-toggle>
        </span>
        <span *ngIf="masterSetting.type == 'object'">
          <mat-form-field>
            <input matInput
                   style="width:100%; !important"
                   [(ngModel)]="masterSetting.value"
                   placeholder="Value"
                   [disabled]="!canCreate">
          </mat-form-field>
        </span>
        <span *ngIf="masterSetting.type == 'integer'">
          <mat-form-field>
            <input matInput
                   style="width:100%; !important"
                   placeholder="Value"
                   type="number"
                   [(ngModel)]="masterSetting.value">
          </mat-form-field>
        </span>
      </span>
    </div> -->

    <!-- <hr> <hr>
    <mat-selection-list #shoes (selectionChange)="onGroupsChange($event.option)">
      <mat-list-option checkboxPosition="before" disableRipple=true [selected]="masterSetting.enabled" [value]="masterSetting.idMasterSetting"  *ngFor="let masterSetting of masterSettings">
        <span>
          <p>{{masterSetting.description}}</p>
        </span>
        <span *ngIf="masterSetting.enabled">
          wefwef
          <span *ngIf="masterSetting.type == 'boolean'">
            <mat-slide-toggle style="width:100%; !important"
              [(ngModel)]="masterSetting.value">
              <i>value</i>
            </mat-slide-toggle>
          </span>
          <span *ngIf="masterSetting.type == 'object'">
            <mat-form-field>
              <input matInput
                     style="width:100% !important"
                     [(ngModel)]="masterSetting.value"
                     placeholder="value"
                     [disabled]="!canCreate">
            </mat-form-field>
          </span>
          <span *ngIf="masterSetting.type == 'integer'">
            <mat-form-field>
              <input matInput
                     title={{masterSetting.description}}
                     alt={{masterSetting.description}}
                     placeholder="value"
                     type="number"
                     [(ngModel)]="masterSetting.value">
            </mat-form-field>
          </span>
        </span>
      </mat-list-option>
    </mat-selection-list>
    <hr>
    <hr> -->
    <!-- <span *ngFor="let field of masterSettings">
      <span *ngIf="field.visible == true">
        <span *ngIf="field.type == 'boolean' ; then slideToggle"></span>
        <ng-template #slideToggle>
            <mat-slide-toggle style="width:100%; !important"
              [(ngModel)]="field.value"
              [disabled]="!canCreate">
              <i>{{field.description}}</i>
            </mat-slide-toggle>
        </ng-template>
      </span>
    </span>
    <hr>
    <mat-slide-toggle style="width:100%; !important"
      [(ngModel)]="isPalletized"
      (change)="isPallet($event)"
      [disabled]="!canCreate">
      <i>Does your machine handle pallets?</i>
    </mat-slide-toggle>
    <span *ngFor="let field of masterSettings">
      <span *ngIf="field.visible == true">
        <span *ngIf="field.type != 'boolean' ; then input2"></span>
        <ng-template #input2>
          <mat-form-field [hidden]="!field.visible">
              <div [ngSwitch]="field.type">
                <input matInput
                       *ngSwitchCase="'object'"
                       style="width:100% !important"
                       id={{field.key}}
                       placeholder={{field.description}}
                       [(ngModel)]="field.value"
                       [disabled]="!canCreate">
               <input matInput
               title={{field.description}}
               alt={{field.description}}
                      *ngSwitchCase="'integer'"
                      type="number"
                      id={{field.key}}
                      placeholder={{field.description}}
                      [(ngModel)]="field.value"
                      [disabled]="!canCreate">
              </div>
          </mat-form-field>
        </ng-template>
      </span>
    </span> -->

    <div class="fixedHeight">
      <button mat-raised-button color="primary"
      (click)="onSave()" [disabled]="!canCreate">
        Apply settings</button>
    </div>
    <!-- <span *ngFor="let field of masterSettings" >
      <span *ngIf="field.type == 'boolean' ; then slideToggle else input"></span>
      <ng-template #input>
        <mat-form-field>
          <div [ngSwitch]="field.type">
            <input matInput *ngSwitchCase="'integer'"
                   id={{field.name}}
                   name={{field.name}}
                   type="number"
                   placeholder={{field.name}}
                   [(ngModel)]="field.value">
            <input *ngSwitchDefault  matInput
                   id={{field.name}}
                   name={{field.name}}
                   placeholder={{field.description}}
                   [(ngModel)]="field.value">
          </div>
        </mat-form-field>
      </ng-template>
      <ng-template #slideToggle>
          <mat-slide-toggle style="width:100% !important"
            [(ngModel)]="field.value"
            id={{field.name}}>
            <i>{{field.description}}</i>
          </mat-slide-toggle>
      </ng-template> -->
  </mat-card-content>

</mat-card>
