import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }  from '@angular/forms';

import { MainRoutingModule }              from './main-routing.module';
import { MainComponent }                  from './main.component';
import { MainService }                    from './main.service';
import { HeaderComponent }                from './header/header.component';
import { SidebarComponent }               from './sidebar/sidebar.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { FabComponent }                   from './fab/fab.component';
import { AppsModule }                     from './apps/apps.module';
import { Angulartics2Module } from 'angulartics2';
import { AppService } from './apps/app.service';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfigTypesService } from '../services/configTypes.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MainRoutingModule,
    AppsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatGridListModule,
    MatListModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    Angulartics2Module.forRoot(),
    SharedModule
  ],
  declarations: [
    MainComponent,
    HeaderComponent,
    ImpersonateComponent,
    SidebarComponent,
    FabComponent
  ],
  providers: [
    MainService,
    AppService,
    ConfigTypesService
  ]
})
export class MainModule {}
