import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  // Counter for active requests
  private requestCount = 0;
  private hideDelay = 300; // milliseconds delay

  /**
   * Call this method when starting a request.
   */
  show(url: string): void {
    if (!url.includes('dataloggerHardware/conectivityStatus') &&
      !url.includes('entity/realTimeSignalValues')) {
      this.requestCount++;
    }
    // Only set to true if it is not currently active
    if (!this.loadingSubject.getValue()) {
      // console.log('show');
      this.loadingSubject.next(true);
    }
  }

  /**
   * Call this method when finishing a request.
   */
  hide(): void {
    if (this.requestCount > 0) {
      this.requestCount--;
    }
    // If there are no more active requests, wait a delay to hide
    if (this.requestCount === 0) {
      setTimeout(() => {
        // If no other request started in that time
        if (this.requestCount === 0) {
          // console.log('hide');
          this.loadingSubject.next(false);
        }
      }, this.hideDelay);
    }
  }

  // Optional: Method to reset the counter in case of global errors or logout
  reset(): void {
    this.requestCount = 0;
    this.loadingSubject.next(false);
  }
}
