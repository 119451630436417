import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { NotificationRule } from './../notificationRule';
import { NotificationRuleService } from './../notificationRule.service';
import { AuthService } from '../../../auth.service';
import { Entity } from '../../entities/entity';
import { Subscription } from 'rxjs';

@Component({
  selector: 'notificationRule-list',
  templateUrl: './notificationRule-list.component.html',
  styleUrls: ['./notificationRule-list.component.css'],
  providers: [ NotificationRuleService ]
})

export class NotificationRuleListComponent implements OnInit {

  notificationRules: NotificationRule[];
  module: string = 'Notification manager';
  // table_headers: Array<string>= ['Name', 'Units'];
  fields: Array<any>= [
    { id: 'entity.dataloggerSoftware.dataloggerHardware.organization.name', display: 'entity.dataloggerSoftware.dataloggerHardware.organization', header: 'Machine owner', type: 'image', sortable: true, filterable: true },
    { id: 'entity.name', display: 'entity.name', header: 'Machine name', sortable: true, filterable: true },
    { id: 'name', display: 'name', header: 'Rule name', sortable: true, filterable: true },
    { id: 'severity', display: 'severity', header: 'Severity', sortable: true, filterable: true },
    { id: 'type', display: 'type', header: 'Type', sortable: true, filterable: true },
    { id: 'duration', display: 'duration', header: 'Duration (in seconds)', sortable: true, filterable: true },
  ];
  menuTitle: string = 'Notification rules';
  insideEntity: boolean = false;
  loading: boolean = true;

  query: any = {pageSize: 10, pageIndex: 0, sort: 'name', direction: 'asc', filter: null};

  entity: Entity;

  isAdmin: boolean = false;
  canCreate: boolean = false;
  errorMessage: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private notificationRuleService: NotificationRuleService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { entity: Entity }) => {
        if(data.entity) {
          this.entity = data.entity;
          this.insideEntity = true;
          this.canCreate = true;
          this.fields = [
            { id: 'name', display: 'name', header: 'Rule name', sortable: true, filterable: true },
            { id: 'severity', display: 'severity', header: 'Severity', sortable: true, filterable: true },
            { id: 'type', display: 'type', header: 'Type', sortable: true, filterable: true },
            { id: 'duration', display: 'duration', header: 'Duration (in seconds)', sortable: true, filterable: true },
          ];
        }
      });
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    if (this.entity) {
      this.router.navigate(['/notificationRules/new'], { queryParams: { entity: this.entity.idEntity } });
    } else {
      this.router.navigate(['/notificationRules/new']);
    }
  }

  onSelect(notificationRule: NotificationRule){
    this.router.navigate(['/notificationRules/edit',notificationRule.idNotificationRule ]);
  }

  onEdit(notificationRule: NotificationRule){
    this.router.navigate(['/notificationRules/edit',notificationRule.idNotificationRule ]);
  }

  onRemove(notificationRule: NotificationRule){
    this.notificationRuleService.removeNotificationRule(notificationRule.idNotificationRule)
        .subscribe(
          error => this.errorMessage = <any>error);
  }

  onGetData(){
    this.loading = true;
    this.unsubscribeData();
    if (this.entity) {
      this.loadEntityNotificationRules();
    } else {
      this.loadAllNotificationRules();
    }
  }

  loadEntityNotificationRules(){
    this.loading = true;
    this.subscription = this.notificationRuleService.getEntityNotificationRules(this.entity.idEntity.toString())
      .subscribe( data => {
        this.notificationRules = data;
        this.loading = false;
      })
  }

  loadAllNotificationRules(){
    this.loading = true;
    this.subscription = this.notificationRuleService.getNotificationRules(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        this.notificationRules = data;
        this.loading = false;
        this.notificationRules = data;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }
  
  private unsubscribeData() {
    if (this.subscription != null) {
        this.subscription.unsubscribe();
    }
  }

}
