<div class="datasource-list">
  <tableListHttp
    [module]='module'
    [fields]='fields'
    [data]='datasources'
    [canCreate]='canCreate'
    [loading]='loading'
    [menuTitle]='menuTitle'
    [query]='query'
    (tableListEmitter)='onTableListEmitter($event)'
    [insideEntity]='insideEntity'>
  </tableListHttp>
  <div class="explain" *ngIf="mainDatasourceInDatasources">
    <p>* Main datasource</p>
  </div>
</div>
