<tableListHttp
  [module]='module'
  [fields]='fields'
  [data]='apps'
  [canCreate]=true
  [loading]='loading'
  [menuTitle]='menuTitle'
  [query]='query'
  (tableListEmitter)='onTableListEmitter($event)'>
</tableListHttp>

