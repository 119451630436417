import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: any, formatOut: any, utc = false, formatIn: any = ''): any {
    let dateFormat;
    if (utc) {
      const offset = moment().utcOffset();
      dateFormat = moment(date).utcOffset(offset).format(formatOut);
    } else {
      dateFormat = moment(date, formatIn).format(formatOut);
    }
    return dateFormat;
  }

}
