import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment }      from './../environments/environment';
import { AppComponent }     from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard }        from './auth-guard.service';
import { AlertService }     from './alert.service';
import { AuthService }      from './auth.service';
import { LoginComponent }   from './login/login.component';
import { LoginUserComponent } from './login-user/login-user.component';
import { FooterComponent }  from './footer/footer.component';
import { MainModule }       from './main/main.module';
import * as process from 'process';
window['process'] = process;
import { QueryStore } from './main/shared/store/query.store';
import { TabStore } from './main/shared/store/tab.store';
import { HttpConfigInterceptor } from './interceptors/http-interceptor';
import { ErrorHttpConfigInterceptor } from './interceptors/error-http-interceptor';
import { SharedModule } from './main/shared/shared.module';

@Injectable()
export class VixionErrorHandler implements ErrorHandler {
  handleError(err:any) : void {
    if (!environment.production) {
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginUserComponent,
    FooterComponent,
  ],
  imports: [
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSnackBarModule,
    MainModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: VixionErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard,
    AuthService,
    AlertService,
    QueryStore,
    TabStore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHttpConfigInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
