<mat-card-header>
  <mat-card-actions>
    <span [ngSwitch]="cardHeaderState">
      <span *ngSwitchCase="'new'">
        <button class="onleft-margin"  mat-icon-button (click)="onCancel()" title="Cancel">
          <mat-icon aria-label="Cancel">clear</mat-icon>
        </button>
        <button class="onleft-margin"  mat-icon-button (click)="onSave(account)" title="Accept">
          <mat-icon aria-label="Accept">done</mat-icon>
        </button>
      </span>

      <span *ngSwitchCase="'edit'">
        <button class="onleft-margin" mat-icon-button *ngIf="canEdit" (click)="onEdit()" title="Edit">
          <mat-icon aria-label="Edit">mode_edit</mat-icon>
        </button>
      </span>
    </span>
    <span>
      <button class="onleft-margin" mat-icon-button (click)="onHelp()" title="Help">
        <mat-icon aria-label="Help" class="calendar-help">help</mat-icon>
      </button>
    </span>
  </mat-card-actions>
</mat-card-header>

<div [ngSwitch]="cardHeaderState">
  <div  *ngSwitchCase="'new'">
    <calendar-generator 
      #calendarGenerator 
      [entity]="entity"
      [organization]="organization"
      [shifts]="shifts"
      (reload)='reload()'>
    </calendar-generator>
  </div>
  <div *ngSwitchCase="'edit'">
    <div class="row text-center">
      <div class="calendar-header">
        <button
          mat-stroked-button
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="onDateChange($event)">
          Today
        </button>
        <button
          mat-icon-button
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="onDateChange($event)">
          <mat-icon aria-label="Previous">keyboard_arrow_left</mat-icon>
        </button>
        <button
          mat-icon-button
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="onDateChange($event)">
          <mat-icon aria-label="Next">keyboard_arrow_right</mat-icon>
        </button>
        <div class="calendar-title">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</div>
        <div class="global-calendar-title" *ngIf="showCalendarOrganization">Organization calendar</div>
      </div>
    </div>
    <br />
    
    <mwl-calendar-month-view
      weekStartsOn="1"
      [cellTemplate]="customCellTemplate"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      >
    </mwl-calendar-month-view>
    
    
    <ng-template #customCellTemplate let-day="day" let-locale="locale">
      <div class="day-content"
        [ngClass]="{
          'day-content-isWeekend': day.isWeekend && day.inMonth,
          'day-content-holiday': checkHoliday(day) && day.inMonth,
          'day-content-past-or-future-month': !day.inMonth,
          'day-content-all': day.events.length === 0
        }" 
      >
        <div 
          class="header-day-calendar" 
          [ngClass]="{
            'header-day-calendar-disabled': entity || !checkCanEditDay(day),
            'header-day-calendar-today': day.isToday,
            'header-day-calendar-all': day.events.length === 0
          }" 
          [title]="'Enable or disable holidays'"
          (click)="dayClicked(day)" 
        >
          <div class="dot" *ngIf="!checkCanEditDay(day)"></div>
          <div class="day-calendar">
            <span class="day-calendar-number">
              {{ day.date | calendarDate:'monthViewDayNumber':locale }}
            </span>
          </div>
        </div>
        <div 
          class="body-day-calendar" 
          [ngClass]="{'body-day-calendar-disabled': !checkCanEditDay(day)}"
          (click)="handleEvent(day)" 
        >
          <!-- <div *ngFor="let event of day.events" class="event" [ngClass]="{'no-worked': !event.worked}"> -->
          <div *ngFor="let event of day.events" class="event" [ngClass]="{'no-worked': !event.worked}" [style.background-color]="event.color.primary">
            <!-- <span class="dot" [style.background-color]="event.color.primary"></span> -->
            <span class="text">{{event.title}}</span>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="legend">
      <h6 class="title">Legend</h6>
      <div class="weekend">
        <span class="dot"></span>
        <span class="text">Weekend</span>
      </div>
      <div class="holiday">
        <span class="dot"></span>
        <span class="text">Holiday</span>
      </div>
    </div>
  </div>
</div>