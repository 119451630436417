import { Injectable }             from '@angular/core';
import { Router, Resolve,
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs';

import { Language }          from './../language';
import { LanguageService }   from './../language.service';

@Injectable()
export class LanguageListResolver implements Resolve<Language[]> {
  constructor(private languageService: LanguageService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Language[]> {
    return this.languageService.getLanguages()
  }
}
