<a mat-list-item
   [ngClass]="{'selected': url.startsWith('/accounts/profile')}"
   [style.border-left-color]="url.startsWith('/accounts/profile') ? leftColor : null"
   routerLink="/accounts/profile/{{account.idAccount}}" routerLinkActive="active"
   (click)="onSidebarClick()">
   <span class="pe pe-7s-user pe-3x" aria-label="My account"></span>
  <!-- <mat-icon class="size-42" aria-label="person outline">person_outline</mat-icon> -->
  <span class="menu-item-space"> My account</span>
</a>
<hr/>
<span class="title">Assets and rights</span>
<div *ngFor="let navItem of navList">
  <a mat-list-item
  *ngIf="navItem.minimumRoleWeight >= account.role.weight && navItem.exclusiveUse === false"
     [ngClass]="{'selected': isActive(navItem)}"
     [style.border-left-color]="isActive(navItem) ? leftColor : null"
     [routerLink]="navItem.link" routerLinkActive="active"
     (click)="onSidebarClick()">
    <mat-icon *ngIf="navItem.matIcon" class="mat-icon material-icons size-42">{{navItem.matIcon}}</mat-icon>
    <span *ngIf="navItem.icon" [class]="navItem.icon"></span>
    <img *ngIf="navItem.iconSvg" src="assets/icons/{{navItem.iconSvg}}" alt="calendar">
    <span class="menu-item-space">  {{navItem.heading}}</span>
  </a>
</div>
<hr/>
<div *ngFor="let navItem of extraNavList">
  <mat-accordion>
    <div class="nav-head" *ngIf="(navItem.pages.length === 0) && (navItem.minimumRoleWeight >= account.role.weight)">
      <!-- use a simple div for an item that has no children,
        match up the styling to the expansion panel styles -->
      <a mat-list-item
      *ngIf="navItem.minimumRoleWeight >= account.role.weight"
         [ngClass]="{'selected': isActive(navItem)}"
         [style.border-left-color]="isActive(navItem) ? leftColor : null"
         [routerLink]="navItem.link" routerLinkActive="active"
         (click)="onSidebarClick()">
        <mat-icon *ngIf="navItem.matIcon" class="size-42">{{navItem.matIcon}}</mat-icon>
        <span [class]="navItem.icon"></span><span class="menu-item-space">  {{navItem.heading}}</span>
      </a>
    </div>

    <!-- use expansion panel for heading item with sub page links -->
    <mat-expansion-panel *ngIf="(navItem.pages.length > 0) && (navItem.minimumRoleWeight >= account.role.weight)"
        class="mat-elevation-z0 expansion-panel" [expanded]="hasChildActive(navItem)">
        <mat-expansion-panel-header
        [ngClass]="{'selected-expansion': hasChildActive(navItem)}"
        [style.border-left-color]="hasChildActive(navItem) ? leftColor : null"
        [expandedHeight]="'48px'">
          <mat-panel-title class="nav-link">
          <mat-icon *ngIf="navItem.matIcon" class="size-42">{{navItem.matIcon}}</mat-icon>
          <span [class]="navItem.icon"></span><span class="menu-expansion-item-space">  {{navItem.heading}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="nav-section">
          <!-- loop through all your sub pages inside the expansion panel content -->
          <div *ngFor="let navPage of navItem.pages"
            class="nav-item">
            <a mat-list-item *ngIf="navPage.minimumRoleWeight >= account.role.weight"
              [ngClass]="{'selected': isActive(navPage)}"
              [style.border-left-color]="isActive(navPage) ? leftColor : null"
              [routerLink]="navPage.link" routerLinkActive="active"
              (click)="onSidebarClick()">
              <mat-icon *ngIf="navItem.matIcon" class="size-42">{{navItem.matIcon}}</mat-icon>
              <span [class]="navPage.icon"></span><span class="menu-item-space">  {{navPage.title}}</span>
            </a>
          </div>
        </div>
      </mat-expansion-panel>
  </mat-accordion>
</div>
