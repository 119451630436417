import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Language }          from './../language';
import { LanguageService }   from './../language.service';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs';

declare var _:any;
declare var $:any;

@Component({
  selector: 'language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.css'],
  providers: [ LanguageService ]
})
export class LanguageListComponent implements OnInit {
  errorMessage: string;
  languages: Language[];
  module: string = 'Language';
  filter_field: string = 'roleName';
  table_headers: Array<string>= ['Name', 'Description'];
  fields: Array<string>= ['name', 'description'];
  roles: Array<string>;
  private subscription: Subscription = new Subscription();

  constructor (
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getRoles();
  }

  getRoles() {
    this.authService.getRole().then((role) => {
      if ( role != 1) {
        this.router.navigate(['/']);
      }
    });
    this.unsubscribeData();
    this.subscription = this.languageService.getLanguages()
                        .subscribe(
                          roles => this.languages = roles,
                          error =>  this.errorMessage = <any>error);
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
    }
  }

  onNew(){
    // this.router.navigate(['/roles/new']);
  }

  onSelect(language: Language){
    // this.router.navigate(['/roles/edit', role.idRole ]);
  }

  onEdit(language: Language){
    // this.router.navigate(['/roles/edit', role.idRole ]);
  }

  onRemove(language: Language){
    // this.languageService.removeRole(role.idRole)
    //     .subscribe(
    //       error => this.errorMessage = <any>error);
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

}
