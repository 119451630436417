import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatGridListModule } from '@angular/material/grid-list';

import { TableListComponent, DialogComponent3 } from './tableList/tableList.component';

import { TableListHttpComponent, DialogComponent2 } from './tableListHttp/tableListHttp.component';
import { ExpandableTableListHttpComponent } from './expandableTableListHttp/expandableTableListHttp.component';

// import { DialogComponent }    from './dialog/dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardHeaderEditComponent,
         DialogComponent }    from './cardHeader-edit/cardHeader-edit.component';
import { MapDialogComponent } from '../entities/entity-edit/entity-edit.component';
import { MapDialogComponent2 } from '../entities/entity-new/entity-new.component';
import { MessageRemoveBBJVariablesComponent } from './message-remove-bbjvariables/message-remove-bbjvariables.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatGridListModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTooltipModule,
    MatChipsModule,
    MatExpansionModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    TableListComponent,
    TableListHttpComponent,
    CardHeaderEditComponent,
    ExpandableTableListHttpComponent,
    DialogComponent,
    DialogComponent2,
    DialogComponent3,
    MapDialogComponent,
    MapDialogComponent2,
    MessageRemoveBBJVariablesComponent
  ],
  entryComponents: [ DialogComponent, DialogComponent2, DialogComponent3, MapDialogComponent, MapDialogComponent2],
  providers: [],
  exports: [
    TableListComponent,
    TableListHttpComponent,
    ExpandableTableListHttpComponent,
    // DialogComponent,
    CardHeaderEditComponent,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatGridListModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTooltipModule,
    MatChipsModule,
    MatExpansionModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ]
})

export class SharedModule {}
