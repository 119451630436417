<h1 mat-dialog-title>Calendar Settings</h1>
<div mat-dialog-content>
   <div class="intro">
      <h6 class="title">Introduction</h6>
      <p *ngIf="data.organization">The calendar allows for efficient shift scheduling management within each organization, ensuring proper and organized workday planning.</p>
      <p *ngIf="!data.organization">The calendar allows for efficient shift scheduling management within each machine, ensuring proper and organized workday planning.</p>
      <p>This will then allow us to view the data within the time slot of each shift.</p>
      <p *ngIf="data.organization">If the organization does not have a configured calendar, a default calendar will be automatically assigned, covering the entire day with a single 24-hour shift, from Monday to Sunday.</p>
      <p *ngIf="data.organization">This default calendar cannot be modified and is only available for viewing.</p>

      <p *ngIf="!data.organization">If a machine does not have a configured calendar, it will automatically inherit the calendar of the organization it belongs to. If the organization also does not have a defined calendar, both the machine and the organization will inherit a default calendar that covers the entire day with a single 24-hour shift, configured from Monday to Sunday.</p>
      <p *ngIf="!data.organization">Inherited calendars from an organization cannot be modified on a machine and are only available for viewing.</p>
   </div>

   <div class="shifts">
      <h6 class="title">Shift Configuration</h6>
      <p>
         The first step to configuring our own calendar is to go to the organization settings in the sidebar menu, access the organization, and enter the shifts tab to configure the different shifts worked within the organization.
      </p>
      <p>
         An example could be:
      </p>
      <ul>
         <li>Morning shift (06:00 - 14:00)</li>
         <li>Afternoon shift (14:00 - 22:00)</li>
         <li>Night shift (22:00 - 06:00)</li>
      </ul>
   </div>

   <div class="calendar-shifts">
      <h6 class="title">Add Shifts to the Calendar</h6>
      <p *ngIf="data.organization">Without leaving the organization settings.</p>
      <p *ngIf="!data.organization">With the shifts configured in the organization, we will navigate to the machines from the sidebar menu and select the machine on which we want to configure its own calendar.</p>
      <p>We will go to the calendar tab and click on the top pencil icon, which will open a pop-up window where we can configure the shifts worked each day throughout the week.</p>
      <p>An example could be the 3 shifts configured in the previous section from Monday to Friday. To do this, we will need to check the corresponding boxes.</p>
   </div>
   
   <div class="calendar-config">
      <h6 class="title">Calendar Settings</h6>
      <p>As we can see, once the shifts and the days they occur are defined, the calendar will automatically fill in with the established configuration from the current day.</p>
      
      <p *ngIf="data.organization">If we scroll through the calendar, we can observe two marked areas for each day of the calendar.</p>
      <ul>
         <li *ngIf="data.organization">
            <p>If we click on the header (Day of the month), it will allow us to indicate that this day is a holiday in our organization and will disable the shifts.</p>
            <p>This action can be performed on both past and future days, and machines with or without a calendar will inherit these holidays.</p>
         </li>
         <li>If we click on the area with the configured shifts, a pop-up window will open:
            <ul>
               <li>
                  <p>If we clicked on a day with the default configured shifts, the pop-up window will display the configured shifts and allow us to disable one or more of the configured shifts and reactivate them if needed.</p>
                  <p>This action can be performed on both past and future days.</p>
               </li>
               <li>
                  <p>If we perform the same action on a day that has not had shifts configured by default, it will allow us to add one or more shifts exclusively for that day. This enables us to set up a special workday.</p>
                  <p>This action can only be performed on future days, as prior programming is necessary to obtain the data based on the specified parameters.</p>
               </li>
            </ul>
         </li>
      </ul>
   </div>

</div>
