import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs';

import { App }          from './../app';
import { AppService }   from './../app.service';


@Injectable()
export class AppEditResolver implements Resolve<App> {
  constructor(private appService: AppService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<App> {
    return this.appService.getApp(route.params['id'])
  }
}
