import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { NotificationRule }      from './notificationRule';
import { Channel } from "./channel";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class NotificationRuleService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all notificationRules
   */
  public getNotificationRules(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<NotificationRule[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<NotificationRule[]>(environment.api_url + '/notificationRules', { params: queryParams });
  };
  /**
   * Retrieves notificationRule details by a given id
   *
   * @param idNotificationRule
   */
  public getNotificationRule(idNotificationRule: string): Observable<NotificationRule> { 
    return this.http.get<NotificationRule>(environment.api_url + '/notificationRule/' + idNotificationRule);
  };

  /**
   * Save notificationRule
   *
   * @param notificationRule
   */
  public saveNotificationRule(notificationRule: NotificationRule): Observable<any> { 
    if (notificationRule.idNotificationRule) {
      return this.updateNotificationRule(notificationRule);
    } else {
      return this.newNotificationRule(notificationRule);
    }
    //

  };

  /**
   * Create new notificationRule
   *
   * @param notificationRule
   */
  public newNotificationRule(notificationRule: NotificationRule): Observable<any> { 
    return this.http.post(environment.api_url + '/notificationRule', notificationRule);
  };
  
  /**
   * Update notificationRule
   *
   * @param notificationRule
   */
  public updateNotificationRule(notificationRule: NotificationRule): Observable<any> { 
    return this.http.put(environment.api_url + '/notificationRule', notificationRule);
  };
  
  /**
   * Delete an notificationRule by a given id
   *
   * @param idNotificationRule 
   */
  public removeNotificationRule (idNotificationRule: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/notificationRule/' + idNotificationRule);
  }


  /**
   * Retrieves entity's notificationRules
   *
   * @param idEntity
   */
  public getEntityNotificationRules(idEntity: string): Observable<NotificationRule[]> { 
    return this.http.get<NotificationRule[]>(environment.api_url + '/notificationRules/entity/' + idEntity);
  };
  

  /**
   * Retrieves channels
   *
   */
  public getChannels(): Observable<Channel[]> { 
    return this.http.get<Channel[]>(environment.api_url + '/channels');
  };
    
}
