<mat-card>
  <!-- <mat-card-header>
    <div mat-card-avatar class="pe-7s-account pe-3x"></div>
    <mat-card-title>Data</mat-card-title>
    <button mat-raised-button (click)="openDialog()">DELETE</button>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <input matInput
             id="name"
             required
             name="name"
             placeholder="Name"
             [(ngModel)]="role.name"
             #name="ngModel">
    </mat-form-field>
    <mat-form-field>
      <input matInput
             id="description"
             name="description"
             placeholder="Description"
             [(ngModel)]="role.description"
             #description="ngModel">
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="onCancel()">CANCEL</button>
    <button mat-raised-button (click)="onSave(role)">SAVE</button>
  </mat-card-actions> -->
</mat-card>
