<tableListHttp
  [module]='module'
  [fields]='fields'
  [data]='shifts'
  [canCreate]=true
  
  [menuTitle]='menuTitle'
  [query]='query'
  [insideEntity]='insideEntity'
  (tableListEmitter)='onTableListEmitter($event)'>
</tableListHttp>
