import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }        from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';

import { AppsRoutingModule }  from './apps-routing.module';
import { AppListComponent }   from './app-list/app-list.component';
import { AppEditComponent }   from './app-edit/app-edit.component';

import { AppService }         from './app.service';
// import { DialogComponent }    from './app-edit/app-edit.component';

import { AccountsModule }     from './../accounts/accounts.module';

import { SharedModule }       from './../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    AppsRoutingModule,
    AccountsModule,
    SharedModule
  ],
  entryComponents: [
    // DialogComponent
  ],
  declarations: [
    AppListComponent,
    AppEditComponent
    //,
    // DialogComponent
  ],
  providers: [ 
    AppService
  ]
})
export class AppsModule { }
