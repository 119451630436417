import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';

import { OrganizationsRoutingModule }  from './organizations-routing.module';
import { OrganizationListComponent }   from './organization-list/organization-list.component';
import { OrganizationDetailComponent } from './organization-detail/organization-detail.component';
import { OrganizationEditComponent}             from './organization-edit/organization-edit.component';
import { OrganizationService }         from './organization.service';
import { ViewService } from '../views/views.service';

import { RolesModule }                 from './../roles/roles.module';
import { SharedModule }                from './../shared/shared.module';
// import { QueryStore } from '../shared/store/query.store';
import { ShiftsModule } from '../shifts/shifts.module';
import { VixCalendarModule } from '../calendar/calendar.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTabsModule,
    OrganizationsRoutingModule,
    RolesModule,
    ShiftsModule,
    VixCalendarModule,
    SharedModule
  ],
  entryComponents: [
    // DialogComponent
  ],
  declarations: [
    OrganizationListComponent,
    OrganizationDetailComponent,
    OrganizationEditComponent
    // ,
    // DialogComponent
  ],
  providers: [ 
    OrganizationService,
    ViewService,
   // QueryStore
   ]
})
export class OrganizationsModule { }
