import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Organization }          from './../organization';
import { OrganizationService }   from './../organization.service';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.css'],
  providers: [ OrganizationService ]
})

export class OrganizationListComponent implements OnInit {

  organizations: Organization[];
  module: string = 'Organization';
  fields: Array<any>= [
    { id: 'image', display: 'image', header: '', type: 'image', sortable: false, filterable: false },
    { id: 'name', display: 'name', header: 'Full name', sortable: true, filterable: true },
    { id: 'role.name', display: 'role.name', header: 'Role', type: 'chip', sortable: true, filterable: true },
  ];
  menuTitle: string = 'Organizations';

  query: any = {pageSize: 10, pageIndex: 0, sort: 'name', direction: 'asc', filter: null};

  errorMessage: string;
  canCreate: boolean = true;

  private subscription: Subscription = new Subscription();

  constructor (
    private organizationService: OrganizationService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.getRole().then((role) => {
      if ( role == 3) { this.canCreate = false }
    })
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    this.router.navigate(['/organizations/new']);
  }

  onSelect(organization: Organization){
    this.router.navigate(['/organizations/edit', organization.idOrganization ]);
  }

  onEdit(organization: Organization){
    this.router.navigate(['/organizations/edit', organization.idOrganization ]);
  }

  onRemove(organization: Organization){
    this.organizationService.removeOrganization(organization.idOrganization)
        .subscribe(
          error => this.errorMessage = <any>error);
  }

  onGetData(){
    
    this.unsubscribeData();
    this.subscription = this.organizationService.getOrganizations(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        
        this.organizations = data;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
        this.subscription.unsubscribe();
    }
  }

}
