<h1 mat-dialog-title>{{data.date | date}}</h1>
<div mat-dialog-content>
    <div *ngFor="let ws of workShifts; let i = index">
        <mat-checkbox [disabled]="pastDate && ws.isShiftNotPreviouslyActivated" [(ngModel)]="ws.worked" (change)="selectWorkShift($event, i)">
        <span style="white-space: pre-wrap;">
            {{ws.name}}    {{ws.start}} - {{ws.end}}
        </span>
      </mat-checkbox>             
    </div>
</div>
<div *ngIf="pastDate == true" style="margin-top: 10px">
    <p><strong>Info.</strong> You can only edit future dates and worked past dates.</p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onSave()" cdkFocusInitial>Save</button>
</div>