import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Shift }        from '../shift';
import { ShiftService } from '../shift.service';
import { AuthService } from '../../../auth.service';
import { Organization } from '../../organizations/organization';
import { MainService } from '../../main.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.css'],
  providers: [ ShiftService ]
})

export class ShiftListComponent implements OnInit {

  @Input() organization: Organization;

  shifts: any;
  module: string = 'Shift';
  fields: Array<any>= [
    { id: 'name', display: 'name', header: 'Name', sortable: true, filterable: true },
    { id: 'start', display: 'start', header: 'start', sortable: true, filterable: true },
    { id: 'end', display: 'end', header: 'end', sortable: true, filterable: true },
    { id: 'color', display: 'color', header: 'color', sortable: true, filterable: false, type: 'color' },
    { id: 'valueStartDayBefore', display: 'valueStartDayBefore', header: 'Starts the previous day', sortable: false, filterable: false },
  ];
  menuTitle: string = 'Shifts';
  
  insideEntity: boolean = true;
  state: any;

  query: any = {pageSize: 10, pageIndex: 0, sort: 'start', direction: 'asc', filter: null};

  private subscription: Subscription = new Subscription();

  constructor(
    private shiftService: ShiftService,
    private router: Router,
    private authService: AuthService,
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.state = { organization: this.organization };
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    this.mainService.setState(this.state);
    this.router.navigate(['/shifts/new']);
  }

  onSelect(shift: Shift){
    this.router.navigate(['/shifts/edit',shift.idShift ]);
  }

  onEdit(shift: Shift){
    this.router.navigate(['/shifts/edit',shift.idShift ]);
  }

  onRemove(shift: Shift){
    this.shiftService.removeShift(shift.idShift);
  }

  onGetData(){
    
    if (!this.organization) return;
    this.unsubscribeData();
    this.subscription = this.shiftService.getOrganizationShifts(this.organization.idOrganization, this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        
        for (let i = 0; i < data.rows.length; i++) {
          const row = data.rows[i];
          row['valueStartDayBefore'] = row.startDayBefore ? 'Yes' : 'No';
        }
        this.shifts = data;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
        this.subscription.unsubscribe();
    }
  }

}
