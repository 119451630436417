import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { BBJVariable }  from './bbjVariable';
import { Datasource }   from './../datasources/datasource';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Entity } from "../entities/entity";

@Injectable()
export class BBJVariableService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all bbjVariables
   */
  public getBBJVariables2(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<BBJVariable[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<BBJVariable[]>(environment.api_url + '/bbjVariables', { params: queryParams });
  };
  /**
   * Retrieves all bbjVariables
   */
  public getBBJVariables(): Observable<BBJVariable[]> {  
    return this.http.get<BBJVariable[]>(environment.api_url + "/bbjVariable"); 
  };

  /**
   * Retrieves bbjVariable details by a given id
   *
   * @param idBBJVariable
   */
  public getBBJVariable(idBBJVariable: string): Observable<BBJVariable> { 
    return this.http.get<BBJVariable>(environment.api_url + "/bbjVariable/" + idBBJVariable); 
  };

  /**
   * Retrieves bbjVariable details by a given id
   *
   * @param idBBJVariable
   */
  public getEntityBBJVariable(idBBJVariable: number): Observable<Entity> { 
    return this.http.get<Entity>(environment.api_url + "/entity/bbjVariable/" + idBBJVariable); 
  };

  /**
   * Retrieves datasource details by a given id
   *
   * @param idDatasource
   */
  public getCsv(idDatasource: string): Observable<any> { 
    return this.http.get<any>(environment.api_url + "/bbjVariable/csv/" + idDatasource);
  };

  /**
   * Retrieves datasource details by a given id
   *
   * @param idDatasource
   */
  public getCsvHeaders(idDatasource: string): Observable<any> { 
    return this.http.get<any>(environment.api_url + "/bbjVariable/csvHeaders/" + idDatasource);
  };


  /**
   * Create new bbjVariable
   *
   * @param idDatasource
   * @param csv
   */
  public importCsv(idDatasource: string, csv: File): Observable<BBJVariable> { 
    return this.http.post<BBJVariable>(environment.api_url + "/bbjVariables/importCsv/" + idDatasource, {csv});
  };
  /**
   * Save bbjVariable
   *
   * @param bbjVariable
   */
  public saveBBJVariable(bbjVariable: BBJVariable): Observable<BBJVariable> {
    if (bbjVariable.idBBJVariable) {
      return this.updateBBJVariable(bbjVariable);
    } else {
      return this.newBBJVariable(bbjVariable);
    }
    //return null;
  };

  /**
   * Create new bbjVariable
   *
   * @param bbjVariable
   */
  public newBBJVariable(bbjVariable: BBJVariable): Observable<BBJVariable> { 
    return this.http.post<BBJVariable>(environment.api_url + "/bbjVariable", bbjVariable); 
  };

  /**
   * Update bbjVariable
   *
   * @param bbjVariable
   */
  public updateBBJVariable(bbjVariable: BBJVariable): Observable<BBJVariable> { 
    return this.http.put<BBJVariable>(environment.api_url + "/bbjVariable", bbjVariable); 
  };

  /**
   * Delete a bbjVariable by a given id
   *
   * @param idBBJVariable
   */
  public removeBBJVariable (idBBJVariable: number): Observable<BBJVariable> { 
    return this.http.delete<BBJVariable>(environment.api_url + "/bbjVariable/" + idBBJVariable); 
  }

  public bulkDeleteBBJVariable (array: any): Observable<any> { 
    let queryParams = new HttpParams();
    queryParams = queryParams.set('idBBJVariables', array);
    const options = {
      params: queryParams
    }
    return this.http.delete<any>(environment.api_url + "/bbjVariables/bulkDelete", options);
  }

  /**
   * Retrieves entity details by a given an entity
   *
   * @param idEntity
   */
  public getCheckBBJVariablesInReport(idEntity: string, idBBJVariables: number[]): Observable<any[]> { 
    let queryParams = new HttpParams();
    queryParams = queryParams.set('idBBJVariables', JSON.stringify(idBBJVariables));
    return this.http.get<any[]>(environment.api_url + '/check/bbjVariables/report/entity/' + idEntity, { params: queryParams });
  };

  /**
   * Retrieves entity details by a given an entity
   *
   * @param idEntity
   */
  public getCheckAllBBJVariablesDatasourceInReport(idDatasource: string): Observable<any[]> { 
    return this.http.get<any[]>(environment.api_url + '/check/bbjVariables/report/datasource/' + idDatasource);
  };

  /**
   * Retrieves entity details by a given an organization
   *
   * @param idOrganization
   */
  public getOrganizationBBJVariables(idOrganization: string): Observable<BBJVariable[]> { 
    return this.http.get<BBJVariable[]>(environment.api_url + "/bbjVariables/organization/" + idOrganization)
  };

  public getRealTimeValue(idBBJVariable: string): Observable<any[]> { 
    return this.http.get<any[]>(environment.api_url + "/bbjVariables/realTime/" + idBBJVariable)
  };
  /**
   * Retrieves entity details by a given an entity
   *
   * @param idEntity
   */
  public getEntityBBJVariables(idEntity: string, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<BBJVariable[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<BBJVariable[]>(environment.api_url + '/bbjVariables/entity/' + idEntity, { params: queryParams });
  };
  /**
   * Retrieves entity details by a given an entity
   *
   * @param idEntity
   */
  public getEntityBBJVariablesReportsCycleReportGenerator(idEntity: string): Observable<BBJVariable[]> { 
    return this.http.get<BBJVariable[]>(environment.api_url + '/bbjVariables/entity/' + idEntity + '/reports/cycle-report');
  };
  /**
   * Create new BBJVariable by a given datasource 
   *
   * @param entity
   */
  public generateFromDatasource (body: any): Observable<any> { 
    return this.http.post(environment.api_url + '/bbjVariable/generateFromDatasource', body);
  };

  /**
   * Get available signals for an MTConnect datasource 
   *
   * @param {number} idDatasource Datasource id
   * @param {string} nodeId Parent node id
   */
  public getMTConnectSignals(idDatasource: number, nodeId: string): Observable<any[]> { 
    let queryParams = new HttpParams();
    queryParams = queryParams.set('nodeId', nodeId.toString());
    return this.http.get<any[]>(environment.api_url + '/mtconnect/signals/datasource/' + idDatasource, { params: queryParams });
  };
}
