<mat-card>
  <cardHeader-edit (headerActionEmitter)='onheaderActionEmitter($event)' [module]='module' [editMode]='editMode' [cardHeaderState]='cardHeaderState'>
  </cardHeader-edit>
  <mat-card-content>
    <mat-form-field>
      <input matInput
             id="name"
             required
             name="name"
             [disabled]="!editMode"
             placeholder="Name (changing the name migth cause issues due to code dependencies. For example Reports)"
             [(ngModel)]="app.name"
             #name="ngModel">
    </mat-form-field>

    <mat-form-field>
      <input matInput
             id="description"
             type="description"
             name="description"
             [disabled]="!editMode"
             placeholder="Description"
             [(ngModel)]="app.description"
             #description="ngModel">
    </mat-form-field>

    <!-- <mat-form-field *ngIf="isAdmin">
      <input matInput
             id="alias"
             type="alias"
             name="alias"
             [disabled]="!editMode"
             placeholder="Alias"
             [(ngModel)]="alias"
             #description="ngModel">
    </mat-form-field> -->

    <mat-form-field *ngIf="isAdmin">
      <input matInput
             id="url"
             type="url"
             name="url"
             [disabled]="!editMode"
             placeholder="URL (Use 'custom/' before the url to create a custom app with recycled widgets. E.g. 'custom/test')"
             [(ngModel)]="url"
             #description="ngModel">
    </mat-form-field>

    <!-- <mat-form-field>
      <input matInput
             id="image"
             name="image"
             [disabled]="!editMode"
             placeholder="Logo"
             [(ngModel)]="app.image"
             #image="ngModel">
    </mat-form-field> -->

    <mat-form-field>
      <input matInput
             id="icon"
             name="icon"
             [disabled]="!editMode"
             placeholder="Icon"
             [(ngModel)]="app.icon"
             #icon="ngModel">
    </mat-form-field>
    <mat-form-field>
      <input matInput
             id="index"
             type="number"
             name="index"
             [disabled]="!editMode"
             placeholder="Index"
             [(ngModel)]="app.index"
             #description="ngModel">
    </mat-form-field>
    <mat-slide-toggle placeholder="Only provider" [(ngModel)]="app.onlyProvider" [disabled]="!editMode">
      <span [ngClass]="(editMode) ? '' : 'disabled-text'">Only provider</span>
    </mat-slide-toggle>

    <!-- <mat-form-field *ngIf="isAdmin">
      <mat-select placeholder="Views"
                  multiple
                  name="Views"
                  [disabled]="!editMode"
                  [(ngModel)]="app['views']"
                  [compareWith]="compareViews"
                  required>
        <mat-option *ngFor="let view of views" [value]="view">
          {{view.name}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
  </mat-card-content>
  <mat-tab-group>
</mat-tab-group>
</mat-card>
