import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs';

import { View } from './../view';
import { ViewService } from './../views.service';


@Injectable()
export class ViewListResolver implements Resolve<View[]> {
  constructor(private viewService: ViewService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<View[]> {
    return this.viewService.getViews(null, 0, 'name', 'asc', '')
  }
}
