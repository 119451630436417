<div class="shift-day-list">
  <h4>Select work days</h4>
  <p>Please select, for each shift, the days of the week that the machine works and if the shift counts for the day at which it ends and send.</p>
  <mat-grid-list cols="9" rowHeight="2:1">
    <mat-grid-tile colspan="1">
    </mat-grid-tile>
    <mat-grid-tile *ngFor="let day of days; index as d" colspan="1">
      <button mat-icon-button (click)="selectCol(d)">
        <strong [attr.title]="day.value">{{day.label}}</strong>
      </button>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <strong title="This shift counts the ending time for the day">Counts the end time</strong>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list *ngFor="let shift of shifts; index as s" cols="9" rowHeight="5rem">
    <mat-grid-tile colspan="1">
      <button mat-button (click)="selectRow(shift)">
        <strong [attr.title]="getShiftTitle(shift)">{{shift.name}}</strong>
        <p>{{shift.start}} - {{shift.end}}</p>
      </button>
    </mat-grid-tile>
    <mat-grid-tile *ngFor="let day of days; index as d" colspan="1">
      <div class="expression-field">
        <mat-checkbox style="padding-bottom:20px" [checked]="shift['weekDay'][d]" (change)="selectDayWorkShift($event, s, d)">
        </mat-checkbox>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" >
      <div class="expression-field">
        <mat-slide-toggle [disabled]="!checkShiftCanBeSetStartDayBefore(shift)" [(ngModel)]="shift.startDayBeforeEntity"></mat-slide-toggle>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
