import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService }      from './../alert.service';

//declare var $:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  subscription: any;
  errorText: string;

  constructor(
    private alertService: AlertService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.subscription = this.alertService.getErrorEmitter()
                            .subscribe(message => {
                              this.showAlert(message.text, message.type);
                            });
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showAlert(text: string, type) {
    //this.errorText = text;

    //$('#footer-snackbar').addClass('mdl-snackbar--active');

    //setTimeout(() => { 
      //this.hideAlert();
    //}, 3000);

    this.snackBar.open(text, 'ok', {
      duration: 3000,
    });
  }
  
  hideAlert() {
    this.errorText = '';
    //$('#footer-snackbar').removeClass('mdl-snackbar--active');
  }

}
