import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';

import { Datasource } from './../../datasources/datasource';
import { BBJVariable } from './../bbjVariable';
import { BBJVariableService } from './../bbjVariable.service';
import { EntityService } from './../../entities/entity.service';
import { MainService} from './../../main.service';
import { AlertService } from '../../../alert.service';
import { AuthService } from '../../../auth.service';
import { Entity } from '../../entities/entity';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { MessageRemoveBBJVariablesComponent } from '../../shared/message-remove-bbjvariables/message-remove-bbjvariables.component';
@Component({
  selector: 'bbjVariable-list',
  templateUrl: './bbjVariable-list.component.html',
  styleUrls: ['./bbjVariable-list.component.css'],
  providers: [ BBJVariableService ]
})

export class BBJVariableListComponent implements OnInit {

  bbjVariables: BBJVariable[];
  module: string = 'Signal';
  fields: Array<any>= [
  ];
  menuTitle: string = 'Signals';
  insideEntity: boolean = false;
  csvButton: boolean = false;
  loading: boolean = true;

  query: any = {pageSize: 10, pageSizeOptions: [5, 10, 20, 50], pageIndex: 0, sort: "masterVariable.alias", direction: "asc", filter: null};
  canCreate: boolean = true;
  datasources: Datasource[];
  state:any;
  errorMessage: string;
  subscriptions: Array<any> = [];
  private subscription: Subscription = new Subscription();

  constructor(
    private bbjVariableService: BBJVariableService,
    private entityService: EntityService,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private authService: AuthService) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        first()
      )
      .subscribe((event) =>
      {
        this.destroySubscriptions();
      });
    }

  ngOnInit() {
    this.authService.isEndUser().then((result) => {
      if ( result ) {
        this.canCreate = false; this.fields = [
        { id: 'prettyName', display: 'prettyName', header: 'Description', sortable: true, filterable: true},
        {id: 'masterVariable.alias', display: 'masterVariable.alias', header: 'Signal type', sortable: true, filterable: true},
        { id: 'datasource.address', display: 'datasource.composedName', header: 'Datasource', sortable: true, filterable: true},
        { id: 'type', display: 'type', header: 'Request method', sortable: false, filterable: false},
        { id: 'parameter', display: 'parameter', header: 'Parameter', sortable: false, filterable: false},
        { id: 'realTime', display: 'realTime', header: 'Real time value', type: 'realTime', sortable: false, filterable: false}
        ];
      }
      else {
        this.fields = [
          { id: 'prettyName', display: 'prettyName', header: 'Description', sortable: true, filterable: true},
          {id: 'masterVariable.alias', display: 'masterVariable.alias', header: 'Signal type', sortable: true, filterable: true},
          { id: 'datasource.address', display: 'datasource.composedName', header: 'Datasource', sortable: true, filterable: true},
          { id: 'type', display: 'type', header: 'Request method', sortable: false, filterable: false},
          { id: 'parameter', display: 'parameter', header: 'Parameter', sortable: false, filterable: false},
          { id: 'realTime', display: 'realTime', header: 'Real time value', type: 'realTime', sortable: false, filterable: false},
          { id: 'idBBJVariable', display: 'idBBJVariable', header: 'Delete', type: 'checkBox', sortable: false, filterable: false }
        ]
      }

    });

      this.route.data
          //.subscribe((data: { bbjVariables: BBJVariable[], datasources: Datasource[] }) => {
          .subscribe((data: { datasources: Datasource[] , entity: Entity}) => {
            if (data.datasources) {
              this.datasources = data.datasources;
              this.insideEntity = true;
              this.csvButton = true;
              this.state = ({datasources: data.datasources['rows'], entity: data.entity});
            }


      })
      this.onGetData();

  }

  destroySubscriptions() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }


  composeTable(bbjVariables: BBJVariable[]) {
    // COMPOSE for table
    bbjVariables['rows'].forEach(variable => {
      variable['datasource']['composedName'] = variable['datasource'].datasourceModel.name + ' (' + variable['datasource'].address + ')';
      if (variable.value['dataParameters']) {
        variable["parameter"] = variable.value['dataParameters'];
      }
      else {
        if (variable.value['item']) {
          variable.value["item"] = variable.value["item"].replace(/\\/g,'\\\\');
          variable["parameter"] = variable.value['item'];
        }
        else if (variable.value['params']){
          variable["parameter"] = variable.value['params'];
        }
        else {
          variable["parameter"] = '';
        }
      }

      if (variable.value['type']) {
        variable["type"] = variable.value['type'];
      }
      else {
        variable["type"] = '';
      }
    });
    this.bbjVariables= bbjVariables;
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'bulkDelete':
        this.onBulkDelete(message.data);
        break;
      case 'refresh':
        this.setInitialValue();
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
      case 'csv':
        this.onCsv();
        break;
    }
  }

  onNew(){
    if (this.insideEntity) {
      this.mainService.setState(this.state);
    }
    this.router.navigate(['/bbjVariables/new']);
  }

  onCsv(){
    if (this.insideEntity && this.csvButton) {
      this.mainService.setState(this.state);
    }
    this.router.navigate(['/bbjVariables/csv']);
  }

  onSelect(bbjVariable: BBJVariable){
    if (this.insideEntity) {
      this.mainService.setState(this.state);
    }
    this.router.navigate(['/bbjVariables/edit',bbjVariable.idBBJVariable ]);
  }

  onEdit(bbjVariable: BBJVariable){
    if (this.insideEntity) {
      this.mainService.setState(this.state);
    }
    this.router.navigate(['/bbjVariables/edit',bbjVariable.idBBJVariable ]);
  }

  onRemove(bbjVariable: BBJVariable){
    this.bbjVariableService.removeBBJVariable(bbjVariable.idBBJVariable)
        .subscribe(
          error => this.errorMessage = <any>error);
  }

  onBulkDelete(variablesArray: any){
    this.checkVariableInReport(variablesArray);
  }
  
  private checkVariableInReport(variablesArray: any) {
    let variablesId = [];
    variablesArray.map((value) => {
      variablesId.push(value.idBBJVariable)
    });
    this.bbjVariableService.getCheckBBJVariablesInReport(this.state.entity.idEntity, variablesId).subscribe(
      response => {
        if (response.length > 0) {
          const dialogRef = this.dialog.open(MessageRemoveBBJVariablesComponent, {data: response});
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.confirmOnBulkDelete(variablesId);
            }
          });
        } else {
          this.confirmOnBulkDelete(variablesId);
        }
      },
      error =>  {
        this.errorMessage = <any>error
      }
    );
    
  }
  
  private confirmOnBulkDelete(variablesId: any) {
    this.bbjVariableService.bulkDeleteBBJVariable(variablesId).subscribe(
      response => {
        this.alertService.emitErrorMessage({text: response["_body"] + '. Please refresh', type: 'success'});
        this.onGetData();
      },
      error =>  {
        this.errorMessage = <any>error
      }
    );
  }

  onGetData(){
    this.loading = true;
    this.unsubscribeData();
    if(this.datasources) {
      this.subscription = this.bbjVariableService.getEntityBBJVariables(this.route.snapshot.params.id, this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
        .subscribe( data => {
          this.loading = false;
          this.composeTable(data);
          this.setInitialValue();
        })
      } else {
        this.subscription = this.bbjVariableService.getBBJVariables2(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
        .subscribe( data => {
          this.loading = false;
          this.composeTable(data);
          this.setInitialValue();
        })
    }
  }

  setInitialValue() {
    this.bbjVariables["rows"].map( (bbjVariable) => {
      bbjVariable["realTime"] = 'null';
      bbjVariable["loading"] = true;
      bbjVariable["realTimeStatus"] = false;
    })
    //this.dataloggerHardwares = dataloggerHardwares;
    this.getRealTimeValue();
  }

  getRealTimeValue() {
    let internalNames = {"variables": []};
    this.bbjVariables["rows"].map((bbjVariable) => {
      internalNames["variables"].push(bbjVariable.internalName);
    });

    this.entityService.getRealTimeSignalValues(internalNames, this.route.snapshot.params.id).subscribe(
      res => {
        if (res["success"] == true && Object.keys(res["variables"]).length > 0){
          this.bbjVariables["rows"].map((bbjVariable) => {
            Object.keys(res["variables"]).map((internalName) => {
                if (bbjVariable.internalName === internalName) {
                  let isArray = Array.isArray(res["variables"][internalName]);
                  if (isArray) {
                    // Stringify for table visualitation
                    bbjVariable["realTime"] = JSON.stringify(res["variables"][internalName]);
                  }
                  else {
                    bbjVariable["realTime"] = res["variables"][internalName];
                  }
                  bbjVariable["realTimeStatus"] = true;
                  bbjVariable["loading"] = false;
                }
            })
          })
        } else {
          this.bbjVariables["rows"].map((bbjVariable) => {
            bbjVariable["realTime"] = 'null';
            bbjVariable["realTimeStatus"] = false;
            bbjVariable["loading"] = false;
          })
        }


      },
      err => {
        this.bbjVariables["rows"].map((bbjVariable) => {
          bbjVariable["realTime"] = 'null';
          bbjVariable["realTimeStatus"] = false;
          bbjVariable["loading"] = false;
        })
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}
