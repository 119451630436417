import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Addon }      from './addon';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class AddonService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all addons
   */

  public getAddons(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Addon[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Addon[]>(environment.api_url + '/addons', { params: queryParams });
  };
  /**
   * Retrieves addon details by a given id
   *
   * @param idAddon
   */
  public getAddon(idAddon: string): Observable<Addon> { 
    return this.http.get<Addon>(environment.api_url + "/addon/" + idAddon)
  };

  /**
   * Save addon
   *
   * @param addon
   */
  public saveAddon(addon: Addon): Observable<any> {
    if (addon.idAddon) {
      return this.updateAddon(addon);
    } else {
      return this.newAddon(addon);
    }
    //return null;
  };

  /**
   * Create new addon
   *
   * @param addon
   */
  public newAddon(addon: Addon): Observable<any> { 
    return this.http.post(environment.api_url + "/addon", addon); 
  };

  /**
   * Update addon
   *
   * @param addon
   */
  public updateAddon(addon: Addon): Observable<any> { 
    return this.http.put(environment.api_url + "/addon", addon); 
  };

  /**
   * Delete an addon by a given id
   *
   * @param idAddon
   */
  public removeAddon (idAddon: number): Observable<any> { 
    return this.http.delete(environment.api_url + "/addon/" + idAddon); 
  }

  /**
   * Get entity addons by entity id
   *
   * @param idEntity
   */
  public getEntityAddons(idEntity: string): Observable<Addon[]> { 
    return this.http.get<Addon[]>(environment.api_url + "/entity/" + idEntity + "/addons"); 
  };


}
